/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '../config';

declare global {
  interface Window {
    BX: any;
  }
}

export const PHARMACY_CARD_WIDTH = 480;
export const MAX_COMMENT_LENGTH = 250;
export const MAX_FIELD_LENGTH = 10;
export const MAX_COMPANY_LENGTH = 50;
export const MAX_FULL_NAME_LENGTH = 45;
export const EMOJI_CODES =
  /[^.,\-_*'"”“„»«#&@?!/:$\n a-zA-Z0-9А-Яа-я()]/gm;
export const CURRENT_REGION_ID =
  +config.apiHeaders['region-id'];
export const CITY_DIRECTORY: string =
  window.BX?.City?.SITE_DIR || '/';
export const CART_LINK = `${CITY_DIRECTORY}personal/cart/`;
export const THIRTY_MINUTES = 1800000;
export const PROD_ORIGIN = 'https://www.eapteka.ru';
