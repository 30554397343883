import React, { FC } from 'react';
import clsx from 'clsx';
import { CheckoutSectionProps } from './types';
import './CheckoutSection.scss';

export const CheckoutSection: FC<CheckoutSectionProps> = ({
  children,
  className = '',
  isWithoutPadding = false
}) => (
  <section
    className={clsx('CheckoutSection ' + className, {
      CheckoutSectionWithoutPadding: isWithoutPadding
    })}
  >
    {children}
  </section>
);
