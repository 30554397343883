/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  RadioButton
} from '@npm-registry/eapteka-ui';
import './CheckoutDeliveryAddressItem.scss';

export const CheckoutDeliveryAddressItem = ({
  id,
  title,
  isSelected,
  description,
  onClickEdit,
  onClickSelect
}) => {

  return (
    <div className="CheckoutDeliveryAddressItem_Root">
      <label
        onClick={onClickSelect}
        htmlFor={`address_${id}`}
        className="CheckoutDeliveryAddressItem_Name"
      >
        <RadioButton
          checked={isSelected}
          value={id}
          onChange={Function.prototype}
          id={`address_${id}`}
        />
        <span>
          {title}
          <br />{' '}
          <mark className="CheckoutDeliveryAddressItem_City">
            {description}
          </mark>{' '}
        </span>
      </label>
      <Button
        theme="transparent"
        className="CheckoutDeliveryAddressItem_Icon"
        onClick={onClickEdit}
        aria-label="Изменить адрес"
      >
        Изменить
      </Button>
    </div>
  );
};

CheckoutDeliveryAddressItem.propTypes = {
  /**
   * Адрес доставки;
   */
  title: PropTypes.string,

  /**
   * Город доставки
   */
  description: PropTypes.string,

  /**
   * ID адреса доставки
   */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),

  /**
   * Коллбэк смены адреса
   */
  onAddressSelect: PropTypes.func,

  /**
   * Выбран адреса доставки
   */
  isSelected: PropTypes.bool
};
