import { useSelector } from 'react-redux';
import { useCorporateSumInsufficient } from '../../../../hooks/useCorporateSumInsufficient';
import { selectCurrentPharmacy } from '../../../../../services/redux/store/pharmacies/selectors';
import { selectReceivingMethod } from '../../../../../services/redux/store/receiving/selectors';

export const useButtonAbility = () => {
  const { isPickupSelected } = useSelector(selectReceivingMethod);
  const isCorporateSumInsufficient = useCorporateSumInsufficient();
  const selectedPickupPoint = useSelector(selectCurrentPharmacy);
  const isPickupDisabled = isPickupSelected && !!selectedPickupPoint?.commonUnavailableReason;

  return isCorporateSumInsufficient || isPickupDisabled;
};
