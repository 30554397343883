import { Store } from '../../services/redux/store/store';

export function isUserInRegion(
  city: string,
  locality: string
): boolean {
  const { region, name, code } = Store.getState().region.region;

  const area = [city, locality];
  const isMoscow = code === 'msc';
  const isSpb = code === 'spb';

  if (isMoscow || isSpb) {
    const regionName = region.split(',')[0];
    const cityName = name.split(' ')[0];

    return (
      area.includes(regionName) || area.includes(cityName)
    );
  }

  return area.includes(name) || area.includes(region);
}
