import {
  configureStore,
  PreloadedStateShapeFromReducersMapObject
} from '@reduxjs/toolkit';
import { rootReducer, TRootState } from './rootReducer';
import { getHandbook } from './handbook/actions';
import { getRegion } from './region/actions';
import { getUser } from './user/actions';
import { getCartWithOffers } from './cart/actions';
import { listenerMiddleware } from './listener';


const initializeStore = (initialValues?: PreloadedStateShapeFromReducersMapObject<TRootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getDefaultMiddleware().prepend(listenerMiddleware.middleware),
    preloadedState: initialValues,
    // Убираю из DevTools данные по всем аптекам, так как массив чрезмерно объемный
    devTools: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      stateSanitizer: (state: TRootState) => {
        if (state.pharmacies) {
          return { ...state, pharmacies: { ...state.pharmacies, data: [''] } };
        }
      }
    }
  });

  // Side effects
  store.dispatch(getHandbook()); // API CALL: /web/features & /sber/features
  store.dispatch(getRegion()); // API CALL: /region/{id}
  store.dispatch(getUser()); // API CALL: /user
  store.dispatch(getCartWithOffers()); // API CALL: /cart & /offers/{offer_ids}

  return store;
};

export const Store = initializeStore();
