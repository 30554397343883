import React, { useCallback } from 'react';
import { Button } from '@npm-registry/eapteka-ui';
import { useDispatch } from 'react-redux';
import { setReceivingMethodPickup } from '../../../../services/redux/store/receiving/slice';
import { setIsDeliveryScreenOpen } from '../../../../services/redux/store/ui/slice';
import { CheckoutReceivingMethodPlaceholder } from '../../../elements/CheckoutReceivingMethodPlaceholder';
import './DeliveryERUnavailablePlaceholder.scss';

export const DeliveryERUnavailablePlaceholder = () => {
  const dispatch = useDispatch();

  const onClickPickup = useCallback(() => {
    dispatch(setReceivingMethodPickup());
  }, [dispatch]);

  const onClickChangeAddress = useCallback(() => {
    dispatch(setIsDeliveryScreenOpen(true));
  }, [dispatch]);

  return (
    <CheckoutReceivingMethodPlaceholder
      title={
        <>
          Доставка доступна для региона, где вы получали
          <br />
          электронный рецепт
        </>
      }
      receiverType="DeliveryUnavailable"
      customButtonBlock={
        <div className="DeliveryERUnavailablePlaceholderButtonsContainer">
          <Button
            size="s"
            variant="filled"
            className="CheckoutReceivingMethodPlaceholder_Button CheckoutReceivingMethodPlaceholder_Button--Filled"
            theme="primary"
            onClick={onClickPickup}
          >
            Выбрать аптеку для самовывоза
          </Button>
          <Button
            size="s"
            variant="outlinedSolid"
            className="CheckoutReceivingMethodPlaceholder_Button"
            theme="primary"
            onClick={onClickChangeAddress}
          >
            Выбрать другой адрес
          </Button>
        </div>
      }
      isButtonUnavailable
    />
  );
};
