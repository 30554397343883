import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@npm-registry/eapteka-ui';
import { BNPLModal } from '../Modal';
import { BNPLTabs } from '../Tabs';
import { useInstallmentContext } from '../InstallmentContext';
import { getInstallmentData } from '../helpers/getInstallmentData';
import { selectCartSummary } from '../../../../services/redux/store/cart/selectors';

export const BNPLSchedule = () => {
  const { totalPrice } = useSelector(selectCartSummary);
  const { setInstallmentContext } = useInstallmentContext();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    getInstallmentData(totalPrice).then(
      setInstallmentContext
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  return (
    <article className="InstallmentBNPL_Banner">
      <button
        title='Показать подробности программы "Плати частями"'
        onClick={() => setVisible(true)}
        className="InstallmentBNPL_Banner_Details"
      >
        <Icon name="infoRound" />
      </button>
      <BNPLTabs />
      <BNPLModal
        onClose={() => setVisible(false)}
        isVisible={isVisible}
      />
    </article>
  );
};
