import React, { useEffect } from 'react';
import { RECEIVING_METHODS_VIEW_TYPE_KEYS } from '../../../../services/redux/store/receiving/consts';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import { CheckoutTooltip } from '../../../elements/CheckoutTooltip';

export const CheckoutReceivingMethodsTabItem = ({
  keyValue,
  label,
  subtitle,
  deliveryDescription,
  deliveryWarningMessage
}) => {
  const isDelivery =
    keyValue === RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery;
  const description = isDelivery
    ? deliveryDescription
    : subtitle;
  const isUnavailable =
    isDelivery && deliveryWarningMessage;

  useEffect(() => {
    if (isUnavailable && deliveryWarningMessage) {
      checkoutDispatchers.deliveryInformerClicked.dispatch(
        deliveryWarningMessage
      );
    }
  }, [deliveryWarningMessage, isUnavailable]);

  return (
    <>
      <strong className="CheckoutReceivingMethodsTabLabel">
        {label}
        <br />
        <small className="CheckoutReceivingMethodsTabDescription">
          {description}
        </small>
      </strong>
      {isUnavailable && (
        <CheckoutTooltip message={deliveryWarningMessage} />
      )}
    </>
  );
};
