import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../rootReducer";
import { PaymentStore } from "./types";

const selectPaymentState = (state: TRootState): PaymentStore => state.payment;

export const selectPaymentStatus = createSelector(selectPaymentState, state => state.status);
export const selectPayment = createSelector(selectPaymentState, (state) => state.payment);
export const selectCurrentPayment = createSelector(selectPaymentState, state => state.selectedPaymentType);
export const selectSberSpasibo = createSelector(selectPaymentState, state => state.sberSpasibo);
export const selectNeedChange = createSelector(selectPaymentState, state => state.needChange);
export const selectPaymentLoyaltyBonuses = createSelector(selectPaymentState, state => state.bonuses);
export const selectCardRememberdToggleState = createSelector(selectPaymentState, state => state.cardRemembered);
