export function formatCurrency(
  value,
  hasRubleSymbol = false
) {
  if (typeof value === 'string') {
    value = value.replace(/\s/g, '');

    if (/[^0-9]/.test(value)) {
      value = value.replace(/[^\d]/g, '');
    }

    value = Number(value);
  }

  const options = {
    style: 'decimal',
    minimumFractionDigits: 0
  };

  if (hasRubleSymbol) {
    options.style = 'currency';
    options.currency = 'RUB';
    options.currencyDisplay = 'symbol';
  }

  const numberFormat = new Intl.NumberFormat(
    'ru-RU',
    options
  );

  return numberFormat.format(value);
}
