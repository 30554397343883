import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switcher } from '@npm-registry/eapteka-ui';
import { selectCartData } from '../../../services/redux/store/cart/selectors';
import { CheckoutRecipientForm } from './Form/CheckoutRecipientForm';
import { selectUserRecipient } from '../../../services/redux/store/user/selectors';
import { setUserRecipientFormVisible, setUserRecipientName, setUserRecipientPhone } from '../../../services/redux/store/user/slice';
import './CheckoutRecipient.scss';

export const CheckoutRecipient = () => {
  const dispatch = useDispatch();
  const { hasERecipes } = useSelector(selectCartData);
  const { name, phone, isCollectedByOtherPerson } = useSelector(selectUserRecipient);

  const onToggleSwitcher = useCallback(() => {
    dispatch(setUserRecipientFormVisible(!isCollectedByOtherPerson))
  }, [dispatch, isCollectedByOtherPerson]);

  const onChangeFullName = useCallback(
    (recipientFullName) => {
      dispatch(setUserRecipientName(recipientFullName))
    },
    [dispatch]
  );

  const onChangePhone = useCallback((recipientPhone) => {
    dispatch(setUserRecipientPhone(recipientPhone))
  }, [dispatch]);

  if (hasERecipes) return null;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="CheckoutRecipient_Switcher">
        Заберёт другой человек
        <Switcher
          checked={isCollectedByOtherPerson}
          onChange={onToggleSwitcher}
        />
      </label>
      {isCollectedByOtherPerson && (
        <CheckoutRecipientForm
          phone={phone}
          fullName={name}
          onChangeFullName={onChangeFullName}
          onChangePhone={onChangePhone}
        />
      )}
    </>
  );
};
