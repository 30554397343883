import { checkoutDispatchers } from '../../../dispatchers';
import { requestCartItemsChange } from '../cart';

export function apiRequestCartChangeItemsPost(params) {
  try {
    return requestCartItemsChange({ params });
  } catch (error) {
    checkoutDispatchers.multicartUpdateFail.dispatch({
      title:
        'Заказ оформлен, но недоступные товары не удалось вернуть в корзину'
    });

    throw error;
  }
}
