import React, { type FC, type ReactElement } from 'react';
import cn from 'clsx';
import './Tabs.scss';

export interface ITabsProps {
  className?: string;
  children: Array<ReactElement>;
}

export const Tabs: FC<ITabsProps> = ({
  className,
  children
}) => {
  return (
    <div className={cn('CheckoutTabsRoot', className)}>
      <div className="CheckoutTabsContainer">
        <div className="CheckoutTabsChildContainer">
          {children}
        </div>
      </div>
    </div>
  );
};
