import React from 'react';
import {
  DrawerOverlay,
  Icon,
  Modal,
  useViewSize
} from '@npm-registry/eapteka-ui';
import { BNPLContainer } from '../Container';

interface InstallmentProps {
  onClose: () => void;
  isVisible: boolean;
}

export const BNPLModal = ({
  isVisible,
  onClose
}: InstallmentProps) => {
  const { isMobile } = useViewSize();

  return isMobile ? (
    <DrawerOverlay isExpanded={isVisible} onClose={onClose}>
      <BNPLContainer onClose={onClose} />
    </DrawerOverlay>
  ) : (
    <Modal visible={isVisible} onClose={onClose}>
      <button
        onClick={onClose}
        className="InstallmentBNPL_Close"
        title="Закрыть модальное окно"
        aria-label="Закрыть модальное окно плати частями"
      >
        <Icon name="close" />
      </button>
      <BNPLContainer onClose={onClose} />
    </Modal>
  );
};
