import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRegion } from '../../../services/redux/store/region/selectors';
import { selectCartCheckout } from '../../../services/redux/store/cart/selectors';
import { getOriginWithRegion } from '../../../tools/region/getOriginWithRegion';
import { getPolicyAgreementLink } from './helpers/getPolicyAgreementLink';
import { CheckoutSection } from '../../elements/CheckoutSection';
import './CheckoutPolicyAgreement.scss';

export function CheckoutPolicyAgreement() {
  const region = useSelector(selectRegion);
  const originUrl = getOriginWithRegion(region.code);
  const { legalInfo, legalLinks } = useSelector(selectCartCheckout);

  const preparedMessage = useMemo(() => {

    return legalLinks.reduce(
      (policyAgreement, policyLink) => {
        const url = policyLink.url.includes('http')
          ? policyLink.url
          : originUrl + policyLink.url;
        return policyAgreement.replace(
          `#${policyLink.id}#`,
          getPolicyAgreementLink(url, policyLink.name)
        );
      },
      legalInfo
    );
  }, [legalInfo, legalLinks, originUrl]);

  return (
    <CheckoutSection className="CheckoutSection--Footer CheckoutPolicyAgreement">
      <p
        className="CheckoutPolicyAgreementInfo"
        dangerouslySetInnerHTML={{
          __html: preparedMessage
        }}
      />
    </CheckoutSection>
  );
}
