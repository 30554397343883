/**
 * Reference: https://www.geodatasource.com/developers/javascript
 */

export function getGeoDistance(
  latitudeBase,
  longitudeBase,
  latitudeTarget,
  longitudeTarget
) {
  if (
    latitudeBase === latitudeTarget &&
    longitudeBase === longitudeTarget
  ) {
    return 0;
  }

  const radlat1 = (Math.PI * latitudeBase) / 180;
  const radlat2 = (Math.PI * latitudeTarget) / 180;
  const theta = longitudeBase - longitudeTarget;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) *
      Math.cos(radlat2) *
      Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = ((Math.acos(dist) * 180) / Math.PI) * 60 * 1.1515;
  const distanceInKilometers = dist * 1.609344;

  return distanceInKilometers;
}
