import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegion } from '../../../../services/redux/store/region/selectors';
import { apiRequestOrdersPost } from '../../../../services/api/methods/ordersPost';
import { apiRequestCartChangeItemsPost } from '../../../../services/api/methods/cartChangeItemsPost';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import { useDeliverySummary } from './useDeliverySummary';
import { apiRequestOrdersPaymentStatus } from '../../../../services/api';
import { validateEmail } from '../../../../tools/validation/validateEmail/validateEmail';
import { validateFullName } from '../../../../tools/validation/validateFullName/validateFullName';
import { validatePhoneNumber } from '../../../../tools/validation/validatePhoneNumber/validatePhoneNumber';
import { SberPayWidget } from '../../../../services/sber/sberpay';
import {
  selectUser,
  selectUserCompany,
  selectUserRecipient
} from '../../../../services/redux/store/user/selectors';
import { setUserRecipientFormVisible } from '../../../../services/redux/store/user/slice';
import { selectCurrentPharmacy } from '../../../../services/redux/store/pharmacies/selectors';
import { selectReceivingMethod } from '../../../../services/redux/store/receiving/selectors';
import {
  selectCardRememberdToggleState,
  selectCurrentPayment,
  selectPaymentLoyaltyBonuses,
  selectSberSpasibo
} from '../../../../services/redux/store/payment/selectors';
import {
  selectCart,
  selectCartBonusProgram,
  selectCartData
} from '../../../../services/redux/store/cart/selectors';
import { selectLogisticsSchemeId } from '../../../../services/redux/store/delivery/selectors';
import { createNotification } from '../../../../services/notifications';

export function useOrderSummary() {
  const dispatch = useDispatch();
  const isCardRemembered = useSelector(selectCardRememberdToggleState);
  const { isDeliverySelected, isPickupSelected } = useSelector(selectReceivingMethod);
  const { name, phone, email, isCorporate } = useSelector(selectUser);
  const {
    name: recipientFullName,
    phone: recipientPhone,
    isCollectedByOtherPerson: isVisibleForm
  } = useSelector(selectUserRecipient);
  const { inn, kpp, name: corporateName } = useSelector(selectUserCompany);
  const { isEnabled: isSberSpasiboEnabled, withdraw: sberSpasiboBonuses } =
    useSelector(selectSberSpasibo);
  const { isEnabled: isBonusEnabled, withdraw: bonusWithdraw } = useSelector(
    selectPaymentLoyaltyBonuses
  );
  const { hasERecipes } = useSelector(selectCartData);
  const { canWithdraw } = useSelector(selectCartBonusProgram);
  const { logisticSchemeId } = useSelector(selectLogisticsSchemeId);
  const region = useSelector(selectRegion);
  const isDeliveryCompany = region.deliveryCompany;
  const isNewGate = region.features.isNewGate;
  const deliveryInfo = useDeliverySummary();
  const payment = useSelector(selectCurrentPayment);
  const { availableItems, unavailableItems } = useSelector(selectCart);
  const selectedPickupPoint = useSelector(selectCurrentPharmacy);
  const features = ['Recipe-Delivery-Restriction'];
  const headers = {};
  const promoCode = localStorage.getItem('promoCodeApplied') || '';
  const isPromoCodeApplied = Boolean(promoCode);
  const isPickup = isPickupSelected && selectedPickupPoint;
  const pickupPointOffers = availableItems.filter((item) =>
    selectedPickupPoint?.availableOffers.includes(item.id)
  );

  if (isPickup && selectedPickupPoint.unavailableOffers.length > 0) {
    features.push('multicart');
  } else if (unavailableItems.length > 0) {
    features.push('multicart');
  }

  const multiCartItems = isPickup
    ? pickupPointOffers.map((offer) => {
        return { id: offer.id, amount: offer.amount };
      })
    : unavailableItems.map((offer) => {
        return { id: offer.id, amount: offer.amount };
      });

  const orderItems = isPickup ? pickupPointOffers : availableItems;

  const data = {
    user: {
      name: name,
      email: email,
      phone: phone
    },
    recipient: {
      recipient_name: isVisibleForm ? recipientFullName : name,
      recipient_phone: isVisibleForm ? recipientPhone : phone
    },
    order_items: orderItems,
    loyalty_programs: [],
    bonus_program: {
      withdraw: !isCorporate && isBonusEnabled && canWithdraw && bonusWithdraw > 0
    },
    company: {}
  };

  if (isCorporate) {
    data.person_type = 'company';
    data.company = {
      name: corporateName,
      inn
    };

    if (kpp) data.company.kpp = kpp;
  }

  if (payment) {
    const isSberPay = payment.name === 'SberPay';

    data.payment_type = {
      id: payment.id,
      code: payment.code,
      name: payment.name
    };
    if (payment.bindingId) {
      data.payment_type.binding_id = payment.bindingId;
    } else if (payment.code === 'card') {
      data.payment_type.save_card_info = isCardRemembered;
    }
    if (isSberPay) {
      data.payment_type.name = 'Картой онлайн';
      data.payment_type.has_sbol = true;
      data.payment_type.sberpay_sdk = true;
    }
  }

  if (isDeliverySelected) {
    data.delivery = deliveryInfo;
    data.logistic_scheme_id = logisticSchemeId;
  }

  if (isPickupSelected && selectedPickupPoint) {
    data.pickup_point_id = selectedPickupPoint.id;
    data.pickup_date = selectedPickupPoint.pickupDatetime;
    data.pickup_interval = selectedPickupPoint.pickupInterval;
    data.in_one_hour = selectedPickupPoint.inOneHour;
  }

  if (isPromoCodeApplied) {
    data.loyalty_programs.push({
      program: 'discount',
      code: promoCode
    });
  }

  if (isSberSpasiboEnabled && sberSpasiboBonuses > 0 && payment) {
    const multiplier = 100;
    const amount = sberSpasiboBonuses * multiplier;
    if (data.payment_type) {
      data.payment_type.jsonParams = {
        'sbrf_spasibo:amount_bonus': String(amount)
      };
    }
  }

  const isDataValid = useCallback(() => {
    const isRecipientNameValid =
      data.recipient.recipient_name && !validateFullName(data.recipient.recipient_name).hasError;
    const isRecipientPhoneValid =
      data.recipient.recipient_phone &&
      !validatePhoneNumber(data.recipient.recipient_phone).hasError;

    if (isCorporate && !isDeliveryCompany) {
      createNotification({ title: 'Пока недоступна в вашем регионе' });
      return false;
    }

    if (!data.delivery && !data.pickup_point_id) {
      createNotification({ title: 'Укажите способ получения' });
      checkoutDispatchers.orderFailEvent.dispatch({
        errorMessage: 'Укажите способ получения'
      });
      return false;
    }
    if (isDeliverySelected && !deliveryInfo.address?.address) {
      createNotification({ title: 'Укажите адрес доставки' });
      return false;
    }
    if (isDeliverySelected && (!data.delivery.interval.from || !data.delivery.interval.to)) {
      createNotification({ title: 'Укажите время доставки' });
      checkoutDispatchers.orderFailEvent.dispatch({
        errorMessage: 'Укажите время доставки'
      });
      return false;
    }
    if (!data.payment_type) {
      createNotification({ title: 'Укажите способ оплаты' });
      checkoutDispatchers.orderFailEvent.dispatch({
        errorMessage: 'Укажите способ оплаты'
      });
      return false;
    }
    if (isVisibleForm && !hasERecipes && (!isRecipientNameValid || !isRecipientPhoneValid)) {
      checkoutDispatchers.errorRecipientData.dispatch({
        name: data.recipient.recipient_name,
        phone: data.recipient.recipient_phone,
        isVisibleForm
      });
      return false;
    }
    if (
      !(
        data.user.email &&
        !validateEmail(data.user.email).hasError &&
        isRecipientNameValid &&
        isRecipientPhoneValid
      )
    ) {
      checkoutDispatchers.errorRecipientData.dispatch({
        email: data.user.email,
        name: data.recipient.recipient_name,
        phone: data.recipient.recipient_phone,
        isVisibleForm
      });
      return false;
    }

    if (isCorporate) {
      if (
        !data.company.name ||
        !data.company.inn ||
        (data.company.inn.length === 12 && !data.company.kpp)
      ) {
        checkoutDispatchers.errorCorporateFields.dispatch({
          companyName: data.company.name,
          inn: data.company.inn,
          kpp: data.company.kpp
        });
        checkoutDispatchers.orderFailEvent.dispatch({
          errorMessage: 'Некорректно указано: Название компании, ИНН или КПП'
        });
        return false;
      }
    }

    return true;
  }, [
    data.delivery,
    data.pickup_point_id,
    data.payment_type,
    data.user.email,
    data.recipient.recipient_name,
    data.recipient.recipient_phone,
    data.company.name,
    data.company.inn,
    data.company.kpp,
    deliveryInfo.address,
    isDeliverySelected,
    isVisibleForm,
    isCorporate,
    hasERecipes,
    isDeliveryCompany
  ]);

  const onConfirmOrderSubmit = async () => {
    checkoutDispatchers.confirmOrderSubmit.dispatch();

    if (isDataValid()) {
      checkoutDispatchers.preloaderShowing.dispatch(true);
      try {
        headers.features = features.toString();
        const order = await apiRequestOrdersPost(data, headers); // /order

        if (features.indexOf('multicart') >= 0) {
          const multicartData = {
            cart_items: multiCartItems
          };
          await apiRequestCartChangeItemsPost(multicartData);
        }

        dispatch(setUserRecipientFormVisible(false));

        localStorage.removeItem('promoCodeApplied');

        if (order?.id && order?.status !== 'sberLoyaltyFail' && isNewGate) {
          await apiRequestOrdersPaymentStatus(order.id);
        } else if (order?.id && !isNewGate) {
          await apiRequestOrdersPaymentStatus(order.id);
        }

        const url = new URL(window.location.href);
        url.searchParams.set('order', order?.hash);
        url.searchParams.set('PAY_ORDER', 'N');

        if (isNewGate && order?.status === 'sberLoyaltyFail' && order?.id) {
          checkoutDispatchers.sberLoyaltyFail.dispatch({
            orderUrl: url, // Отмена
            orderId: order.id // Продолжить
          });
        } else if (order?.bank_invoice_id) {
          SberPayWidget.open({
            bankInvoiceId: order.bank_invoice_id,
            backUrl: order.web_return_url
          });
        } else if (order?.payment_url) {
          window.location.assign(order.payment_url);
        } else if (order?.hash) {
          window.location.assign(url);
        } else {
          console.error('Неудачная отправка запроса оформления заказа', { order });
          checkoutDispatchers.confirmOrderFail.dispatch();
        }
      } catch (err) {
        console.error('Неудачная отправка запроса оформления заказа', err);
        checkoutDispatchers.confirmOrderFail.dispatch();
        checkoutDispatchers.orderFailEvent.dispatch({
          errorMessage: 'Неудачная отправка запроса оформления заказа',
          serverError: err?.message || err
        });
      } finally {
        checkoutDispatchers.preloaderShowing.dispatch(false);
      }
    }
  };

  return {
    onConfirmOrderSubmit
  };
}
