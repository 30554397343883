import config from '../../../../config';
import {
  AddressesResponse,
  DeliveryIntervalsPayload,
  DeliveryIntervalsResponse,
  PostAddressPayload,
  PostAddressResponse,
  PutAddressPayload,
  PutAddressResponse
} from '../../../redux/store/delivery/types';
import { API_SERVICE } from '../../api';

export const getAddressesRequest = API_SERVICE.createGetMethod<undefined, AddressesResponse>(
  config.apiUrl.addresses
);

export const putAddressRequest = API_SERVICE.createPutMethod<PutAddressPayload, PutAddressResponse>(
  config.apiUrl.addresses,
  {
    mapRequest: (address) => ({
      body: JSON.stringify(address)
    })
  }
);

export const postAddressRequest = API_SERVICE.createPostMethod<
  PostAddressPayload,
  PostAddressResponse
>(config.apiUrl.addresses, {
  mapRequest: (address, { currentUrl }) => ({
    url: `${currentUrl}/${address.id}`,
    body: JSON.stringify(address)
  })
});

export const deleteUserAddressRequest = API_SERVICE.createDeleteMethod<number, { result: boolean }>(
  config.apiUrl.addresses,
  {
    mapRequest: (addressId, { currentUrl }) => ({
      url: `${currentUrl}/${addressId}`
    })
  }
);

export const getAddressZone = API_SERVICE.createGetMethod<
  number[],
  { zone: string; is_erecipie_delivery_allowed: boolean }
>(config.apiUrl.geocodeZone, {
  mapRequest: ([latitude, longitude], { currentUrl }) => ({
    url: `${currentUrl}?latitude=${latitude}&longitude=${longitude}`
  })
});

export const getDeliveryIntervalsRequest = API_SERVICE.createPostMethod<
  DeliveryIntervalsPayload,
  DeliveryIntervalsResponse
>(config.apiUrl.getDelivery, {
  mapRequest: (params) => ({
    body: JSON.stringify(params)
  })
});
