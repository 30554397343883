import config from '../../../config';
import { apiService } from '../apiService';

export const requestPickupPoints =
  apiService.createGetMethod(
    config.apiUrl.getAllPickupPoints
  );

export const requestCurrentPickupPoints =
  apiService.createGetMethod(
    config.apiUrl.getCurrentPickupPoints,
    {
      mapRequest: () => ({
        headers: {
          Accept: 'application/json, text/plain, */*',
          features: 'multicart'
        }
      })
    }
  );
