import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORE_NAMES } from '../consts';
import { UI } from './types';

const initialState = {
  pickup: {
    isMapView: true,
    isPickupScreenOpen: false
  },
  delivery: {
    isDeliveryScreenOpen: false,
    isDeliveryAddressModalOpen: false,
    isDeliveryIntervalModalOpen: false
  }
} as UI;

export const uiSlice = createSlice({
  name: STORE_NAMES.UI,
  initialState,
  reducers: {
    setIsMapView: (state, { payload }: PayloadAction<boolean>) => {
      state.pickup.isMapView = payload;
    },
    setIsPickupScreenOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.pickup.isPickupScreenOpen = payload;
    },
    setIsDeliveryScreenOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.delivery.isDeliveryScreenOpen = payload;
    },
    setIsDeliveryAddressModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.delivery.isDeliveryAddressModalOpen = payload;
    },
    setIsDeliveryIntervalModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.delivery.isDeliveryIntervalModalOpen = payload;
    }
  }
});

const uiReducer = uiSlice.reducer;
const uiActions = uiSlice.actions;

export const {
  setIsMapView,
  setIsPickupScreenOpen,
  setIsDeliveryScreenOpen,
  setIsDeliveryAddressModalOpen,
  setIsDeliveryIntervalModalOpen
} = uiActions;
export { uiReducer, uiActions };
