import config from '../../../config';
import { getTokensFromStorage } from '../../../tools/getTokensFromStorage';
import { apiService } from '../apiService';

export const requestAddressZoneCheck =
  apiService.createGetMethod(config.apiUrl.geocodeZone, {
    mapRequest: (
      { latitude, longitude },
      { currentUrl }
    ) => ({
      url: `${currentUrl}?latitude=${latitude}&longitude=${longitude}`,
      headers: {
        ...getTokensFromStorage('cartToken')
      }
    }),
    mapResponse: async (response) => {
      const body = await response.json();

      return {
        zone: body.zone,
        isErecipeDeliveryAllowed:
          body.is_erecipe_delivery_allowed
      };
    }
  });

export const requestPickupAddressSuggestions =
  apiService.createPostMethod(config.apiUrl.geocodePoints, {
    mapRequest: ({ address, coordinates }) => {
      const [latitude, longitude] = coordinates;

      return {
        body: JSON.stringify({
          address,
          context: { latitude, longitude }
        })
      };
    },
    mapResponse: async (response) => {
      const { points } = await response.json();

      return points.map(
        ({
          label,
          coords,
          city,
          locality,
          street,
          building,
          country
        }) => ({
          city,
          street,
          building,
          locality,
          label,
          coordinates: {
            longitude: coords.lon,
            latitude: coords.lat
          },
          country
        })
      );
    }
  });

export const requestAddressByCoords =
  apiService.createPostMethod(
    config.apiUrl.geocodeAddress,
    {
      mapRequest: ({ coordinates }) => {
        const [longitude, latitude] = coordinates;
        return {
          body: JSON.stringify({ longitude, latitude })
        };
      },

      mapResponse: async (response) => {
        const address = await response.json();

        return address;
      }
    }
  );
