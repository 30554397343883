import React, { useCallback, useEffect } from 'react';
import { Button } from '@npm-registry/eapteka-ui';
import { useSelector } from 'react-redux';
import { checkoutDispatchers } from '../../../services/dispatchers';
import { THIRTY_MINUTES } from '../../../tools/const';
import { selectReceivingMethod } from '../../../services/redux/store/receiving/selectors';
import { createDialog } from '../../../services/notifications';

/*
 * Нотификация. Показывается если пользователь был неактивен 30 минут.
 * По истечению этого срока появляется уведомление о необходимости
 * перезагрузить страницу.
 */

export const useInactivityNotification = (
  isLoaded = false
) => {
  const { isDeliverySelected } = useSelector(
    selectReceivingMethod
  );
  const currentReceivingMethodKey = isDeliverySelected
    ? 'delivery'
    : 'pickup';

  const handleRefresh = useCallback(() => {
    window.sessionStorage.setItem(
      'reloadRequired',
      currentReceivingMethodKey
    );
    document.location.reload();
  }, [currentReceivingMethodKey]);

  useEffect(() => {
    if (isLoaded) {
      checkoutDispatchers.pageVisit.dispatch();
      const intervalRefreshPage = setInterval(() => {
        createDialog({
          title: 'Обновите страницу',
          message:
            'Состав корзины и интервалы доставки могли измениться',
          addon: (
            <Button onClick={handleRefresh}>
              Обновить
            </Button>
          )
        });
      }, THIRTY_MINUTES);
      return () => clearInterval(intervalRefreshPage);
    }
  }, [handleRefresh, isLoaded]);
};
