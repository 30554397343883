import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { User, UserStore } from './types';
import { FetchStatus } from '../types';

const initialState = {
  status: FETCH_STATUS.IDLE,
  user: null,
} as UserStore;

export const userSlice = createSlice({
  name: STORE_NAMES.USER,
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    setUserStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.status = payload;
    },
    setUserCorporate: (state, { payload }: PayloadAction<boolean>) => {
      state.user.isCorporate = payload;
    },
    setUserRecipientFormVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.user.recipient.isCollectedByOtherPerson = payload;
    },
    setUserRecipientName: (state, { payload }: PayloadAction<string>) => {
      state.user.recipient.name = payload;
    },
    setUserRecipientPhone: (state, { payload }: PayloadAction<string>) => {
      state.user.recipient.phone = payload;
    },
    setUserCompanyINN: (state, { payload }: PayloadAction<string>) => {
      state.user.company.inn = payload;
    },
    setUserCompanyKPP: (state, { payload }: PayloadAction<string>) => {
      state.user.company.kpp = payload;
    },
    setUserCompanyName: (state, { payload }: PayloadAction<string>) => {
      state.user.company.name = payload;
    },
    setUserName: (state, { payload }: PayloadAction<string>) => {
      state.user.name = payload;
    },
    setUserEmail: (state, { payload }: PayloadAction<string>) => {
      state.user.email = payload;
    },
    setUserPhone: (state, { payload }: PayloadAction<string>) => {
      state.user.phone = payload;
    }
  },
});

export const {
  setUser,
  setUserStatus,
  setUserCorporate,
  setUserRecipientFormVisible,
  setUserRecipientName,
  setUserRecipientPhone,
  setUserCompanyINN,
  setUserCompanyKPP,
  setUserCompanyName,
  setUserEmail,
  setUserName,
  setUserPhone
} = userSlice.actions;

const userReducer = userSlice.reducer;
const userActions = userSlice.actions;

export { userReducer, userActions };
