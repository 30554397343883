export function validateEmail(email) {
  const emailString = String(email).toLowerCase();
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const isEmpty = emailString.length < 1;
  const isInvalid = !emailString.match(emailPattern);
  const errorMessage = isEmpty
    ? 'Укажите почту'
    : isInvalid
      ? 'Укажите корректную почту'
      : '';

  const hasError = isEmpty || isInvalid;

  return { hasError, errorMessage };
}
