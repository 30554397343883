import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUIPickup } from '../../../../../services/redux/store/ui/selectors';
import { setIsMapView } from '../../../../../services/redux/store/ui/slice';
import { Tab, Tabs } from '../../../../elements/Tabs';
import { PICKUP_VIEW_TYPE_LABELS, PickupViewLabel } from './const';

export const CheckoutPickupViewType = ({ handleMinimize }: { handleMinimize: () => void }) => {
  const { isMapView } = useSelector(selectUIPickup);
  const dispatch = useDispatch();
  const activeTabIndex = isMapView ? 0 : 1;
  const onTabClick = (label: PickupViewLabel) => {
    dispatch(setIsMapView(label === 'Карта'));
    if (label === 'Список') {
      handleMinimize();
    }
  };

  return (
    <Tabs className="CheckoutPickupView_Tabs CheckoutReceivingMethodsTabs">
      {PICKUP_VIEW_TYPE_LABELS.map(
        (label, index) => (
          <Tab
            key={label}
            isActive={activeTabIndex === index}
            isDisabled={false}
            onClick={() => onTabClick(label)}
            className="CheckoutReceivingMethodsTab"
          >
            {label}
          </Tab>
        )
      )}
    </Tabs>
  );
};
