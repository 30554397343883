/* eslint-disable no-undef */
import { Map } from '@2gis/mapgl/types';
import { MapGLDispatcher } from './dispatcher';

export const getUserLocation = (map: Map) => {
  if (navigator.geolocation) {
    const success = (pos: GeolocationPosition) => {
      const { latitude, longitude } = pos.coords;
      const center = [longitude, latitude];

      map.setCenter(center);
      map.setZoom(7);

      MapGLDispatcher.userCoordinates.dispatch(center);
    };

    const error = (e: GeolocationPositionError) => {
      console.warn(
        'Пользователь не предоставил доступ к геолокации',
        e
      );
    };

    navigator.geolocation.getCurrentPosition(
      success,
      error
    );
  } else {
    console.warn(
      'У пользователя нет доступа к получению геолокации'
    );
  }
};
