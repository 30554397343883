import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';
import { Switcher, Icon } from '@npm-registry/eapteka-ui';
import {
  IconNameMedium,
  IconNameSmall
} from '@npm-registry/eapteka-ui/lib/components/Icon/icons';
import { CheckoutTooltip } from '../../../../elements/CheckoutTooltip';

interface PaymentProgram {
  iconName: IconNameMedium | IconNameSmall;
  gradientName?: 'callicarpa' | 'caribbeanSea';
  title: string;
  subtitle: string | ReactElement;
  isUnavailable: boolean;
  tooltipMessage?: string;
  isTooltipVisible?: boolean;
  isSwitcherVisible: boolean;
  isSwitchChecked: boolean;
  switchClass?: string;
  onToggleSwitch: React.ChangeEventHandler<HTMLInputElement>;
}

export const CheckoutPaymentProgram: FC<PaymentProgram> = ({
  iconName,
  gradientName,
  title,
  subtitle,
  tooltipMessage,
  isTooltipVisible,
  isUnavailable,
  isSwitcherVisible,
  isSwitchChecked,
  switchClass,
  onToggleSwitch
}) => {
  const rootClassNames = clsx(
    'CheckoutPayment_Program_Root',
    {
      CheckoutPayment_Program_Unavailable: isUnavailable
    }
  );
  const iconClass = clsx(switchClass);
  const switchClasses = clsx('CheckoutPayment_Switcher', switchClass)

  return (
    <div className={rootClassNames}>
      <Icon name={iconName} className={iconClass} gradientName={gradientName} />
      <label className="CheckoutPayment_Program">
        <span>
          <strong className="CheckoutPayment_Program_Title">
            {title}
          </strong>
          <br />
          <small className="CheckoutPayment_Program_Subtitle">
            {subtitle}
          </small>
        </span>
        {isSwitcherVisible && (
          <Switcher
            disabled={isUnavailable}
            checked={isSwitchChecked}
            className={switchClasses}
            onChange={onToggleSwitch}
          />
        )}
        {isTooltipVisible && (
          <CheckoutTooltip
            message={tooltipMessage}
            iconName="questionCircleFilled"
          />
        )}
      </label>
    </div>
  );
};
