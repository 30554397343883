import { checkoutDispatchers } from '../../../dispatchers';
import { requestOrders } from '../orders';

export function apiRequestOrdersPost(data, headers) {
  try {
    return requestOrders({
      data,
      headers
    });
  } catch (error) {
    checkoutDispatchers.errorTechnical.dispatch({
      title: 'Не удалось оформить'
    });

    throw error;
  }
}
