import { ReferenceLink } from '../types';

function getLink(url: string, text: string) {
  return `<a
    class="InstallmentBNPL_Link"
    href="${url}"
    target="_blank"
    rel="noopener noreferrer"
  >${text}</a>`;
}

export function formatReference(
  reference: string,
  referenceLinks: Array<ReferenceLink>
) {
  return referenceLinks.reduce(
    (reference, link) =>
      reference.replace(
        `#${link.id}#`,
        getLink(link.url, link.text)
      ),
    reference
  );
}
