// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNjU5NTcgMTIuMzE1QzAuNzk4NTQ3IDExLjk4MDMgMC43NzUwMTUgMTAuNzU3NCAxLjYyMTQyIDEwLjM4NjVDOC42OTk3MyA3LjI4NDc2IDEzLjUwNjQgNS4xNzg0MiAyMC41NTU5IDIuMDg5MzFDMjEuNDIwNCAxLjcxMDQ1IDIyLjI5MzUgMi42MDYyNSAyMS45MDUgMy40NzQwNUwxMy40Mzc2IDIyLjM4NzlDMTMuMDYwNSAyMy4yMzAyIDExLjg2NTUgMjMuMTk1MiAxMS41MzcyIDIyLjMzMjNMOC45NDMyMyAxNS41MTQ5QzguODM4NTcgMTUuMjM5OSA4LjYyMzU0IDE1LjAyMjUgOC4zNTE0MyAxNC45MTY3TDEuNjU5NTcgMTIuMzE1WiIgc3Ryb2tlPSIjMzMzRjQ4IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapControlsGeolocation::after{width:21px;height:21px;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}`, "",{"version":3,"sources":["webpack://./src/components/core/2GIS/Controls/Geolocation/MapControlsGeolocation.scss"],"names":[],"mappings":"AACE,+BACE,UAAA,CACA,WAAA,CAEA,wDAAA","sourcesContent":[".MapControlsGeolocation {\n  &::after {\n    width: 21px;\n    height: 21px;\n\n    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNjU5NTcgMTIuMzE1QzAuNzk4NTQ3IDExLjk4MDMgMC43NzUwMTUgMTAuNzU3NCAxLjYyMTQyIDEwLjM4NjVDOC42OTk3MyA3LjI4NDc2IDEzLjUwNjQgNS4xNzg0MiAyMC41NTU5IDIuMDg5MzFDMjEuNDIwNCAxLjcxMDQ1IDIyLjI5MzUgMi42MDYyNSAyMS45MDUgMy40NzQwNUwxMy40Mzc2IDIyLjM4NzlDMTMuMDYwNSAyMy4yMzAyIDExLjg2NTUgMjMuMTk1MiAxMS41MzcyIDIyLjMzMjNMOC45NDMyMyAxNS41MTQ5QzguODM4NTcgMTUuMjM5OSA4LjYyMzU0IDE1LjAyMjUgOC4zNTE0MyAxNC45MTY3TDEuNjU5NTcgMTIuMzE1WiIgc3Ryb2tlPSIjMzMzRjQ4IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
