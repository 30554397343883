import React from 'react';
import {
  Icon,
  Typography,
  Button
} from '@npm-registry/eapteka-ui';
import { useDispatch } from 'react-redux';
import { CheckoutPharmacySection } from '../CheckoutPharmacySection';
import { CheckoutPharmacyProductsAvailability } from './ProductsAvailability';
import { CheckoutPharmacyAddress } from './Address';
import { CheckoutPharmacyOffers } from './Offers';
import { checkoutDispatchers } from '../../../services/dispatchers';
import { setSelectedPharmacy } from '../../../services/redux/store/pharmacies/slice';
import { useReceivingMethodPickup } from '../CheckoutReceivingMethods/Pickup/hooks/useReceivingMethodPickup';
import { setReceivingMethodPickup } from '../../../services/redux/store/receiving/slice';
import type { PickupPoint } from '../../../services/redux/store/pharmacies/types';
import { setBonusToggled, setCurrentPayment, setSberspasiboToggled } from '../../../services/redux/store/payment/slice';
import './CheckoutPharmacy.scss';

export const CheckoutPharmacy = ({ pickupPoint }: { pickupPoint: PickupPoint}) => {
  const dispatch = useDispatch();
  const { onPickupScreenClose } = useReceivingMethodPickup();

  const {
    distance,
    isOnlinePayAvailable,
    isPickupAvailablePartly,
    isPickupAvailable,
    isPartner,
    commonUnavailableReason,
    unavailableOffers,
    availabilityText,
    pickupActionText,
    paymentText,
    paymentIcon,
    paymentIconSize,
    name,
    id
  } = pickupPoint;

  const onClickPharmacyButton = () => {
    dispatch(setBonusToggled(false));
    dispatch(setSberspasiboToggled(false));
    dispatch(setCurrentPayment(null));
    dispatch(setSelectedPharmacy(pickupPoint));
    dispatch(setReceivingMethodPickup());
    checkoutDispatchers.pickupPharmacyClick.dispatch();
    onPickupScreenClose();
  };

  return (
    <div key={id} className="CheckoutPharmacy_Root">
      <header>
        <Typography
          className="CheckoutPharmacy_Name"
          variant="p1"
        >
          {name}
          {distance && (
            <span className="CheckoutPharmacy_Distance">
              <Icon
                className="CheckoutPharmacy_Marker"
                name="marker"
                size="s"
              />
              {distance}
            </span>
          )}
        </Typography>
        <CheckoutPharmacyAddress pharmacy={pickupPoint} />
      </header>
      <section className="CheckoutPharmacy_Sections">
        {isPickupAvailable && (
          <>
            <CheckoutPharmacySection
              icon={
                <Icon
                  name="timeRound"
                  size="m"
                  className="CheckoutSelectedPharmacy_SectionIcon"
                />
              }
            >
              <b>{pickupPoint.storeTo}</b>
            </CheckoutPharmacySection>
            <CheckoutPharmacySection
              icon={
                <Icon
                  name="time"
                  size="m"
                  className="CheckoutSelectedPharmacy_SectionIcon"
                />
              }
            >
              {pickupPoint.storeFrom}
            </CheckoutPharmacySection>
          </>
        )}
        {isOnlinePayAvailable && (
          <CheckoutPharmacySection
            icon={
              <Icon
                name="sberSpasibo"
                size="m"
                className="CheckoutSelectedPharmacy_SectionIcon SberSpasibo"
              />
            }
          >
            Бонусы СберСпасибо
            {isPartner ? ' при оплате онлайн' : ''}
          </CheckoutPharmacySection>
        )}
        <CheckoutPharmacySection
          icon={
            <Icon
              name={paymentIcon}
              size={paymentIconSize as 's' | 'm'}
              className="CheckoutPharmacy_Icon"
            />
          }
        >
          {paymentText}
        </CheckoutPharmacySection>
      </section>
      <CheckoutPharmacyProductsAvailability
        className="CheckoutPharmacy_Offers"
        isPickupAvailablePartly={isPickupAvailablePartly}
        availabilityText={availabilityText}
        commonUnavailableReason={commonUnavailableReason}
      >
        <CheckoutPharmacyOffers
          className="CheckoutPharmacy_Products"
          unavailableOffers={unavailableOffers}
        />
      </CheckoutPharmacyProductsAvailability>
      {isPickupAvailable && (
        <Button
          type="button"
          variant="filled"
          onClick={onClickPharmacyButton}
          className="CheckoutPharmacy_Button"
        >
          {pickupActionText}
        </Button>
      )}
    </div>
  );
};
