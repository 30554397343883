/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useLayoutEffect } from 'react';
import { Clusterer, InputMarker } from '@2gis/mapgl-clusterer';
import { AnimationOptions } from '@2gis/mapgl/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentPharmacy, selectFilteredPharmacies } from '../../../../../services/redux/store/pharmacies/selectors';
import { setBaseCoordinates } from '../../../../../services/redux/store/pharmacies/slice';
import { useViewSize } from '@npm-registry/eapteka-ui';
import { useMapGLContext } from '../../../../core/2GIS/MapGLContext';
import { MapGLDispatcher } from '../../../../core/2GIS/helpers/dispatcher';
import { PickupPoint } from '../../../../../services/redux/store/pharmacies/types';
import {
  getPlacemarkIcon,
  resetIconSize,
  CLUSTER_ICON
} from './helpers';
import { PHARMACY_CARD_WIDTH } from '../../../../../tools/const';
import './CheckoutPickupMapPlacemarks.scss';

const options: AnimationOptions = {
  easing: 'linear',
  duration: 450
};

export const CheckoutPickupMapPlacemarks = (): null => {
  const { mapglInstance } = useMapGLContext();
  const { isMobile } = useViewSize();
  const dispatch = useDispatch();
  const filteredPickupPoints = useSelector(selectFilteredPharmacies);
  const leftPadding = isMobile
    ? undefined
    : PHARMACY_CARD_WIDTH;
  const bottomPadding = isMobile
    ? (window.visualViewport?.height - 120) / 4
    : undefined;
  const rightPadding = isMobile ? 34 : undefined;
  const currentPickupPoint = useSelector(selectCurrentPharmacy);

  useEffect(() => {
    let clusterer: Clusterer | undefined = undefined;

    if (mapglInstance) {
      mapglInstance.setMinZoom(7);
      clusterer = new Clusterer(mapglInstance, {
        radius: 120,
        clusterStyle: {
          labelColor: '#333F48',
          labelFontSize: 12,
          labelOffset: [-3.5, -2],
          labelRelativeAnchor: [0.5, 0.5],
          size: [48, 48],
          icon: CLUSTER_ICON
        }
      });
      const markers: InputMarker[] = filteredPickupPoints.map(
        (pickupPoint: PickupPoint) => {
          const { image, availabilityText } =
            getPlacemarkIcon(pickupPoint);

          return {
            type: 'html',
            coordinates: pickupPoint.coordinates,
            userData: pickupPoint,
            html: `<div class="MapGL_Marker ${image}" id="pp_${pickupPoint.id}" ><span>${availabilityText}</span></div>`
          };
        }
      );

      clusterer.load(markers);

      // Открытие карточки аптеки со смещением центра карты
      clusterer.on('click', (event) => {
        if (event.target.type === 'marker') {
          resetIconSize();
          const { userData } = event.target.data;
          // @ts-ignore иначе не видит свойство classList
          event.originalEvent.target.classList.add(
            'active'
          );
          mapglInstance.setCenter(
            userData.coordinates,
            options
          );
          MapGLDispatcher.userData.dispatch(userData);
        } else {
          mapglInstance
            .setCenter(event.lngLat)
            .setZoom(mapglInstance.getZoom() + 2);
        }
      });
    }

    return () => {
      if (clusterer) clusterer.destroy();
    };
  }, [
    mapglInstance,
    filteredPickupPoints,
    leftPadding,
    rightPadding,
    bottomPadding
  ]);

  useLayoutEffect(() => {
    if (mapglInstance) {
      mapglInstance.setPadding(
        {
          left: leftPadding,
          bottom: bottomPadding,
          right: rightPadding
        },
        options
      );
    }
  }, [
    bottomPadding,
    leftPadding,
    mapglInstance,
    rightPadding
  ]);

  // Автофокус на избранной аптеке
  useEffect(() => {
    if (currentPickupPoint && mapglInstance) {
      mapglInstance
        .setCenter(currentPickupPoint.coordinates, options)
        .once('centerend', () => {
          mapglInstance
            .setZoom(16, options)
            .once('zoomend', () => {
              document
                .getElementById(
                  `pp_${currentPickupPoint.id}`
                )
                ?.classList.add('active');
              MapGLDispatcher.userData.dispatch(
                currentPickupPoint
              );
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapglInstance,
    leftPadding,
    rightPadding,
    bottomPadding
  ]);

  useEffect(() => {
    const onSetUserMarker =
      MapGLDispatcher.userCoordinates.subscribe(
        (coordinates: number[]) => {
          dispatch(setBaseCoordinates({ latitude: coordinates[1], longitude: coordinates[0] }));
        }
      );

    return () => {
      onSetUserMarker();
    };
  }, [dispatch]);

  return null;
};
