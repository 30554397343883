const baseApiUrl: string = (() => {
  // PROD
  if (location.hostname === 'www.eapteka.ru') {
    return 'https://api.eapteka.ru/api/v3';
  }
  // RELEASE
  if (location.hostname === 'release.eapteka.ru') {
    return 'https://apirelease.eapteka.ru/api/v3';
  }
  // STAGE
  if (process.env.API_URL !== undefined) {
    return process.env.API_URL;
  }
  // DEV
  return '/api/v3';
})();

const config = {
  baseUrl: baseApiUrl,
  apiUrl: {
    getCart: '/cart',
    updateCart: '/cart/update',
    setCart: '/cart/change_items',
    getOffers: '/offers',
    getUser: '/user',
    authUser: '/user/auth',
    checkSMS: '/user/check_sms',
    oauth: '/user/oauth',
    getUserCards: '/user/cards',
    getDelivery: '/delivery_intervals',
    getAllPickupPoints: '/pickup_points',
    getCurrentPickupPoints: '/pickup_points/current',
    getUserOffers: '/user/promocodes',
    historyOrders: '/orders/history',
    feedbackSearch: '/feedback/search',
    getBonusHistoryTransactions:
      '/bonus_program/transactions',
    getAllowDeleteUser: '/user/allow_delete',
    putEditUser: '/user/edit',
    postCheckUser: '/user/check',
    postUserRejectDelete: '/user/reject_delete',
    geocodeAddress: '/geocode/address',
    geocodePoints: '/geocode/points',
    geocodePoint: '/geocode/point',
    geocodeZone: '/geocode/zone',
    getBonusProgramRefferalInfo:
      '/bonus_program/refferal/info',
    getBonusProgramRefferalQuestions:
      '/bonus_program/refferal/questions',
    getBonusProgramRefferalUsers:
      '/bonus_program/refferal/users',
    getSpecials: '/specials',
    getUserSubscriptions: '/user/subscriptions',
    postUserSubscriptions: '/user/subscriptions',
    checkout: '/checkout',
    payment: '/proxy/order_payment',
    addresses: '/addresses',
    getUserAddresses: '/addresses',
    postHandbook: '/handbook',
    regions: '/regions',
    orders: '/orders',
    getOffersEan: '/offers/ean',
    getUserPopular: '/user/popular',
    getUserPromoCodes: '/user/promocodes',
    approvals: '/user/approvals/',
    deliveryErecipe: '/delivery/erecipe',
    getInstallmentContent: '/bnpl_content',
    getInstallmentPayments: '/bnpl_payments?totalSum=',
    handbook: '/handbook/web/features',
    handbookSber: '/handbook/sber/features',
    userLoyalty: '/user/loyalty',
    sberspasibo: '/checkout/sberspasibo',
    sberspasiboEarn: '/sber_bonuses/earn?totalSum='
  },

  apiHeaders: {
    'api-key': 'K<8RNCa^ft86LnNa',
    'platform': 'frontend',
    'region-id': window?.BX?.City?.ID || '672',
    'version': '3.6.4',
    'Content-Type': 'application/json',
  }
};

if (process.env.NODE_ENV === 'development') {
  Object.assign(config, {
    apiHeaders: {
      ...config.apiHeaders,
      Authorization: 'Basic ZWFwdGVrYTpzdGFnZQ==',
    }
  })
}


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const apiPathSetter = (configUpdateFunction) => {
  Object.assign(config, configUpdateFunction(config));
};

export default config;
