/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback } from 'react';
import {
  Icon,
  Link,
  Title
} from '@npm-registry/eapteka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIsCorporate } from '../../../services/redux/store/user/selectors';
import { setUserCorporate } from '../../../services/redux/store/user/slice';
import { selectCartData } from '../../../services/redux/store/cart/selectors';
import { CART_LINK } from '../../../tools/const';
import {
  CheckoutRecipientType,
  RECIPIENT_TYPE_LABELS
} from '../CheckoutRecipientType';
import './CheckoutMainHeader.scss';

export const CheckoutMainHeader = () => {
  const dispatch = useDispatch();
  const isCorporate = useSelector(selectUserIsCorporate);
  const { hasERecipes, hasItemPreorder } = useSelector(selectCartData);

  const onRecipientTypeChange = useCallback(
    (label: keyof typeof RECIPIENT_TYPE_LABELS) => {
      dispatch(
        setUserCorporate(
          label === RECIPIENT_TYPE_LABELS.corporate
        )
      );
    },
    [dispatch]
  );

  return (
    <section className="CheckoutMainHeader_Container">
      <Title className="CheckoutMainHeader_Title" tag="h1">
        Оформление
      </Title>
      <Link
        className="CheckoutMainHeader_Link"
        href={CART_LINK}
        aria-label="Вернуться в корзину"
      >
        <Icon
          className="CheckoutMainHeader_Icon"
          name="arrowBack"
          size="m"
        />
        Вернуться в корзину
      </Link>
      <CheckoutRecipientType
        isCorporate={isCorporate}
        hasERecipes={hasERecipes}
        hasPreorder={hasItemPreorder}
        onChange={onRecipientTypeChange}
      />
    </section>
  );
};
