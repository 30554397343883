export const RECEIVING_METHODS_VIEW_TYPE_KEYS = {
  pickup: 'pickup',
  delivery: 'delivery'
};

export const RECEIVING_METHODS_IDS = {
  [RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery]: 1,
  [RECEIVING_METHODS_VIEW_TYPE_KEYS.pickup]: 2
};

export const RECEIVING_METHODS_KEYS_BY_IDS = {
  1: RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery,
  2: RECEIVING_METHODS_VIEW_TYPE_KEYS.pickup
};

export const RECEIVING_METHODS_VIEW_TYPE_LABELS = {
  [RECEIVING_METHODS_VIEW_TYPE_KEYS.pickup]: {
    key: RECEIVING_METHODS_VIEW_TYPE_KEYS.pickup,
    label: 'Забрать из аптеки',
    subtitle: 'Бесплатно'
  },
  [RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery]: {
    key: RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery,
    label: 'Доставка',
    subtitle: 'От 179 ₽'
  }
};

export const RECEIVING_METHODS_KEYS = [
  RECEIVING_METHODS_VIEW_TYPE_KEYS.pickup,
  RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery
];
