import React from 'react';
import { useSelector } from 'react-redux';
import { selectRegion } from '../../../services/redux/store/region/selectors';
import { getOriginWithRegion } from '../../../tools/region/getOriginWithRegion';
import './CheckoutFooter.scss';

const FULL_YEAR = new Date().getFullYear();

export const CheckoutFooter = () => {
  const region = useSelector(selectRegion);
  const originUrl = getOriginWithRegion(region.code);

  return (
    <footer className="CheckoutFooter">
      © {FULL_YEAR} eАптека. Все права защищены
      <br />
      <a
        className="CheckoutFooterLink"
        href={originUrl + '/company/policy/'}
      >
        Политика конфиденциальности
      </a>
    </footer>
  );
};
