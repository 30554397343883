import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@npm-registry/eapteka-ui';
import { ScreenModal } from '../../../core/ScreenModal';
import { CheckoutModalHeader } from '../../../elements/CheckoutModalHeader';
import { CheckoutDeliveryDates } from './Dates/DeliveryDates';
import { CheckoutDeliveryIntervals } from './Intervals/CheckoutDeliveryIntervals';
import { selectCurrentInterval, selectIntervalStatus } from '../../../../services/redux/store/delivery/selectors';
import { DeliveryDatesSkeletons } from './Skeletons';
import { setIsDeliveryIntervalModalOpen } from '../../../../services/redux/store/ui/slice';
import './CheckoutDeliveryDatetime.scss';

export const CheckoutDeliveryDatetime = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(selectIntervalStatus) === 'SUCCESS';
  const currentInterval = useSelector(selectCurrentInterval);
  const onClose = useCallback(() => {
    dispatch(setIsDeliveryIntervalModalOpen(false));
  }, [dispatch]);

  return (
    <ScreenModal
      onClose={onClose}
      rootClassName="CheckoutDeliveryScreen"
      className="CheckoutDeliveryScreen_Body"
    >
      <div className="CheckoutDeliveryDatetimeContent">
        <CheckoutModalHeader
          onClose={onClose}
          title="Время доставки"
        />
        <p className="CheckoutDeliveryDatetime_Subtitle">
          Выберите, когда вам удобно получить заказ
        </p>
        {!isLoaded ? (
          <DeliveryDatesSkeletons />
        ) : (
          <>
            <CheckoutDeliveryDates />
            <CheckoutDeliveryIntervals />
            <Button
              onClick={onClose}
              disabled={!currentInterval}
              fullWidth
            >
              Выбрать
            </Button>
          </>
        )}
      </div>
    </ScreenModal>
  );
};
