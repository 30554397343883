/* eslint-disable no-use-before-define */
import { Level, Levels, LevelsResponse, RecipientKeys, User, UserResponse } from "./types";

export const serializeUser = (user: UserResponse): User => ({
  acceptedCheckErecipe: user.accepted_check_erecipe,
  birthday: user.birthday,
  bonusProgram: {
    about: user.bonus_program.about,
    aboutBonus: user.bonus_program.about_bonus,
    aboutExpire: user.bonus_program.about_expire,
    active: user.bonus_program.active,
    balance: user.bonus_program.balance,
    bonusThreshold: user.bonus_program.bonus_threshold,
    doublePointsOrdersLeft: user.bonus_program.double_points_orders_left,
    emailConfirmed: user.bonus_program.email_confirmed,
    emailVerified: user.bonus_program.email_verified,
    level: user.bonus_program.level,
    levelLifetime: user.bonus_program.level_lifetime,
    levels: serializeLevels(user.bonus_program.levels),
    phoneVerified: user.bonus_program.phone_verified,
    referralCode: user.bonus_program.referral_code,
    totalCollected: user.bonus_program.total_collected,
  },
  callbackRoute: user.callback_route,
  cartToken: user.cart_token,
  email: user.email,
  favoriteStore: +user.favorite_store,
  id: user.id,
  isCorporate: serializeRecipient('isCorporate'),
  name: user.name,
  phone: user.phone,
  promocode: {
    countActive: user.promocode.count_active,
  },
  refferalCode: user.refferal_code,
  recipient: {
    name: serializeRecipient('recipientFullName'),
    phone: serializeRecipient('recipientPhone'),
    isCollectedByOtherPerson: serializeRecipient('isVisibleForm'),
  },
  company: {
    inn: serializeRecipient('inn'),
    kpp: serializeRecipient('kpp'),
    name: serializeRecipient('corporateName'),
  },
  register: user.register,
  sberIdEnabled: user.sber_id_enabled,
  sberprime: user.sberprime ? {
    cashback: user.sberprime.cashback,
    delivery: {
      freeDisabled: user.sberprime.delivery.free_disabled,
      freeFrom: user.sberprime.delivery.free_from,
      price: user.sberprime.delivery.price,
    },
    expire: user.sberprime.expire,
    image: user.sberprime.image,
    imageShort: user.sberprime.image_short,
    isActive: new Date(user.sberprime.expire) > new Date(),
    manageLink: user.sberprime.manage_link,
    privilege: user.sberprime.privilege,
    subscriptionType: user.sberprime.subscription_type,
  } : undefined,
  userDeleteTimeout: user.user_delete_timeout,
  userToken: user.user_token,
});

function serializeLevels(levels: LevelsResponse): Levels {
  const result = {} as Levels;
  const keys = Object.keys(levels).map((key: Level) => key);

  keys.forEach((key) => {
    return result[key] = {
      canHaveDoubleBonus: levels[key].can_have_double_bonus,
      name: levels[key].name,
      nextLevelLeft: levels[key].next_level_left,
      nextLevelSum: levels[key].next_level_sum,
      nextLevelTitle: levels[key].next_level_title,
      percent: levels[key].percent,
      percentGet: levels[key].percent_get,
      subtext: levels[key].subtext,
      text: levels[key].text,
    };
  });

  return result;
}

function serializeRecipient<T extends RecipientKeys>(field: T) {
  const recipientData = JSON.parse(localStorage.getItem('checkout-order-recipient'));

  if (!recipientData) {
    return false;
  }

  if (['isCorporate', 'isVisibleForm'].includes(field) && !recipientData[field]) {
    return false;
  }
  if (!recipientData[field]) {
    return '';
  }

  return recipientData[field];
}
