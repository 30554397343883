import { useSelector } from 'react-redux';
import { selectRegion } from '../../../../../services/redux/store/region/selectors';
import { selectCartData, selectCartStatus } from '../../../../../services/redux/store/cart/selectors';
import { selectUserIsCorporate } from '../../../../../services/redux/store/user/selectors';

export const useDeliveryAvailable = () => {
  const { isDeliveryAvailable } = useSelector(selectRegion);
  const isCartFetched = useSelector(selectCartStatus) === 'SUCCESS';
  const isCorporate = useSelector(selectUserIsCorporate)
  const {
    hasItemWithPku,
    hasItemWithRecipe,
    hasItemWithAlcohol,
    hasItemWithRecipeOnly,
    hasERecipes
  } = useSelector(selectCartData);

  const cartHasRestrictions =
    hasItemWithPku ||
    hasItemWithRecipe ||
    hasItemWithAlcohol ||
    hasItemWithRecipeOnly;

  if (!isCartFetched) {
    return true;
  }

  return (
    (isDeliveryAvailable && !cartHasRestrictions)
    || isCorporate
    || hasERecipes
  );
};
