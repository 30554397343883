import { useState } from 'react';
import { validateFullName } from '../../../../tools/validation/validateFullName/validateFullName';

export function useFormFullName(fullName, callback) {
  const [hasErrorFullName, setHasErrorFullName] =
    useState(false);
  const [errorFullNameMessage, setErrorFullNameMessage] =
    useState('');

  const onBlurFullName = () => {
    const { hasError, errorMessage } =
      validateFullName(fullName);

    setHasErrorFullName(hasError);
    setErrorFullNameMessage(errorMessage);

    if (!hasError) {
      callback(fullName);
    } else {
      callback('');
    }
  };

  const onValidateFullName = (fullName) => {
    const { hasError, errorMessage } =
      validateFullName(fullName);

    setHasErrorFullName(hasError);
    setErrorFullNameMessage(errorMessage);
  };

  return {
    onBlurFullName,
    onValidateFullName,
    hasErrorFullName,
    errorFullNameMessage
  };
}
