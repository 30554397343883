import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegion } from '../../../services/redux/store/region/selectors';
import { CheckoutHeader } from '../../layouts/CheckoutHeader';
import { ScreenModal } from '../../core/ScreenModal/Screen';
import { CheckoutDeliveryAddressEditForm } from './Form';
import {
  MapControls,
  MapControlsGeolocation,
  MapControlsZoom,
  MapGL,
  MapGLContextProvider,
  MapGLUserMarker
} from '../../core/2GIS';
import { setIsDeliveryScreenOpen } from '../../../services/redux/store/ui/slice';
import './CheckoutDeliveryAddressEdit.scss';

export const CheckoutDeliveryAddressEdit = () => {
  const dispatch = useDispatch();
  const { name } = useSelector(selectRegion);

  const onClose = useCallback(() => {
    dispatch(setIsDeliveryScreenOpen(false));
  }, [dispatch]);

  return (
    <ScreenModal>
      <section className="CheckoutDeliveryAddressEdit">
        <CheckoutHeader onClickUndo={onClose} title={name} />
        <MapGLContextProvider>
          <div className="CheckoutDeliveryMap_Container">
            <div className="CheckoutDeliveryMap_Wrapper">
              <MapGL />
              <MapGLUserMarker />
              <MapControls>
                <MapControlsZoom />
                <MapControlsGeolocation />
              </MapControls>
            </div>
            <CheckoutDeliveryAddressEditForm />
          </div>
        </MapGLContextProvider>
      </section>
    </ScreenModal>
  );
};
