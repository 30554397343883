/* eslint-disable no-use-before-define */
import {
  Cart,
  CartItem,
  CartItemResponse,
  CartResponse,
  CartUpdatePayload,
  Offer,
  OfferResponse,
  OffersResponse,
} from './types';

export const serializeCartAndOffers = (
  cart: CartResponse,
  offers: OffersResponse
): Cart => {
  const cartItems = serializeCartItems(cart);
  const offerItems = serializeOfferItems(offers);
  const items = serializeItems(cartItems, offerItems);
  const discount = serializeDiscount(items);
  const hasOnlinePayment = serializeOnlinePaymentAvailability(items);
  const availableItems = items.filter((offer) => offer.isActive && offer.inOrder);
  const unavailableItems = items.filter((offer) => !offer.isActive || !offer.inOrder);
  const availableOffersPrice = items.filter(item => item.inOrder && item.isActive).reduce((prev, { price, amount }) => (prev += price * amount), 0);
  const unavailableOffersPrice = items.filter(item => !item.inOrder || !item.isActive).reduce((prev, { price, amount }) => (prev += price * amount), 0);
  const promocodeAmount = localStorage.getItem('promoCodeApplied') ? items.reduce((prevItem, item)=>{
    return prevItem += (item.oldPrice - item.price) * item.amount
  }, 0) : 0;

  return {
    bonusProgram: {
      canWithdraw: cart.bonus_program?.can_withdraw || false,
      earn: cart.bonus_program?.earn || 0,
      reasonNotWithdraw: cart.bonus_program?.reason_not_withdraw || '',
      withdraw: cart.bonus_program?.withdraw || 0,
    },
    checkout: {
      legalInfo: cart.checkout_legal_info,
      legalLinks: cart.checkout_legal_links,
    },
    data: {
      hasERecipes: false,
      hasItemBenefit: items.some((offer) => offer.recipeType === 'benefit'),
      hasItemBenefitOnly: items.every((offer) => offer.recipeType === 'benefit'),
      hasItemPreorder: items.some((offer) => offer.preorder),
      hasItemPreorderOnly: items.every((offer) => offer.preorder),
      hasItemRecipeDeliveryAllowed: items.some((offer) => offer.recipeDeliveryAllowed),
      hasItemWithAlcohol: items.some((offer) => offer.hasAlcohol),
      hasItemWithPku: items.some((offer) => offer.isPku),
      hasItemWithRecipe: items.some((offer) => offer.isRecipe),
      hasItemWithRecipeOnly: items.some((offer) => offer.recipeOnly),
      hasOnlinePayment,
    },
    items,
    availableItems,
    unavailableItems,
    offersData: {
      availableOffersPrice,
      availableOffersCount: items
        .filter((offer) => offer.isActive && offer.inOrder)
        .reduce((prev, { amount }) => (prev += amount), 0),
      availablePositionsCount: items.filter(offer => offer.isActive && offer.inOrder).length,
      totalPositionsCount: offers.count,
      totalOffersCount: items
      .reduce((prev, { amount }) => (prev += amount), 0),
      unavailableOffersPrice,
      unavailablePositionsCount: items.filter(offer => !offer.isActive || !offer.inOrder).length,
      unavailableOffersCount: items
      .filter((offer) => !offer.isActive || !offer.inOrder)
        .reduce((prev, { amount }) => (prev += amount), 0),
    },
    summary: {
      discount,
      promocodeAmount,
      totalPrice: availableOffersPrice,
      totalPriceComponents: {
        availableOffersPrice,
        unavailableOffersPrice,
        bonusWithdraw: 0,
        deliveryPrice: 0,
        spasiboWithdraw: 0,
        fee: 0,
      },
    },
    token: cart.cart_token,
  };
};

export const serializeCartItemsIds = (cart: CartResponse): string => {
  const result: string[] = [];

  cart.cart_items.forEach((item) => result.push(String(item.id)));

  return result.join(',');
};

export const serializeCartUpdate = (cart: CartResponse): CartUpdatePayload => {
  const items = cart.cart_items.map(({ id, amount }) => ({
    id,
    amount
  }));

  return {
    cart_items: items,
    loyalty_programs: [
      {
        program: 'discount',
        code: localStorage.getItem('promoCodeApplied') || '',
      }
    ],
    bonus_program: {
      withdraw: false
    }
  };
};

function serializeCartItems(cart: CartResponse): CartItem[] {
  return cart.cart_items.map((item: CartItemResponse) => ({
    amount: item.amount,
    basePrice: item.base_price,
    brand: item.brand,
    byRecipe: item.by_recipe,
    category: item.category,
    delayed: item.delayed,
    deliveryDate: item.delivery_date,
    expired: item.expired,
    hasAlcohol: item.has_alcohol,
    id: item.id,
    inOrder: item.in_order,
    isActive: item.is_active,
    isERecipe: item.provider && item.recipe && item.recipe_type !== 'benefit',
    isPku: item.is_pku,
    isRecipe: item.is_recipe,
    isStm: item.is_stm,
    isVmt: item.is_vmt,
    name: item.name,
    oldPrice: item.old_price,
    preorder: item.preorder,
    price: item.price,
    provider: item.provider,
    recipe: item.recipe,
    recipeOnly: item.recipe_only,
    recipeSource: item.recipe_source,
    recipeType: item.recipe_type,
    takeAway: {
      isPickupAvailable: item.take_away.is_pickup_available,
      isDeliveryAvailable: item.take_away.is_delivery_available,
    },
    timestamp: item.timestamp,
    weight: item.weight,
    xmlId: item.xml_id,
  }));
}

function serializeOfferItems(offers: OffersResponse): Offer[] {
  return offers.offers.map((offer: OfferResponse) => ({
    active: offer.active,
    adultsOnly: offer.adults_only,
    availableCount: offer.available_count,
    canPartnerPickup: offer.can_partner_pickup,
    deliveryDate: offer.delivery_date,
    discount: {
      percent: offer.discount.percent,
      from: offer.discount.from,
      discountPrice: offer.discount.discount_price,
    },
    ean: offer.ean,
    fastDelivery: offer.fast_delivery,
    hasAlcohol: offer.has_alcohol,
    id: offer.id,
    ingredients: offer.ingredients,
    installment: {
      isOfferInstallmentAvailable:
        offer.installment.is_offer_installment_available,
      installmentRange: offer.installment.installment_range,
    },
    isExp: offer.is_exp,
    isMdlp: offer.is_mdlp,
    isOriginal: offer.is_original,
    isPku: offer.is_pku,
    isRecipe: offer.is_recipe,
    isStm: offer.is_stm,
    isVmt: offer.is_vmt,
    keepInCold: offer.keep_in_cold,
    minBuyAmount: offer.min_buy_amount,
    minPrice: offer.min_price,
    name: offer.name,
    oldPrice: offer.old_price,
    pickupOnly: offer.pickup_only,
    pictures: offer.pictures,
    preview: offer.pictures[0],
    preorder: offer.preorder,
    price: offer.price,
    rate: offer.rate,
    recipeDeliveryAllowed: offer.recipe_delivery_allowed,
    recipeOnly: offer.recipe_only,
    shortName: offer.short_name,
    sort: offer.sort,
    storeCount: offer.store_count,
    type: offer.type,
    variants: offer.variants,
    vendor: offer.vendor,
    weight: offer.weight,
  }));
}

function serializeItems(
  cartItems: CartItem[],
  offerItems: Offer[]
): Array<CartItem & Offer> {
  return cartItems.map((item) => {
    const offerItem = offerItems.find((offer) => offer.id === item.id);
    return {
      active: offerItem!.active,
      adultsOnly: offerItem!.adultsOnly,
      amount: item.amount,
      availableCount: offerItem!.availableCount,
      basePrice: item.basePrice,
      brand: item.brand,
      byRecipe: item.byRecipe,
      canPartnerPickup: offerItem!.canPartnerPickup,
      category: item.category,
      delayed: item.delayed,
      deliveryDate: item.deliveryDate,
      discount: offerItem!.discount,
      ean: offerItem!.ean,
      expired: item.expired,
      fastDelivery: offerItem!.fastDelivery,
      hasAlcohol: offerItem!.hasAlcohol,
      id: item.id,
      ingredients: offerItem!.ingredients,
      inOrder: item.inOrder,
      installment: offerItem!.installment,
      isActive: item.isActive,
      isERecipe: item.isERecipe,
      isExp: offerItem!.isExp,
      isMdlp: offerItem!.isMdlp,
      isOriginal: offerItem!.isOriginal,
      isPku: offerItem!.isPku,
      isRecipe: offerItem!.isRecipe,
      isStm: offerItem!.isStm,
      isVmt: offerItem!.isVmt,
      keepInCold: offerItem!.keepInCold,
      minBuyAmount: offerItem!.minBuyAmount,
      minPrice: offerItem!.minPrice,
      name: item.name,
      oldPrice: offerItem!.oldPrice,
      pickupOnly: offerItem!.pickupOnly,
      price: item.price,
      pictures: offerItem!.pictures,
      preview: offerItem!.pictures[0],
      preorder: offerItem!.preorder,
      provider: item.provider,
      rate: offerItem!.rate,
      recipe: item.recipe,
      recipeDeliveryAllowed: offerItem!.recipeDeliveryAllowed,
      recipeOnly: offerItem!.recipeOnly,
      recipeType: item.recipeType,
      recipeSource: item.recipeSource,
      shortName: offerItem!.shortName,
      sort: offerItem!.sort,
      storeCount: offerItem!.storeCount,
      takeAway: item.takeAway,
      timestamp: item.timestamp,
      type: offerItem!.type,
      variants: offerItem!.variants,
      vendor: offerItem!.vendor,
      weight: offerItem!.weight,
      xmlId: item.xmlId,
    };
  });
}

function serializeDiscount(items: Array<CartItem & Offer>): number {

  const result = items.filter((offer) => offer.inOrder).reduce((prevSum, item) => {

      const isDiscounted = item.loyaltyPrograms?.length > 0;
      const oldPrice = item.oldPrice > 0 ? item.oldPrice : isDiscounted ? item.price : 0;
      const discount = oldPrice > item.price ? (oldPrice - item.price ) * item.amount : 0;

      return prevSum += discount;
    }, 0);

  return Math.round(result);
}

function serializeOnlinePaymentAvailability(
  items: Array<CartItem & Offer>
): boolean {
  return !items.some(
    (item) =>
      item.hasAlcohol ||
      item.isPku ||
      item.isRecipe ||
      item.recipeOnly ||
      item.recipeType === 'benefit'
  );
}
