export const FETCH_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  IDLE: 'IDLE',
} as const;

export const STORE_NAMES = {
  RECEIVING: 'receiving',
  PAYMENT: 'payment',
  HANDBOOK: 'handbook',
  REGION: 'region',
  USER: 'user',
  CART: 'cart',
  DELIVERY: 'delivery',
  UI: 'ui',
  PHARMACIES: 'pharmacies',
}
