import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CheckoutPharmacyCard } from '../PharmacyCard';
import { MapGLDispatcher } from '../../../../core/2GIS/helpers/dispatcher';
import { CheckoutPickupFilters } from '../../Overlay/Filters';
import { CheckoutPickupViewType } from '../../Overlay/ViewType';
import { resetIconSize } from '../Placemarks/helpers';
import type { PickupPoint } from '../../../../../services/redux/store/pharmacies/types';
import './Balloon.scss';

export const Balloon = () => {
  const [pharmacy, setPharmacy] = useState<PickupPoint>(null);
  const rootClassNames = clsx('CheckoutMapBalloon_Root', {
    ['CheckoutMapBalloon_Root--selected']: !!pharmacy
  });
  const handleMinimize = () => {
    setPharmacy(null);
    resetIconSize();
  };

  useEffect(() => {
    const balloonData =
      MapGLDispatcher.userData.subscribe(setPharmacy);

    return () => {
      balloonData();
      handleMinimize();
    };
  }, []);

  return (
    <div className={rootClassNames}>
      <div className="CheckoutMapBalloon_Pharmacy">
        {pharmacy && (
          <CheckoutPharmacyCard
            pharmacy={pharmacy}
            onClose={handleMinimize}
          />
        )}
      </div>
      <div className="CheckoutMapBalloon_Controls">
        <CheckoutPickupFilters />
        <CheckoutPickupViewType
          handleMinimize={handleMinimize}
        />
      </div>
    </div>
  );
};
