import { Skeleton } from '@npm-registry/eapteka-ui';
import React from 'react';

// TODO: нужно перейти на классы
export const DeliveryDatesSkeletons = () => {
  return (
    <div style={{ marginTop: '32px' }}>
      <div style={{ display: 'flex' }}>
        <Skeleton
          height="5em"
          width="7em"
          style={{ marginRight: '0.5em' }}
        />
        <Skeleton
          height="5em"
          width="7em"
          style={{ marginRight: '0.5em' }}
        />
        <Skeleton
          height="5em"
          width="7em"
          style={{ marginRight: '0.5em' }}
        />
        <Skeleton
          height="5em"
          width="7em"
          style={{ marginRight: '0.5em' }}
        />
        <Skeleton height="5em" width="7em" />
      </div>
      <div style={{ marginTop: '32px' }}>
        <Skeleton
          height="4em"
          style={{ marginBottom: '0.5em' }}
        />
        <Skeleton
          height="4em"
          style={{ marginBottom: '0.5em' }}
        />
        <Skeleton
          height="4em"
          style={{ marginBottom: '0.5em' }}
        />
        <Skeleton
          height="4em"
          style={{ marginBottom: '0.5em' }}
        />
      </div>
    </div>
  );
};
