import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@npm-registry/eapteka-ui';
import { selectCartSummary } from '../../../../services/redux/store/cart/selectors';
import { useOrderSummary } from '../hooks/useOrderSummary';
import { useButtonAbility } from './hooks/useButtonAbility';
import { formatCurrency } from '../../../../tools/formatters/formatCurrency/formatCurrency';
import './CheckoutSubmitOrderButton.scss';

export function SubmitOrderButton() {
  const { totalPrice, discount } = useSelector(selectCartSummary);
  const { onConfirmOrderSubmit } = useOrderSummary();
  const isDisabled = useButtonAbility();

  return (
    <Button
      type="button"
      className="CheckoutOrderSummary_Submit"
      onClick={onConfirmOrderSubmit}
      disabled={isDisabled}
    >
      <span>Оформить</span>
      <span className="CheckoutOrderSummary_SubmitPrice">
        <strong className="CheckoutOrderSummary_SubmitPriceCurrent">
          {formatCurrency(totalPrice, true)}
        </strong>
        {discount > 0 && (
          <small className="CheckoutOrderSummary_SubmitPriceOld">
            {formatCurrency(discount, true)}
          </small>
        )}
      </span>
    </Button>
  );
}
