import React from 'react';
import { Icon } from '@npm-registry/eapteka-ui';
import { CheckoutPharmacy } from '../../../CheckoutPharmacy/CheckoutPharmacy';

export const CheckoutPharmacyCard = ({
  pharmacy,
  onClose
}) => {
  return (
    <div className="CheckoutPharmacy_Container">
      <button
        type="button"
        onClick={onClose}
        className="CheckoutPharmacy_Button__Close"
        aria-label="Закрыть шторку с аптекой"
      >
        <Icon name="close" size="m" aria-hidden="true" />
      </button>
      <CheckoutPharmacy pickupPoint={pharmacy} />
    </div>
  );
};
