import React, { useMemo } from 'react';
import { Icon } from '@npm-registry/eapteka-ui';
import './CheckoutPharmacyAddress.scss';

export const CheckoutPharmacyAddress = ({
  pharmacy,
  isFavorite = false
}) => {
  const { address, name, metroColor, workingHours } = pharmacy;

  const addressField = useMemo(() => {
    return isFavorite ? `${name}, ${address}` : address;
  }, [address, isFavorite, name]);

  return (
    <div className="CheckoutPharmacyAddress_Root">
      {metroColor && (
        <Icon
          name="metro"
          size="s"
          className="CheckoutPharmacyAddress_MetroIcon"
          style={{ fill: metroColor }}
        />
      )}
      <p className="CheckoutAddress_Title">
        {addressField} <br />
        <small className="CheckoutPharmacyAddress_WorkingHours">
          {workingHours}
        </small>
      </p>
    </div>
  );
};
