/* eslint-disable no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from '../rootReducer';
import { User, UserStore } from './types';
import { FetchStatus } from '../types';

type UserSelectors = {
  selectUser: (state: UserStore) => User;
  selectUserStatus: (state: UserStore) => FetchStatus;
}

export const selectUserState = (state: TRootState): UserStore => state.user;
export const selectUserStateStatus = (state: TRootState): UserStore => state.user;

export const selectUser = createSelector(selectUserState, (state) => state.user);
export const selectUserStatus = createSelector(selectUserStateStatus, (state) => state.status);
export const selectUserIsCorporate = createSelector(selectUserState, (state) => state.user?.isCorporate);
export const selectUserRecipient = createSelector(selectUserState, (state) => state.user?.recipient);
export const selectUserCompany = createSelector(selectUserState, (state) => state.user?.company);


export default {
  selectUser: (state) => state.user,
  selectUserStatus: (state) => state.status,
} as UserSelectors
