import config from '../../../../config';
import type {
  CartResponse,
  CartUpdatePayload,
  OffersResponse
} from '../../../redux/store/cart/types';
import { API_SERVICE } from '../../api';

export const getCartRequest = API_SERVICE.createGetMethod<
  undefined,
  CartResponse
>(config.apiUrl.getCart);

export const getCartUpdateRequest =
  API_SERVICE.createPostMethod<
    CartUpdatePayload,
    CartResponse
  >(config.apiUrl.updateCart, {
    mapRequest: (payload) => ({
      body: JSON.stringify(payload)
    })
  });

export const getOffersRequest = API_SERVICE.createGetMethod<
  { ids: string },
  OffersResponse
>(config.apiUrl.getOffers, {
  mapRequest: ({ ids }, { currentUrl }) => ({
    url: `${currentUrl}/${ids}`
  })
});

export const getDeliveryERecipe =
  API_SERVICE.createGetMethod<
    undefined,
    {
      result: boolean;
      message: string;
    }
  >(config.apiUrl.deliveryErecipe, {
    mapRequest: () => ({
      headers: {
        features: 'erecipe_new_api_flow'
      },
    })
  });
