/* eslint-disable no-unused-vars */
import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "./rootReducer";

const selectData = (state: TRootState) => state;

export const selectEssentialDataLoaded = createSelector([selectData], (data) => {
  const { cart, handbook, region, user } = data;

  return [cart, handbook, region, user].every(field => field.status === 'SUCCESS');
})

