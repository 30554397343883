import React, { type JSX } from 'react';
import './MapControls.scss';

export const MapControls = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return <div className="MapControls">{children}</div>;
};
