import { createRestApiPreset } from '@npm-registry/helpers/request';
import { getTokensFromStorage } from '../../tools/getTokensFromStorage';
import config from '../../config';

export const apiService = createRestApiPreset(
  config.baseUrl,
  {
    credentials: 'include',
    headers: {
      ...config.apiHeaders,
      ...getTokensFromStorage('userToken')
    }
  }
);
