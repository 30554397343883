import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from '../rootReducer';
import { CartStore } from './types';

const selectCartState = (state: TRootState): CartStore => state.cart;

const selectCartInnerState = (state: TRootState): CartStore['cart'] => state.cart.cart;

export const selectCart = createSelector(selectCartState, (state) => state.cart);

export const selectCartStatus = createSelector(selectCartState, (state) => state.status);

export const selectCartData = createSelector(selectCartInnerState, (state) => state.data);

export const selectCartItems = createSelector(selectCartInnerState, (state) => state.items);

export const selectCartAvailableItems = createSelector(selectCartInnerState, (state) => state.availableItems);

export const selectCartUnavailableItems = createSelector(selectCartInnerState, (state) => state.unavailableItems);

export const selectCartCheckout = createSelector(selectCartInnerState, (state) => state.checkout);

export const selectCartBonusProgram = createSelector(
  selectCartInnerState,
  (state) => state.bonusProgram
);

export const selectCartSummary = createSelector(selectCartInnerState, (state) => state.summary);

export const selectCartOffersData = createSelector(
  selectCartInnerState,
  (state) => state.offersData
);

export const selectCartTotalPriceComponents = createSelector(selectCartInnerState, (state) => state.summary.totalPriceComponents);
