import { useSelector } from 'react-redux';
import { selectCartData } from '../../../../../../services/redux/store/cart/selectors';
import { selectPayment } from '../../../../../../services/redux/store/payment/selectors';
import { selectCurrentAddress, selectCurrentDate } from '../../../../../../services/redux/store/delivery/selectors';

export function useReceivingMethodDelivery() {
  const currentDeliveryAddress = useSelector(selectCurrentAddress);
  const isErecipeDeliveryAllowed = false;
  const currentDeliveryDate = useSelector(selectCurrentDate);
  const { hasERecipes } = useSelector(selectCartData);
  const { paymentTypes } = useSelector(selectPayment);
  const paymentMethodOnline = paymentTypes.find(
    (paymentMethod) =>
      paymentMethod.code === 'card'
  );
  const paymentMethodInstallment = paymentTypes.find(
    (paymentMethod) =>
      paymentMethod.code === 'bnpl'
  );

  const isDeliveryErecipeUnavailable = !isErecipeDeliveryAllowed && hasERecipes;
  const isAddressUnavailable =
    !currentDeliveryAddress?.zoneId ||
    !!(
      currentDeliveryAddress &&
      currentDeliveryDate &&
      currentDeliveryDate.intervals.length === 0
    ) ||
    isDeliveryErecipeUnavailable;

  let contactLessAvailableText = '';

  if (paymentMethodInstallment) {
    contactLessAvailableText =
      'Доступно при онлайн-оплате и оплате частями';
  } else if (paymentMethodOnline) {
    contactLessAvailableText = 'Доступно при онлайн-оплате';
  }

  return {
    contactLessAvailableText,
    isAddressUnavailable,
  };
}
