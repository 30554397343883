import React, { FC } from 'react';

interface Bubbles {
  bubblesCount?: number;
  paymentDates?: Array<{ date: Date }>;
}

const formattedDay = new Intl.DateTimeFormat('ru-RU', {
  day: 'numeric'
});

const formattedMonth = new Intl.DateTimeFormat('ru-RU', {
  month: 'short'
});

export const Bubbles: FC<Bubbles> = ({
  bubblesCount,
  paymentDates
}) => {
  const bubbles = Array.from(
    Array(bubblesCount).keys()
  ).map((i) => {
    return {
      number: i + 1,
      day: paymentDates
        ? formattedDay.format(
            new Date(paymentDates[i].date)
          )
        : null,
      month: paymentDates
        ? formattedMonth.format(
            new Date(paymentDates[i].date)
          )
        : null
    };
  });

  return (
    <div className="InstallmentBNPL_Bubbles">
      {bubbles.map(({ number, day, month }) => (
        <div
          key={number}
          className="InstallmentBNPL_Bubble"
        >
          <div className="InstallmentBNPL_BubbleCounter">
            {number}
          </div>
          {day && (
            <div className="InstallmentBNPL_BubbleDate">
              {day}
              <br />
              {month}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
