export function sendAmplitudeAnalyticsEvent({
  id,
  name,
  customParams = {}
}) {
  const dataLayer = window.dataLayer;

  if (dataLayer && dataLayer.push && id && name) {
    const params = {
      event: 'addEvents_oldma',
      event_id: id,
      event_name: name
    };

    if (customParams) {
      for (const [key, value] of Object.entries(
        customParams
      )) {
        params[key] =
          typeof value === 'string'
            ? value
            : JSON.stringify(value);
      }
    }

    dataLayer.push(params);
  }
}
