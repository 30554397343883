/* eslint-disable no-use-before-define */
import dateTimeHelper from '@npm-registry/helpers/datetime';
import { PaymentMethodsIcons, FilterKeys, PickupPoint, PickupPointCurrentResponse } from './types';
import { Cart } from '../cart/types';

export function getOffersStoredInfo({
  pickupDate,
  shelfLife,
  isPickupInAnHour,
  isPickupAvailable
}: {
  pickupDate: number | string | Date,
  shelfLife: number,
  isPickupInAnHour: boolean,
  isPickupAvailable: boolean,
}) {
  if (isPickupAvailable) {
    const storeFrom = formatPickupExpireDate(
      new Date(pickupDate),
      shelfLife
    );
    const storeTo = formatPickupReadyText(
      isPickupInAnHour,
      pickupDate
    );

    return {
      storeFrom,
      storeTo
    };
  }

  return { storeFrom: '', storeTo: '' };
}

function formatPickupReadyText(
  isPickupInAnHour: boolean,
  datetime: number | string | Date
) {
  const date = new Date(datetime);
  const isToday = dateTimeHelper.isToday(date);

  if (isToday && isPickupInAnHour) {
    return 'сегодня в течение часа';
  }

  if (isToday && !isPickupInAnHour) {
    return `сегодня к ${formatDateToTimeOnly(date)}`;
  }

  if (dateTimeHelper.isTomorrow(date)) {
    return `завтра к ${formatDateToTimeOnly(date)}`;
  }

  return `${date.getDate()} ${dateTimeHelper.formatDatetimeToMonthNameGenitive(date)} после ${formatDateToTimeOnly(date)}`;
}

export function formatPickupExpireDate(
  pickupDate: Date,
  shelfLife: number
) {
  const pickupExpireDate = new Date();

  pickupExpireDate.setDate(
    pickupDate.getDate() + shelfLife
  );

  const month =
    dateTimeHelper.formatDatetimeToMonthNameGenitive(
      pickupExpireDate
    );

  return `Храним до ${pickupExpireDate.getDate()} ${month}`;
}

export function formatDateToTimeOnly(date: Date) {
  const formatter = new Intl.DateTimeFormat('ru', {
    hour: 'numeric',
    minute: '2-digit'
  });

  const formattedDate = formatter.format(date);

  return formattedDate;
}

export function serializePharmacyTotalPrice(
  unavailableOffers: number[],
  offers: Cart['items'],
  cartSum: number
) {
  const unavailable = offers.filter((item) =>
    unavailableOffers.includes(item.id)
  );
  const totalPrice = unavailable.reduce((prev, offer) => {
    return (prev += offer.amount * offer.price);
  }, 0);

  return cartSum - totalPrice;
}

export function serializeAvailabilityText(pharmacy: PickupPointCurrentResponse['data'][0], offersData: Cart['offersData']):string {
  if (pharmacy.available.length === 0) {
    return `В наличии 0 из ${offersData.totalPositionsCount}`;
  }

  if (pharmacy.unavailable.length > 0) {
    return `В наличии ${offersData.totalPositionsCount - pharmacy.unavailable.length} из ${offersData.totalPositionsCount}`;
  }

  return 'Всё в наличии';
}

export function serializeButtonText(
  isPickupInAnHour: boolean,
  date: number
) {
  const dateFormat = new Date(date);
  const today = dateTimeHelper.isToday(dateFormat);
  const tomorrow = dateTimeHelper.isTomorrow(dateFormat);

  if (today && isPickupInAnHour) {
    return 'Забрать сегодня за час';
  }

  if (today && !isPickupInAnHour) {
    return `Забрать сегодня после ${formatDateToTimeOnly(dateFormat)}`;
  }

  if (tomorrow) {
    return `Забрать завтра после ${formatDateToTimeOnly(dateFormat)}`;
  }

  return `Забрать ${dateFormat.getDate()} ${dateTimeHelper.formatDatetimeToMonthNameGenitive(dateFormat)} после ${formatDateToTimeOnly(dateFormat)}`;
}

export function getPaymentInfo({
  isOnlinePayAvailable,
  cashOnly
}: {
  isOnlinePayAvailable: boolean;
  cashOnly: boolean;
}): {
  paymentText: string,
  paymentIcon: PaymentMethodsIcons
} {
  if (isOnlinePayAvailable) {
    return {
      paymentText: 'Доступна онлайн оплата',
      paymentIcon: 'card'
    };
  }

  if (cashOnly) {
    return {
      paymentText: 'Оплата только наличными',
      paymentIcon: 'cashOnly'
    };
  }

  return {
    paymentText: 'Оплата при получении',
    paymentIcon: 'cash'
  };
}

export function getAvailableFilters(pickupPoint: PickupPoint, hasItemPreorder: boolean): FilterKeys[] {
  const {
    isInstallmentAvailable,
    isOnlinePayAvailable,
    isPickupInAnHour,
    isAroundTheClock,
    pickupDate,
    hasAllInStock
  } = pickupPoint;
  const filters:FilterKeys[] = [];
  const date = new Date(pickupDate);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  if (isPickupInAnHour) filters.push('inAnHour');
  if (isInstallmentAvailable && !hasItemPreorder) filters.push('installment');
  if (isAroundTheClock) filters.push('aroundTheClock');
  if (hasAllInStock) filters.push('allInStock');
  if (dateTimeHelper.isToday(date) && !isPickupInAnHour) filters.push('today');
  if (dateTimeHelper.isTomorrow(date) || date > tomorrow) filters.push('tomorrow');
  if (isOnlinePayAvailable) {
    filters.push('onlinePay');
    filters.push('sberSpasibo')
  };

  return filters;
}

export function arraysHaveSameItems(
  arr1: unknown[],
  arr2: unknown[]
) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const arr2Copy = [...arr2];

  for (let i = 0; i < arr1.length; i++) {
    const index = arr2Copy.indexOf(arr1[i]);
    if (index === -1) {
      return false;
    }
    arr2Copy.splice(index, 1);
  }

  return true;
}

export function formatDistance(distance: number) {
  if (typeof distance === 'number') {
    if (distance === 0) {
      return '';
    }

    const distanceText =
      distance < 1
        ? `${(distance * 1000).toFixed(0)} м`
        : `${distance.toFixed(2)} км`;

    return distanceText;
  }
  return distance;
}
