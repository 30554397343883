import { checkoutDispatchers } from '../../../dispatchers';
import { requestOrdersPaymentUrl } from '../orders';

export function apiRequestOrdersPaymentUrl(
  orderId,
  headers
) {
  try {
    return requestOrdersPaymentUrl({ orderId, headers });
  } catch (error) {
    checkoutDispatchers.errorTechnical.dispatch({
      title: 'Не удалось получить адрес платежной страницы'
    });

    throw error;
  }
}
