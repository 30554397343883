export function validateKPP(value = '') {
  if (typeof value === 'number') {
    value = value.toString();
  }

  if (typeof value !== 'string') {
    return {
      isValid: false,
      errorMessage: 'Введенные данные некорректны'
    };
  }

  value = value.replace(/\s/g, '');

  const valueLength = value.length;

  if (!valueLength) {
    return { isValid: false, errorMessage: 'Укажите КПП' };
  } else if (valueLength !== 9) {
    return {
      isValid: false,
      errorMessage: 'КПП должен состоять из 9 знаков'
    };
  } else {
    const isValidKPP = /^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(
      value
    );

    if (isValidKPP) {
      return { isValid: true, errorMessage: '' };
    } else {
      return {
        isValid: false,
        errorMessage: 'Некорректный КПП'
      };
    }
  }
}
