type Suggestion = {
  street: string;
  building?: string;
  label?: string;
};

export function formatSuggestion(suggestion: Suggestion) {
  const streetArray = suggestion.street.split(' ');
  const street = streetArray
    .filter((word) => word !== 'улица')
    .join(' ');

  return suggestion.building
    ? `${street}, ${suggestion.building}`
    : street || suggestion.label;
}
