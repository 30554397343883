import React from 'react';
import clsx from 'clsx';
import { Typography } from '@npm-registry/eapteka-ui';
import offerDefaultImage from '../../../assets/images/no-photo.png';
import { formatCurrency } from '../../../tools/formatters/formatCurrency/formatCurrency';
import { CheckoutERecipe } from '../CheckoutERecipe';
import './CheckoutOffer.scss';

export const CheckoutOffer = ({
  className,
  title,
  preview = offerDefaultImage,
  count,
  price,
  oldPrice,
  isAvailable,
  isRecipeDeliveryAllowed,
  isERecipe
}) => {
  const rootClassNames = clsx(
    'CheckoutPharmacyOffers_Item',
    className,
    {
      CheckoutPharmacyOffers_Unavailability: !isAvailable
    }
  );
  const countClassNames = clsx(
    'CheckoutPharmacyOffers_Count',
    {
      CheckoutPharmacyOffers_Warning: !isAvailable
    }
  );
  const discount = oldPrice > price;

  return (
    <div className={rootClassNames}>
      <div className="CheckoutPharmacyOffers_Image">
        <img src={preview} alt={title} />
      </div>
      <div className="CheckoutPharmacyOffers_Description">
        <Typography
          variant="p4"
          className="CheckoutPharmacyOffers_Name"
        >
          {title}
        </Typography>
        {isRecipeDeliveryAllowed &&
          isERecipe &&
          isAvailable && <CheckoutERecipe />}
      </div>
      <div className="CheckoutPharmacyOffers_Quantity">
        <Typography
          variant="p2"
          className={countClassNames}
        >
          {isAvailable ? `${count} шт.` : 'Недоступно'}
        </Typography>
        <div className="CheckoutPharmacyOffers_Prices">
          {discount && (
            <Typography
              variant="p2"
              className="CheckoutPharmacyOffers_OldPrice"
            >
              {formatCurrency(oldPrice, true)}
            </Typography>
          )}
          <Typography
            variant="p2"
            className="CheckoutPharmacyOffers_Price"
          >
            {formatCurrency(price, true)}
          </Typography>
        </div>
      </div>
    </div>
  );
};
