import { useState } from 'react';
import { validatePhoneNumber } from '../../../../tools/validation/validatePhoneNumber/validatePhoneNumber';
import { UserFormFields } from '../consts';

export function useFormPhone(phone, callback) {
  const [hasErrorPhone, setHasErrorPhone] = useState(false);
  const [errorPhoneMessage, setErrorPhoneMessage] =
    useState('');

  const onBlurPhone = () => {
    const { hasError, errorMessage } =
      validatePhoneNumber(phone);

    setHasErrorPhone(hasError);
    setErrorPhoneMessage(errorMessage);

    callback(phone);

    return [UserFormFields.phone, phone, hasError];
  };

  const onValidatePhone = () => {
    const { hasError, errorMessage } =
      validatePhoneNumber(phone);

    setHasErrorPhone(hasError);
    setErrorPhoneMessage(errorMessage);
  };

  return {
    onBlurPhone,
    onValidatePhone,
    hasErrorPhone,
    errorPhoneMessage
  };
}
