import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';
import { TContentSerialized } from './types';

const InstallmentContext = createContext<{
  content?: TContentSerialized;
  setInstallmentContext: Dispatch<
    SetStateAction<TContentSerialized>
  >;
}>({
  content: undefined,
  setInstallmentContext: () => {}
});

export function useInstallmentContext() {
  return useContext(InstallmentContext);
}

export function InstallmentContextProvider({
  children
}: {
  children: ReactNode;
}) {
  const [content, setInstallmentContext] =
    useState<TContentSerialized>();
  return (
    <InstallmentContext.Provider
      value={{ content, setInstallmentContext }}
    >
      {children}
    </InstallmentContext.Provider>
  );
}
