import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterKeys, Filters, PickupPoint, PickupPointsStore } from "./types";
import { STORE_NAMES } from "../consts";
import { FetchStatus } from "../types";
import { formatDistance } from "./helpers";
import { getGeoDistance } from "../../../../tools/geo/getDistance";

const initialState:PickupPointsStore = {
  status: 'IDLE',
  data: null,
  filters: {},
  selectedPharmacy: null,
  baseCoordinates: null,
}

export const pharmaciesSlice = createSlice({
  name: STORE_NAMES.PHARMACIES,
  initialState,
  reducers: {
    setPharmacies: (state, { payload }: PayloadAction<Array<PickupPoint>>) => {
      state.data = payload;
    },
    setPharmaciesStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.status = payload;
    },
    setFilters: (state, { payload }: PayloadAction<Filters>) => {
      state.filters = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<FilterKeys>) => {
      type IndependedProgram = 'installment' | 'sberSpasibo';
      type IndependedTimeFilter = 'inAnHour' | 'tomorrow' | 'today';
      const independedPrograms: Array<IndependedProgram> = ['installment', 'sberSpasibo'];
      const independedTimeFilters: Array<IndependedTimeFilter> = ['inAnHour', 'tomorrow', 'today'];
      const availableFilters = [] as FilterKeys[];

      for (const key in state.filters) {
          availableFilters.push(key as FilterKeys);
      }

      if (independedPrograms.includes(payload as IndependedProgram)) {
        const excludedFilter: IndependedProgram = independedPrograms.find(
          (program) => program !== payload
        );
        if (availableFilters.includes(excludedFilter)) {
          state.filters[excludedFilter] = false;
        }
        state.filters[payload] = !state.filters[payload];
        return;
      }
      if (independedTimeFilters.includes(payload as IndependedTimeFilter)) {
        const excludedFilters: IndependedTimeFilter[] = independedTimeFilters.filter(filter => filter !== payload && availableFilters.includes(filter));
        excludedFilters.forEach(filter => {
          state.filters[filter] = false;
        });
        state.filters[payload] = !state.filters[payload];
        return;
      }
      state.filters[payload] = !state.filters[payload];
    },
    clearFilters: (state) => {
      for (const filter in state.filters) {
        state.filters[filter as FilterKeys] = false;
      }
    },
    setSelectedPharmacy: (state, { payload }: PayloadAction<PickupPoint | null>) => {
      state.selectedPharmacy = payload;
    },
    setBaseCoordinates: (state, { payload }: PayloadAction<{ longitude: number, latitude: number }>) => {
      state.baseCoordinates = payload;
      state.data.forEach(pharmacy => {
        const distance = getGeoDistance(payload.latitude, payload.longitude, pharmacy.latitude, pharmacy.longitude);
        pharmacy.distance = formatDistance(distance);
      });
    },
  }
});

const pharmaciesActions = pharmaciesSlice.actions;
const pharmaciesReducer = pharmaciesSlice.reducer;

export const {
  setBaseCoordinates,
  setFilters,
  setPharmacies,
  setPharmaciesStatus,
  setSelectedPharmacy,
  clearFilters,
  updateFilter
} = pharmaciesActions;

export { pharmaciesActions, pharmaciesReducer };
