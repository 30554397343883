import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { cartActions } from './slice';
import { getCartRequest, getCartUpdateRequest, getOffersRequest } from '../../../api/endpoints/cart/getCartRequest';
import { serializeCartAndOffers, serializeCartItemsIds, serializeCartUpdate } from './serializers';

export const getCartWithOffers = createAsyncThunk(
  STORE_NAMES.CART + '_action',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(cartActions.setCartStatus(FETCH_STATUS.PENDING));

    await getCartRequest()
      .then(async (cart) => {
        const offerIds = serializeCartItemsIds(cart);
        const offers = await getOffersRequest({ ids: offerIds });
        const hasPromocode = !!localStorage.getItem('promoCodeApplied');

        if (hasPromocode) {
          const cartPayload = serializeCartUpdate(cart);
          cart = await getCartUpdateRequest(cartPayload);
        }

        const result = serializeCartAndOffers(cart, offers);

        thunkAPI.dispatch(cartActions.setCart(result));
        thunkAPI.dispatch(cartActions.setCartStatus(FETCH_STATUS.SUCCESS));
      })
      .catch((err) => {
        console.error('Ошибка получения данных о корзине: ', err);
        thunkAPI.dispatch(cartActions.setCartStatus(FETCH_STATUS.ERROR));
      });
  }
);
