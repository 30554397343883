import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../rootReducer";
import { FilterKeys, PickupPoint, PickupPointsStore } from "./types";

const selectPharmaciesState = (state: TRootState): PickupPointsStore => state.pharmacies;

export const selectPharmacies = createSelector(selectPharmaciesState, state => state.data);
export const selectPharmaciesStatus = createSelector(selectPharmaciesState, state => state.status);
export const selectPharmaciesFilters = createSelector(selectPharmaciesState, state => state.filters);
export const selectCurrentPharmacy = createSelector(selectPharmaciesState, state => state.selectedPharmacy);
export const selectEnabledFilters = createSelector(selectPharmaciesState, state => {
  const filtersEnabled = [] as FilterKeys[];

  for (const key in state.filters) {
    if (state.filters[key as FilterKeys] === true) {
      filtersEnabled.push(key as FilterKeys);
    }
  }
  return filtersEnabled;
});
export const selectFilteredPharmacies = createSelector([selectPharmaciesState, selectEnabledFilters]
, (state, filtersEnabled) => {
  const pharmacies = state.data;
  const filteredPickupPoints = [] as PickupPoint[];

  for (const key in state.filters) {
    if (state.filters[key as FilterKeys] === true) {
      filtersEnabled.push(key as FilterKeys);
    }
  }

  if (filtersEnabled.length === 0) {
    return pharmacies;
  }

  for (const pharmacy of pharmacies) {
    if (filtersEnabled.every(filter => pharmacy.filtersVisible.includes(filter as never))) {
      filteredPickupPoints.push(pharmacy);
    }
  }

  return filteredPickupPoints;
});

