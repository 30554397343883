import React, { FC, MouseEventHandler } from 'react';
import { Icon } from '@npm-registry/eapteka-ui';
import './ButtonClose.scss';

interface ButtonClose {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color?: string;
}

export const ButtonClose: FC<ButtonClose> = ({
  onClick,
  color = '#9199A3'
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="CheckoutButton_Close"
      aria-label="Закрыть"
    >
      <Icon name="close" style={{ color }} />
    </button>
  );
};
