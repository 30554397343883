import { combineSlices } from '@reduxjs/toolkit';
import { handbookReducer } from './handbook/slice';
import { regionReducer } from './region/slice';
import { userReducer } from './user/slice';
import { cartReducer } from './cart/slice';
import { uiReducer } from './ui/slice';
import { pharmaciesReducer } from './pharmacies/slice';
import { receivingReducer } from './receiving/slice';
import { paymentReducer } from './payment/slice';
import { deliveryReducer } from './delivery/slice';

export const rootReducer = combineSlices({
  pharmacies: pharmaciesReducer,
  receiving: receivingReducer,
  handbook: handbookReducer,
  delivery: deliveryReducer,
  payment: paymentReducer,
  region: regionReducer,
  user: userReducer,
  cart: cartReducer,
  ui: uiReducer,
});

export type TRootState = ReturnType<typeof rootReducer>;
