import config from '../../../../config';
import {
  PaymentDeliveryRequest,
  PaymentPickupRequest,
  PaymentResponse
} from '../../../redux/store/payment/types';
import { API_SERVICE } from '../../api';

const PAYMENT_FEATURE_HEADER =
  'multicart,analogs,sync_addresses,multi_specials,' +
  'unavailable_payments,show_short_pickup,order_status_code,hide_googlepay,payment_deeplink,' +
  'Recipe-Delivery-Restriction,recipe_flow_v2';

export const postPaymentRequest = API_SERVICE.createPostMethod<
  PaymentDeliveryRequest | PaymentPickupRequest,
  PaymentResponse
>(config.apiUrl.payment, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  mapRequest: (requestData, { currentUrl }) => {
    const type = Object.keys(requestData)[0];

    return {
      url: `${currentUrl}/${type}`,
      headers: {
        features: PAYMENT_FEATURE_HEADER,
        'old-paylist': false
      },
      body: JSON.stringify(requestData)
    };
  }
});
