import { nanoid } from '@reduxjs/toolkit';
import { LoyaltyResponse, Payment, PaymentResponse, PaymentStore } from './types';

const IconMap = {
  card: 'card',
  cash: 'cash',
  AGENT: 'cashOnly',
  pickup_payment: 'cash',
  bnpl: 'bnpl',
  sberpay: 'sberpay',
  sbp: 'sbp',
  sberbank: 'sberbank',
  tinkoffbank: 'tinkoffBank',
  vtbbank: 'vtbBank',
  alfabank: 'alfaBank',
}

export const serializePayments = (data: PaymentResponse, isSberpayEnabled: boolean): PaymentStore['payment'] => {
  const cards =
    data.cards.map((card) => {
      return {
        bankCountryCode: card.bank_country_code,
        bankName: card.bank_name,
        bindingId: card.binding_id,
        clientId: card.client_id,
        pan: card.pan,
        paymentSystem: card.payment_system,
        paymentWay: card.payment_way
      };
    }) || [];

  const cardsToPaymentTypes: Payment[] = cards?.map((card) => {
    const isSberbank = card.bankName.split(' ')[0].toLowerCase() === 'sberbank';
    const iconName = isSberbank ? card.bankName.split(' ')[0].replace(/[- ]/g, '').toLocaleLowerCase() : card.bankName.replace(/[- ]/g, '').toLowerCase();
    const isAvailable = data.payment_types.some(p => p.code === 'card' && p.is_available);

    return {
      bindingId: card.bindingId,
      code: 'card',
      iconName: IconMap[iconName as keyof typeof IconMap],
      id: data.payment_types.find(p => p.code === 'card')?.id || +card.bindingId.replace(/\D/g, '').slice(-16),
      isAvailable,
      isSBP: false,
      message: isAvailable ? '' : data.payment_types.find(p => p.code === 'card')?.message || '',
      name: card.paymentSystem + ' ·' + card.pan.slice(-4),
      uuid: nanoid(),
    }
  }) || []


  // добавляем SberPay в список доступных платежей !WARNING - костыль
  const cardPayment = data.payment_types.find((type) => type.code === 'card');

  if (cardPayment && cardPayment.is_available && isSberpayEnabled) {
    const sberpay = JSON.parse(JSON.stringify(cardPayment));

    sberpay.name = 'SberPay';
    sberpay.has_sbol = true;
    sberpay.sberpay_sdk = true;

    data.payment_types.unshift(sberpay);
  }

  const paymentTypes: Payment[] =
    data.payment_types.map((payment) => {
      const isSberpay = payment.name === 'SberPay';
      const icon = isSberpay ? 'sberbank' : payment.code;

      return {
        bindingId: '',
        code: payment.code,
        id: payment.id,
        isAvailable: payment.is_available,
        iconName: IconMap[icon as keyof typeof IconMap] || 'card',
        isSBP: payment.sbp || false,
        name: payment.name,
        message: !payment.is_available ? (payment.message || 'Онлайн оплата недоступна') : '',
        uuid: nanoid(),
      };
    }) || [];

  return {
    cards,
    paymentTypes: [ ...paymentTypes, ...cardsToPaymentTypes ],
    errors: data.errors
  };
};

export const serializeLoyalty = (data: LoyaltyResponse) => {
  return {
    balance: data.sber_balance,
    message: data.message || '',
    state: data.sber_state,
  }
}
