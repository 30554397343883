import React from 'react';
import { usePickupPointsList } from './hooks/usePickupPointsList';
import { CheckoutPharmacy } from '../../CheckoutPharmacy/CheckoutPharmacy';
import './CheckoutPickupList.scss';

export const CheckoutPickupList = () => {
  const { pickupPointsVisible, setPaginationElement } =
    usePickupPointsList();

  return (
    <div className="CheckoutPickupList_root">
      {pickupPointsVisible.map((pharmacy) => (
        <CheckoutPharmacy
          key={pharmacy.id}
          pickupPoint={pharmacy}
        />
      ))}
      <div
        key="CheckoutPaginationTriggerElementKey"
        className="CheckoutPagination_Trigger"
        ref={setPaginationElement}
      >
        Конец списка
      </div>
    </div>
  );
};
