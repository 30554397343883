import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldText } from '@npm-registry/eapteka-ui';
import { CheckoutSection } from '../../../elements/CheckoutSection';
import { CheckoutPaymentProgram } from './Program';
import { selectReceivingMethod } from '../../../../services/redux/store/receiving/selectors';
import {
  selectCartBonusProgram,
  selectCartSummary
} from '../../../../services/redux/store/cart/selectors';
import { selectUserIsCorporate } from '../../../../services/redux/store/user/selectors';
import {
  selectCurrentPayment,
  selectNeedChange,
  selectPaymentLoyaltyBonuses,
  selectPaymentStatus,
  selectSberSpasibo
} from '../../../../services/redux/store/payment/selectors';
import { selectWebFeatures } from '../../../../services/redux/store/handbook/selectors';
import {
  setBonusToggled,
  setPaymentNeedChange,
  setSberspasiboToggled
} from '../../../../services/redux/store/payment/slice';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import './CheckoutPaymentPrograms.scss';

export const CheckoutPaymentPrograms = () => {
  const dispatch = useDispatch();
  const isCorporate = useSelector(selectUserIsCorporate);
  const { isSberBonusesEnabled, isSberpaySpasiboEnabled } = useSelector(selectWebFeatures);
  const { totalPrice } = useSelector(selectCartSummary);
  const selectedPayment = useSelector(selectCurrentPayment);
  const needChange = useSelector(selectNeedChange);
  const isPending = useSelector(selectPaymentStatus) === 'PENDING';
  const { isAvailable, isEnabled, text, message, tooltip, title, status } =
    useSelector(selectSberSpasibo);
  const { canWithdraw, reasonNotWithdraw } = useSelector(selectCartBonusProgram);
  const { isDeliverySelected } = useSelector(selectReceivingMethod);
  const isCashSelected = ['cash', 'pickup_payment'].includes(selectedPayment?.code);
  const {
    isAvailable: isBonusAvailable,
    isEnabled: isBonusEnabled,
    isVisible: isBonusVisible,
    level: bonusLevel,
    title: bonusTitle,
    description
  } = useSelector(selectPaymentLoyaltyBonuses);

  const onChangeField = (e) => {
    if (!isNaN(+e.target.value) && +e.target.value <= 100000) {
      dispatch(setPaymentNeedChange(+e.target.value));
    }
  };

  const isChangeFieldVisible = isDeliverySelected && isCashSelected;
  const changeFieldValue = needChange > 0 ? String(needChange) : '';

  const isSberSpasiboVisible =
    isSberBonusesEnabled &&
    !isCorporate &&
    totalPrice > 0 &&
    !!selectedPayment &&
    status === 'SUCCESS' &&
    selectedPayment.name === 'SberPay'
      ? isSberpaySpasiboEnabled
      : true;

  const onSberSbasiboToggle = useCallback(() => {
    dispatch(setSberspasiboToggled());
  }, [dispatch]);

  const onBonusToggle = useCallback(() => {
    dispatch(setBonusToggled());
    checkoutDispatchers.loyaltyPointsUsed.dispatch();
  }, [dispatch])

  if (isPending || !selectedPayment) return null;

  return (
    <CheckoutSection className="CheckoutPayment_Programs">
      {isChangeFieldVisible && (
        <FieldText
          size="m"
          name="change"
          className="CheckoutPayment_MoneyChange"
          label="С какой суммы понадобится сдача?"
          value={changeFieldValue}
          onChange={onChangeField}
        />
      )}
      {isBonusVisible && (
        <CheckoutPaymentProgram
          isUnavailable={!isBonusAvailable}
          iconName="eaptekaIcon"
          gradientName={!isBonusAvailable ? undefined : 'callicarpa'}
          isSwitcherVisible={isBonusAvailable}
          isSwitchChecked={isBonusEnabled}
          onToggleSwitch={onBonusToggle}
          title={isBonusAvailable ? `Списать ${bonusTitle}` : bonusTitle}
          subtitle={
            <>
              {bonusLevel && <b>{bonusLevel} </b>}
              {canWithdraw ? description : reasonNotWithdraw}
            </>
          }
        />
      )}
      {isSberSpasiboVisible && (
        <CheckoutPaymentProgram
          isUnavailable={!isAvailable}
          iconName="sberSpasibo"
          isSwitcherVisible={!tooltip}
          isSwitchChecked={isEnabled}
          switchClass='SberSpasibo'
          onToggleSwitch={onSberSbasiboToggle}
          title={title}
          subtitle={message || text}
          isTooltipVisible={!!tooltip && !isAvailable}
          tooltipMessage={tooltip}
        />
      )}
    </CheckoutSection>
  );
};
