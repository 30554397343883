import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CheckoutCollapse } from '../../../elements/CheckoutCollapse';
import './CheckoutPharmacyProductsAvailability.scss';

export const CheckoutPharmacyProductsAvailability = ({
  isPickupAvailablePartly,
  commonUnavailableReason,
  availabilityText,
  children
}) => {
  const [isListVisible, setListVisible] = useState(false);

  const buttonClassNames = clsx(
    'CheckoutPharmacyProductsAvailability_Button',
    {
      CheckoutPharmacyProductsAvailability_Button_isAvailablePartly:
        isPickupAvailablePartly,
      CheckoutPharmacyProductsAvailability_Button_isUnavailable: !!commonUnavailableReason,
    }
  );

  return (
    <>
      <button
        className={buttonClassNames}
        onClick={() => setListVisible(!isListVisible)}
      >
        {availabilityText}
      </button>
      {commonUnavailableReason && (
        <p className="CheckoutPharmacyProductsAvailability_minPrice">
          {commonUnavailableReason}
        </p>
      )}
      <CheckoutCollapse
        className="CheckoutPharmacyProductsAvailability_Collapse"
        isOpen={isListVisible}
      >
        {children}
      </CheckoutCollapse>
    </>
  );
};

CheckoutPharmacyProductsAvailability.propTypes = {
  /**
   * Дополнительный класс для root элемента компонента
   * @ignore
   */
  className: PropTypes.string,

  /**
   * Список недоступных товаров товаров
   */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ])
};
