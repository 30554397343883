import React, { useCallback, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIsCorporate } from '../../../../../services/redux/store/user/selectors';
import {
  setSelectedPharmacy,
  updateFilter
} from '../../../../../services/redux/store/pharmacies/slice';
import { Icon, RadioButton, Typography } from '@npm-registry/eapteka-ui';
import { checkoutDispatchers } from '../../../../../services/dispatchers';
import { CheckoutTooltip } from '../../../../elements/CheckoutTooltip';

import './CheckoutPaymentMethodSelectItem.scss';

export const CheckoutPaymentMethodSelectItem = ({ checked, payment, onSelect }) => {
  const dispatch = useDispatch();
  const { iconName, name, isAvailable, message, isSBP, code } = payment;
  const rootClassName = clsx('CheckoutPaymentMethodItem_Root', {
    MethodUnavailable: !isAvailable
  });
  const iconCode = name === 'SberPay' ? 'sberbank' : iconName;
  const iconClass = clsx(
    ['CheckoutPaymentMethodItem_Icon', 'CheckoutPaymentMethodItem_Icon--' + iconCode],
    {
      CheckoutPaymentMethodItem_WithoutBackground: ['sbp', 'bnpl', 'ukassa'].includes(code)
    }
  );
  const isCorporate = useSelector(selectUserIsCorporate);

  const onClickShowInstallmentPickups = useCallback(() => {
    if (code === 'bnpl' && !isAvailable) {
      dispatch(setSelectedPharmacy(null));
    }
    checkoutDispatchers.bnplPharmaciesButtonClicked.dispatch();
    checkoutDispatchers.pickupEditOpen.dispatch(false);
    dispatch(updateFilter('installment'));
  }, [dispatch, isAvailable, code]);

  const handleSelectPayment = useCallback(
    (e) => {
      if (!isAvailable) return;
      if (e.type === 'click' || e.code === 'Enter') {
        onSelect(payment);
      }
    },
    [isAvailable, payment, onSelect]
  );

  useLayoutEffect(() => {
    const showInstallmentStoresLink = document.querySelector('[data-action=showInstallmentStores]');

    if (showInstallmentStoresLink && code === 'bnpl') {
      checkoutDispatchers.bnplPharmaciesButtonShown.dispatch();
      showInstallmentStoresLink.addEventListener('click', onClickShowInstallmentPickups);
    }

    return () => {
      if (showInstallmentStoresLink && code === 'bnpl') {
        document.removeEventListener('click', onClickShowInstallmentPickups);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAvailable && isCorporate && code === 'card') return null;

  return (
    <div
      className={rootClassName}
      onClick={handleSelectPayment}
      onKeyDown={handleSelectPayment}
      tabIndex={0}
      role="button"
      aria-label={name}
    >
      <div className={iconClass}>
        <Icon name={iconName} size="m" />
        {isSBP && code === 'cash' && (
          <span className="Icon_SBP">
            <Icon name="sbp" size="s" />
          </span>
        )}
      </div>
      <div className="CheckoutPaymentMethodItem_Text">
        <Typography variant="p1" className="CheckoutPaymentMethodItem_Name">
          {name}
        </Typography>
        {message && (
          <Typography
            variant="p2"
            className="CheckoutPaymentMethodItem_Description"
            dangerouslySetInnerHTML={{
              __html: code === 'bnpl' || isAvailable ? message : ''
            }}
          />
        )}
      </div>
      {isAvailable ? (
        <RadioButton
          name="CheckoutPaymentMethodSelectItem"
          checked={checked}
          readOnly
          tabIndex={0}
        />
      ) : (
        message &&
        code !== 'bnpl' && <CheckoutTooltip message={message} iconName="questionCircleFilled" />
      )}
    </div>
  );
};
