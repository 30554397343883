type Suggestion = {
  locality: string;
  city?: string;
  country: string;
};

export function formatLocality(suggestion: Suggestion) {
  if (suggestion.locality === suggestion.city) {
    return `${suggestion.city}, ${suggestion.country}`;
  }
  if (!suggestion.city) {
    return `${suggestion.locality}, ${suggestion.country}`;
  }
  return `${suggestion.locality}, ${suggestion.city}, ${suggestion.country}`;
}
