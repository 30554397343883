export function validatePhoneNumber(value = '') {
  if (typeof value === 'number') {
    value = value.toString();
  }

  value = value.replace(/\s/g, '');

  const digits = value.match(/\d/g) || [];
  const pattern =
    /^(\+7|7|8)?\s?\(?\d{3}\)?[\s|-]?\d{3}([\s|-]?\d{2}){2}$/;
  const isEmpty = !value.length || value.length === 0;
  const isNotFill = digits.length !== 11;
  const isInvalid =
    !pattern.test(value) || typeof value !== 'string';
  const errors = new Map([
    [0, 'Укажите телефон'],
    [1, 'Укажите корректный телефон']
  ]);

  const errorMessage = isEmpty
    ? errors.get(0)
    : isNotFill || isInvalid
      ? errors.get(1)
      : '';

  const hasError = isEmpty || isNotFill || isInvalid;

  return {
    hasError,
    errorMessage: hasError ? errorMessage : ''
  };
}
