import { debouncePromise } from '@npm-registry/helpers/debouncePromise';
import { checkoutDispatchers } from '../../../dispatchers';
import { requestUser } from '../user';

export const apiRequestUserGet = debouncePromise(
  async () => {
    try {
      return await requestUser();
    } catch (error) {
      checkoutDispatchers.errorTechnical.dispatch();

      throw error;
    }
  },
  500
);
