export function formatKPPToString(value = '') {
  if (typeof value === 'number') {
    value = value.toString();
  }

  if (typeof value !== 'string' || !value.length) {
    return null;
  }

  value = value.replace(/\s/g, '');

  if (/[^0-9]/.test(value)) {
    value = value.replace(/[^\d]/g, '');
  }

  const pattern = /(\d{0,3})(\d{0,3})(\d{0,3})(\d*)/;

  return value.replace(pattern, (match, p1, p2, p3) => {
    let result = '';
    result += p1 ? `${p1}` : '';
    result += p2 ? ` ${p2}` : '';
    result += p3 ? ` ${p3}` : '';

    return result;
  });
}
