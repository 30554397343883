import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { userActions } from './slice';
import { getUserRequest } from '../../../api/endpoints/user/getUserRequest';
import { serializeUser } from './serializers';

export const getUser = createAsyncThunk(
  STORE_NAMES.USER + '_action',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(userActions.setUserStatus(FETCH_STATUS.PENDING));

    await getUserRequest()
      .then((response) => {
        const user = serializeUser(response);

        thunkAPI.dispatch(userActions.setUser(user));
        thunkAPI.dispatch(userActions.setUserStatus(FETCH_STATUS.SUCCESS));
      })
      .catch((err) => {
        console.error('Ошибка получения данных о пользователе: ', err);
        thunkAPI.dispatch(userActions.setUserStatus(FETCH_STATUS.ERROR));
      });
  }
);
