import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { useHiddenOverflow } from '../../hooks/useHiddenOverflow';
import './CheckoutScreenModal.scss';

export const ScreenModal = ({
  rootClassName,
  className,
  children,
  onClose
}) => {
  const rootClassNames = clsx(
    'CheckoutScreenModal_Root',
    rootClassName
  );
  const bodyClassNames = clsx(
    'CheckoutScreenModal_Body',
    className
  );
  const refBackground = useRef(null);

  useHiddenOverflow();

  const handleClickOutside = (e) => {
    if (e.target === refBackground.current) {
      onClose();
    }
  };

  return createPortal(
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={refBackground}
      onClick={handleClickOutside}
      className={rootClassNames}
    >
      <div className={bodyClassNames}>{children}</div>
    </div>,
    document.getElementById('checkout-portal')
  );
};
