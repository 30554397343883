/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Switcher } from '@npm-registry/eapteka-ui';
import { CheckoutTooltip } from '../../../elements/CheckoutTooltip';
import './CheckoutContactlessDelivery.scss';

const MESSAGE = `Курьер оставляет товары у двери, звонит, вы принимаете заказ находясь по адресу доставки.\n\nНе является функцией «Оставить у двери»`;

export const CheckoutContactlessDelivery = ({
  isEnabled,
  isDisabled,
  onChange,
  text
}) => {
  return (
    <div className="CheckoutContactlessDelivery_Root">
      <CheckoutTooltip message={MESSAGE} side="right" />
      <label className="CheckoutContactlessDelivery_Root">
        <span className="CheckoutContactlessDelivery_Title">
          Бесконтактная доставка
          <br />
          <small className="CheckoutContactlessDelivery_Description">
            {text}
          </small>
        </span>
        <Switcher
          checked={isEnabled}
          disabled={isDisabled}
          onChange={onChange}
        />
      </label>
    </div>
  );
};

CheckoutContactlessDelivery.propTypes = {
  /**
   * Бесконтактная доставка включена
   */
  isEnabled: PropTypes.bool,

  /**
   * Бесконтактная доставка заблокирована
   */
  isDisabled: PropTypes.bool,

  /**
   * Функция по смене переключателя бесконтактная доставки
   */
  onChange: PropTypes.func
};
