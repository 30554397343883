export const RECEIVING_METHODS_VIEW_TYPE_KEYS = {
  pickup: 'pickup',
  delivery: 'delivery'
};

export const RECEIVING_METHODS_IDS = {
  [RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery]: 1,
  [RECEIVING_METHODS_VIEW_TYPE_KEYS.pickup]: 2
};

export const RECEIVING_METHODS_KEYS_BY_IDS = {
  1: RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery,
  2: RECEIVING_METHODS_VIEW_TYPE_KEYS.pickup
};