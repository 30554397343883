import React, { useState, useMemo } from 'react';
import { Skeleton } from '@npm-registry/eapteka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, selectUserStatus } from '../../../services/redux/store/user/selectors';
import { formatPhoneNumberToString } from '../../../tools/formatters/formatPhoneNumberToString/formatPhoneNumberToString';
import { setUserEmail, setUserName, setUserPhone } from '../../../services/redux/store/user/slice';
import { CheckoutSection } from '../../elements/CheckoutSection';
import { CheckoutSectionHeader } from '../../elements/CheckoutSectionHeader';
import { CheckoutRecipient } from '../CheckoutRecipient';
import { CheckoutUserEdit } from './Edit';
import { CheckoutUserForm } from './Form';

import './CheckoutUser.scss';

function UserFieldsSkeleton() {
  return (
    <>
      <div className="CheckoutUserForm_Root">
        <Skeleton height="48px" />
        <Skeleton height="48px" />
        <Skeleton height="48px" />
      </div>
      <Skeleton height="44px" />
    </>
  );
}

export const CheckoutUser = () => {
  const dispatch = useDispatch();
  const [showUserEdit, setShowUserEdit] = useState(false);
  const isLoading = useSelector(selectUserStatus) === 'PENDING';
  const user = useSelector(selectUser);
  const { phone, name, email, recipient, isCorporate } = user;
  const { isCollectedByOtherPerson } = recipient;

  const showUserFieldForm = !user.name || !user.phone || !user.email;
  const isPhoneDisabled = user.phone.length > 0;
  const isEmailDisabled = user.email.length > 0;
  const phoneValue = useMemo(
    () => formatPhoneNumberToString(phone),
    [phone]
  );

  const userInfo = useMemo(() => {
    if (showUserEdit) return;
    if (!email && !name && phoneValue)
      return <span>{phoneValue}</span>;
    if (!phoneValue && !email && name)
      return <span>{name}</span>;
    return [name, phoneValue, email]
      .filter(Boolean)
      .map((field, index) => (
        <span key={field}>
          {field}
          {index !== 2 ? ', ' : ''}
        </span>
      ));
  }, [showUserEdit, name, phoneValue, email]);

  const onChangeEmail = (email) => {
    dispatch(setUserEmail(email));
  };

  const onChangeFullName = (name) => {
    dispatch(setUserName(name));
  };

  const onChangePhone = (phone) => {
    dispatch(setUserPhone(phone));
  };

  const onSave = ({ phone, email, name }) => {
    dispatch(setUserEmail(email));
    dispatch(setUserName(name));
    dispatch(setUserPhone(phone));
    setShowUserEdit(false);
  };

  return (
    <>
      <CheckoutSection className="User">
        <CheckoutSectionHeader
          image="profile"
          subtitle={userInfo}
        >
          Личные данные
        </CheckoutSectionHeader>
        {isLoading ? (
          <UserFieldsSkeleton />
        ) : (
          <>
            {showUserFieldForm && (
              <CheckoutUserForm
                phone={phoneValue}
                isPhoneDisabled={isPhoneDisabled}
                isEmailDisabled={isEmailDisabled}
                email={email}
                name={name}
                onChangeEmail={onChangeEmail}
                onChangeFullName={onChangeFullName}
                onChangePhone={onChangePhone}
                isVisibleForm={isCollectedByOtherPerson}
              />
            )}
            {!isCorporate && <CheckoutRecipient />}
          </>
        )}
      </CheckoutSection>
      {showUserEdit && (
        <CheckoutUserEdit
          onClose={() => setShowUserEdit(false)}
        >
          <CheckoutUserForm
            phone={phoneValue}
            isPhoneDisabled={true}
            isEmailDisabled={isEmailDisabled}
            email={email}
            name={name}
            onChangeEmail={onChangeEmail}
            onChangeFullName={onChangeFullName}
            isFullScreen={true}
            onSave={onSave}
          />
        </CheckoutUserEdit>
      )}
    </>
  );
};
