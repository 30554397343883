import { useSelector } from 'react-redux';
import { CURRENT_REGION_ID } from '../../../../tools/const';
import { selectNeedChange } from '../../../../services/redux/store/payment/selectors';
import { selectCurrentAddress, selectCurrentInterval } from '../../../../services/redux/store/delivery/selectors';

const getDeliveryAddressSummary = (address) => ({
  id: address.id,
  address: address.fullAddress,
  apartment: address.apartment,
  floor: address.floor,
  entrance: address.entrance,
  entrance_code: address.entranceCode,
  comment: address.comment,
  address_city: address.city,
  address_street: address.street,
  address_house: address.house,
  latitude: address.latitude,
  longitude: address.longitude,
  last_delivery_date: address.lastDeliveryDate,
  region_id: CURRENT_REGION_ID,
  address_country: '',
  zone_id: address.zone,
  fast_delivery: false
});

export function useDeliverySummary() {
  const needChange = useSelector(selectNeedChange);
  const address = useSelector(selectCurrentAddress);

  const {
    from,
    to,
    price,
    freeFrom,
  } = useSelector(selectCurrentInterval) || {};

  const interval = {
    from: from || '',
    to: to || ''
  };

  if (!address) {
    return {};
  }

  return {
    address: getDeliveryAddressSummary(address),
    interval: {
      from: interval.from,
      to: interval.to,
      price: price,
      free_from: freeFrom,
      express: false, // Не используется
      hours_interval: 0 // Не используется
    },
    need_change: needChange,
    is_contactless: false
  };
}
