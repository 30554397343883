import config from '../../../../config';
import { apiService } from '../../../../services/api/apiService';
import {
  TContent,
  TContentSerialized,
  TPayments
} from '../types';
import { serializeInstallmentResponse } from './serializers';

const INSTALLMENT_CACHE = new Map();

export async function getInstallmentData(
  cartSum?: number
): Promise<TContentSerialized> {
  const getBnplContent = apiService.createGetMethod<
    TContent,
    TContent
  >(config.apiUrl.getInstallmentContent);
  const getBnplPayments = apiService.createGetMethod<
    TPayments,
    TPayments
  >(config.apiUrl.getInstallmentPayments + cartSum);
  const cartSumIsValid = Number(cartSum) > 0;

  const content: TContent =
    INSTALLMENT_CACHE.get('content') ||
    (await getBnplContent().then((data) => {
      INSTALLMENT_CACHE.set('content', data);
      return data;
    }));

  const payments: TPayments | object =
    INSTALLMENT_CACHE.get(cartSum) ||
    (cartSumIsValid &&
      (await getBnplPayments().then((data) => {
        INSTALLMENT_CACHE.set(cartSum, data);
        return data;
      }))) ||
    {};

  const response = Object.assign(content, payments);

  return serializeInstallmentResponse(response);
}
