/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { Map } from '@2gis/mapgl/global';
import { memo, useEffect } from 'react';
import { useMapGLContext } from './MapGLContext';
import { MapWrapper } from './MapWrapper';
import { API_KEY_2GIS } from './const';
import { Store } from '../../../services/redux/store/store';

export const MapGL = memo(function () {
  const { setMapglContext } = useMapGLContext();

  useEffect(() => {
    let mapState: Map | undefined = undefined;
    const { latitude, longitude } = Store.getState().region.region;

    System.import('https://mapgl.2gis.com/api/js').then(
      (mapgl) => {
        window.mapgl = mapgl;
        const map = new mapgl.Map('map-container', {
          center: [longitude, latitude],
          zoom: 10,
          key: API_KEY_2GIS,
          lang: 'ru',
          zoomControl: false
        });
        mapState = map;
        setMapglContext({ mapgl, mapglInstance: map });
      }
    );

    return () => {
      if (mapState) mapState.destroy();

      setMapglContext({
        mapglInstance: undefined,
        mapgl: undefined
      });
    };
  }, [setMapglContext]);

  return <MapWrapper />;
});
