import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { pharmaciesActions } from './slice';
import {
  getCurrentPickupPointsRequest,
  getPickupPointsRequest
} from '../../../api/endpoints/pharmacies/getPickupPointsRequest';
import { serializePharmacies } from './serializers';

export const getPharmacies = createAsyncThunk(
  STORE_NAMES.PHARMACIES + '_action',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(
      pharmaciesActions.setPharmaciesStatus(FETCH_STATUS.PENDING)
    );

    await Promise.all([
      getCurrentPickupPointsRequest(),
      getPickupPointsRequest()
    ])
      .then(([currentPickupPoints, pickupPoints]) => {
        const { filters, pharmacies } = serializePharmacies(
          currentPickupPoints,
          pickupPoints
        );

        thunkAPI.dispatch(
          pharmaciesActions.setPharmacies(pharmacies)
        );
        thunkAPI.dispatch(
          pharmaciesActions.setFilters(filters)
        );
        thunkAPI.dispatch(
          pharmaciesActions.setPharmaciesStatus(FETCH_STATUS.SUCCESS)
        );
      })
      .catch((err) => {
        console.error(
          'Ошибка получения пунктов самовывоза: ',
          err
        );
        thunkAPI.dispatch(
          pharmaciesActions.setPharmaciesStatus(FETCH_STATUS.ERROR)
        );
      });
  }
);
