import React from 'react';
import {
  CheckoutTooltip
} from '../../../elements/CheckoutTooltip';
import './CheckoutFreeDeliveryPrice.scss';

export const CheckoutFreeDeliveryPrice = ({
  freeDeliveryFrom
}) => (
  <div className="CheckoutFreeDeliveryPrice">
    <CheckoutTooltip
      message="Итоговая стоимость зависит от выбранного времени доставки"
      side='right'
    />
    {freeDeliveryFrom} ₽ до бесплатной доставки
  </div>
);
