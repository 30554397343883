import { useSelector } from 'react-redux';
import { selectCartSummary } from '../../services/redux/store/cart/selectors';
import { selectCurrentInterval } from '../../services/redux/store/delivery/selectors';

export const useFreeDeliveryFrom = () => {
  const { totalPrice } = useSelector(selectCartSummary);
  const currentDeliveryInterval = useSelector(selectCurrentInterval);

  if (!currentDeliveryInterval) return 0;

  if (currentDeliveryInterval.freeFrom >= 999999999 ||
    currentDeliveryInterval.freeFrom === 0) {
    return 0;
  }

  const freeDeliveryFrom = Math.max(currentDeliveryInterval.freeFrom - totalPrice);

  return freeDeliveryFrom;
};
