import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../rootReducer";
import { DeliveryStore } from "./types";

const selectDeliveryState = (state: TRootState): DeliveryStore => state.delivery;

export const selectDeliveryStatus = createSelector(selectDeliveryState, state => state.status);
export const selectDeliveryAddresses = createSelector(selectDeliveryState, state => state.addresses);
export const selectCurrentAddress = createSelector(selectDeliveryState, state => state.currentAddress);
export const selectCurrentInterval = createSelector(selectDeliveryState, state => state.currentInterval);
export const selectCurrentDate= createSelector(selectDeliveryState, state => state.currentDate);
export const selectIntervals = createSelector(selectDeliveryState, state => state.intervals);
export const selectIntervalStatus = createSelector(selectDeliveryState, state => state.intervalStatus);
export const selectLogisticsSchemeId = createSelector(selectDeliveryState, state => state.logisticSchemeId);
export const selectDeliveryDates = createSelector(selectDeliveryState, state => state.dates);
