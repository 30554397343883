import React from 'react';
import clsx from 'clsx';
import { Button, Icon } from '@npm-registry/eapteka-ui';
import { resetIconSize } from '../../Map/Placemarks/helpers';
import { MapGLDispatcher } from '../../../../core/2GIS/helpers/dispatcher';
import './CheckoutPickupMapSearchBar.scss';

export const CheckoutPickupMapSearchBar = ({
  onChange = Function.prototype,
  onKeyDown = Function.prototype,
  inputRef = null,
  value = '',
  onClear = Function.prototype,
  isInvalid = false,
  hasLabel = false,
  message = ''
}) => {
  const onInputFocus = () => {
    resetIconSize();
  };
  const handleClear = () => {
    MapGLDispatcher.userCoordinates.dispatch([], true);
    MapGLDispatcher.userAddress.dispatch('');
    MapGLDispatcher.userData.dispatch(null);
    onClear();
  };

  const inputClass = clsx(
    'CheckoutPickupMapSearchBar_input',
    {
      Invalid: isInvalid
    }
  );

  return (
    <div
      className="CheckoutPickupMapSearchBar_Root"
      data-message={message}
    >
      <input
        ref={inputRef}
        className={inputClass}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Поиск по адресу"
        onFocus={onInputFocus}
        id="checkout-address-search"
        value={value}
        type="text"
        required
        autoComplete="off"
      />
      {hasLabel && (
        <label
          className="CheckoutPickupMapSearchBar_label"
          htmlFor="checkout-address-search"
        >
          {message}
        </label>
      )}
      {value.length > 0 && (
        <Button
          theme="transparent"
          className="CheckoutPickupMapSearchBar_clearInput"
          aria-label="Очистить поисковую строку"
          onClick={handleClear}
        >
          <Icon name="close" size="m" />
        </Button>
      )}
    </div>
  );
};
