import React, { useCallback } from 'react';
import { Button, Icon } from '@npm-registry/eapteka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useReceivingMethodDelivery } from './hooks/useReceivingMethodDelivery';
import { CheckoutDeliveryAddressEdit } from '../../CheckoutDeliveryAddressEdit';
import { CheckoutDeliveryAddresses } from '../DeliveryAddresses';
import { CheckoutReceivingMethodLayout } from '../Layout';
import { CheckoutContactlessDelivery } from '../Contactless';
import { DeliveryPlaceholder } from '../DeliveryPlaceholder';
import { DeliveryERUnavailablePlaceholder } from '../DeliveryERUnavailablePlaceholder';
import { CheckoutReceivingMethodsDeliveryFields } from './CheckoutReceivingMethodsDeliveryFields';
import { selectUIDelivery } from '../../../../services/redux/store/ui/selectors';
import { selectCurrentAddress } from '../../../../services/redux/store/delivery/selectors';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import { setIsDeliveryAddressModalOpen } from '../../../../services/redux/store/ui/slice';
import { selectCartData } from '../../../../services/redux/store/cart/selectors';
import './CheckoutReceivingMethodDelivery.scss';

export const CheckoutReceivingMethodDelivery = () => {
  const dispatch = useDispatch();
  const { isDeliveryScreenOpen } = useSelector(selectUIDelivery);
  const currentDeliveryAddress = useSelector(selectCurrentAddress);
  const { hasERecipes } = useSelector(selectCartData);
  const isErecipeDeliveryAllowed = false;
  const isContactlessVisible = false; // !isCorporate && !hasERecipes && isDeliverySelected; ECOMM-1750 Отключить/скрыть бесконтактную доставку
  const isCurrentAddressVisible =
    currentDeliveryAddress && (isErecipeDeliveryAllowed || !hasERecipes);

  const { contactLessAvailableText, isAddressUnavailable } = useReceivingMethodDelivery();

  const onDeliveryAddressEditCurrent = useCallback(() => {
    checkoutDispatchers.notificationsClear.dispatch();
    dispatch(setIsDeliveryAddressModalOpen(true));
  }, [dispatch]);

  return (
    <>
      <CheckoutReceivingMethodLayout>
        {!currentDeliveryAddress && <DeliveryPlaceholder />}
        {currentDeliveryAddress && !isErecipeDeliveryAllowed && hasERecipes && (
          <DeliveryERUnavailablePlaceholder />
        )}
        {isCurrentAddressVisible && (
          <>
            <div className="CheckoutReceivingMethodsDelivery_Address">
              <Icon className="CheckoutReceivingMethodsDelivery_AddressIcon" name="map" size="m" />
              <p className="CheckoutAddress_Title">{currentDeliveryAddress.fullAddress}</p>
              <Button theme="transparent" size="s" onClick={onDeliveryAddressEditCurrent}>
                {isAddressUnavailable ? 'Выбрать другой адрес' : 'Изменить'}
              </Button>
            </div>
            <CheckoutReceivingMethodsDeliveryFields
              currentDeliveryAddress={currentDeliveryAddress}
            />
            {isContactlessVisible && (
              <CheckoutContactlessDelivery
                isEnabled={false}
                isDisabled={true}
                onChange={() => {}}
                text={contactLessAvailableText}
              />
            )}
          </>
        )}
      </CheckoutReceivingMethodLayout>
      <CheckoutDeliveryAddresses />
      {isDeliveryScreenOpen && <CheckoutDeliveryAddressEdit />}
    </>
  );
};
