import { TContent, TContentSerialized } from '../types';

export function serializeInstallmentResponse(
  data: TContent
): TContentSerialized {
  const tabs = data.payment_periods || data.tabs;
  const availableTabs = data.tabs;
  const serializedTabs = tabs.map((tab) => {
    const descriptionCart = availableTabs.find(
      (thisTab) => thisTab.id === tab.id
    )?.description_cart;
    const bubblesCount =
      tab.payments?.length || tab.bubbles_count;
    const titleText = tab.title_text || tab.title;

    return {
      id: tab.id,
      bubblesCount,
      descriptionCart,
      showTabForSum: tab.show_tab_for_sum,
      title: titleText,
      titleSum: tab.title_sum,
      name: tab.name,
      payments: tab.payments
    };
  });
  return {
    bannerTextFail: data.banner_text_fail,
    bannerTextSuccess: data.banner_text_success,
    bannerTitleFail: data.banner_title_success,
    bannerTitleSuccess: data.banner_title_success,
    textСheckout: data.text_checkout,
    content: data.content,
    description: data.description,
    maxBnplSum: data.max_bnpl_sum,
    minBnplSum: data.min_bnpl_sum,
    reference: data.reference,
    referenceLinks: data.reference_links,
    requirements: data.requirements,
    tabs: serializedTabs,
    thresholdSum: data.threshold_sum
  };
}
