/* eslint-disable no-unused-vars */
import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../rootReducer";
import { HandbookStore } from "./types";

export const getHandbookState = (state: TRootState): HandbookStore => state.handbook;
export const getHandbookFeaturesState = (state: TRootState): HandbookStore['features'] => state.handbook.features;
export const getHandbookStateStatus = (state: TRootState): HandbookStore => state.handbook;

export const selectFeatures = createSelector(getHandbookState, (handbookStore) => handbookStore.features);
export const selectWebFeatures = createSelector(getHandbookFeaturesState, (state) => state.web);
export const selectSberFeatures = createSelector(getHandbookFeaturesState, (state) => state.sber);
export const selectFeaturesStatus = createSelector(getHandbookStateStatus, (state) => state.status);
