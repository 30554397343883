import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DrawerOverlay,
  Button,
  Icon
} from '@npm-registry/eapteka-ui';
import { useHiddenOverflow } from '../../../hooks/useHiddenOverflow';
import { ScreenModal } from '../../../core/ScreenModal';
import { CheckoutModalHeader } from '../../../elements/CheckoutModalHeader';
import { selectUIDelivery } from '../../../../services/redux/store/ui/selectors';
import { setIsDeliveryAddressModalOpen, setIsDeliveryScreenOpen } from '../../../../services/redux/store/ui/slice';
import { CheckoutDeliveryAddressItem } from './Item';
import { useMobileWithoutTablet } from '../../../hooks/useMobileWithoutTablet';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import { selectCurrentAddress, selectDeliveryAddresses } from '../../../../services/redux/store/delivery/selectors';
import { setCurrentAddress } from '../../../../services/redux/store/delivery/slice';
import type { Address } from '../../../../services/redux/store/delivery/types';
import './CheckoutDeliveryAddresses.scss';

export const CheckoutDeliveryAddresses = () => {
  const dispatch = useDispatch();
  const { isDeliveryAddressModalOpen } = useSelector(selectUIDelivery);
  const deliveryAddresses = useSelector(selectDeliveryAddresses);
  const currentAddress = useSelector(selectCurrentAddress);
  const isMobileWithoutTablet = useMobileWithoutTablet();

  const onCloseDeliveryAddresses = useCallback(() => {
    dispatch(setIsDeliveryAddressModalOpen(false));
  }, [dispatch]);

  const onAddressAdd = useCallback(() => {
    checkoutDispatchers.notificationsClear.dispatch();
    checkoutDispatchers.deliveryAddressVisit.dispatch();

    dispatch(setCurrentAddress(null));
    dispatch(setIsDeliveryAddressModalOpen(false));
    dispatch(setIsDeliveryScreenOpen(true));
  }, [dispatch]);

  const onAddressEdit = useCallback((address: Address) => {
    checkoutDispatchers.notificationsClear.dispatch();
    checkoutDispatchers.deliveryAddressVisit.dispatch();

    dispatch(setCurrentAddress(address));
    dispatch(setIsDeliveryAddressModalOpen(false));
    dispatch(setIsDeliveryScreenOpen(true));
  }, [dispatch]);

  const onAddressSelect = useCallback((address: Address) => {
    checkoutDispatchers.deliveryAddressSelectFromHistory.dispatch();
    checkoutDispatchers.notificationsClear.dispatch();

    dispatch(setCurrentAddress(address));
    dispatch(setIsDeliveryAddressModalOpen(false));
  }, [dispatch]);


  useHiddenOverflow(isDeliveryAddressModalOpen);

  const RootElem =
    !isMobileWithoutTablet && isDeliveryAddressModalOpen
      ? ScreenModal
      : DrawerOverlay;

  return (
    <RootElem
      isExpanded={isDeliveryAddressModalOpen}
      rootClassName="CheckoutDeliveryScreen"
      className="CheckoutDeliveryScreen_Body Checkout_Drawer-dialog"
      onClose={onCloseDeliveryAddresses}
    >
      <div className="CheckoutDeliveryAddresses_Root">
        <CheckoutModalHeader
          title="Адрес"
          className="CheckoutDeliveryScreen_Header"
          onClose={onCloseDeliveryAddresses}
        />
        <div className="CheckoutDeliveryAddresses_Container">
          {deliveryAddresses.map((address) => {
            return (
              <CheckoutDeliveryAddressItem
                key={address.id}
                id={address.id}
                title={address.address}
                description={address.addressCity}
                isSelected={currentAddress?.id === address.id}
                onClickSelect={() => onAddressSelect(address)}
                onClickEdit={() => onAddressEdit(address)}
              />
            );
          })}
        </div>
        <Button
          type="button"
          onClick={onAddressAdd}
          variant="outlinedSolid"
          className="CheckoutDeliveryAddresses_AddAddress"
          theme="primary"
          size="m"
          fullWidth
        >
          <Icon name="plus" size="m" />
          Добавить адрес
        </Button>
      </div>
    </RootElem>
  );
};
