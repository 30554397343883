import React, { useCallback } from 'react';
import { useMapGLContext } from '../../MapGLContext';
import { MapControlsElement } from '../MapControlsElement';

import './MapControlsZoom.scss';

export const MapControlsZoom = () => {
  const { mapglInstance } = useMapGLContext();

  const onClickZoomIn = useCallback(() => {
    mapglInstance?.setZoom(mapglInstance.getZoom() + 1);
  }, [mapglInstance]);

  const onClickZoomOut = useCallback(() => {
    mapglInstance?.setZoom(mapglInstance.getZoom() - 1);
  }, [mapglInstance]);

  return (
    <MapControlsElement>
      <button
        className="MapControlsZoom_In"
        onClick={onClickZoomIn}
      />
      <button
        className="MapControlsZoom_Out"
        onClick={onClickZoomOut}
      />
    </MapControlsElement>
  );
};
