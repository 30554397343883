export const FILTER_NAMES = {
  aroundTheClock: 'Круглосуточно',
  inAnHour: 'Забрать за час',
  tomorrow: 'Завтра или позже',
  onlinePay: 'Оплата онлайн',
  today: 'Сегодня',
  installment: 'Плати частями',
  allInStock: 'Всё в наличии',
  sberSpasibo: 'СберСпасибо'
};
