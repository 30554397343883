import { createSlice } from "@reduxjs/toolkit";
import { STORE_NAMES } from "../consts";
import { ReceivingMethodStore } from "./types";

const initialState = {
  status: 'IDLE',
  method: {
    isDeliverySelected: false,
    isPickupSelected: false,
  }
} as ReceivingMethodStore;

export const receivingSlice = createSlice({
  name: STORE_NAMES.RECEIVING,
  initialState,
  reducers: {
    setReceivingMethodDelivery: (state) => {
      state.method.isPickupSelected = false;
      state.method.isDeliverySelected = true;
    },
    setReceivingMethodPickup: (state) => {
      state.method.isDeliverySelected = false;
      state.method.isPickupSelected = true;
    },
    resetReceivingMethods: (state => {
      state.method.isDeliverySelected = false;
      state.method.isPickupSelected = false;
    }),
  }
})

const receivingReducer = receivingSlice.reducer;
const receivingActions = receivingSlice.actions;

export const { setReceivingMethodDelivery, setReceivingMethodPickup, resetReceivingMethods } = receivingActions;
export { receivingReducer, receivingActions };
