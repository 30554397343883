import React, {
  useCallback,
} from 'react';
import {
  HorizontalScroll,
  useViewSize
} from '@npm-registry/eapteka-ui';
import { CheckoutDeliveryDay } from './Day';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDate, setCurrentInterval } from '../../../../../services/redux/store/delivery/slice';
import { selectCurrentDate, selectDeliveryDates } from '../../../../../services/redux/store/delivery/selectors';

export const CheckoutDeliveryDates = () => {
  const { isMobile } = useViewSize();
  const dispatch = useDispatch();
  const deliveryDates = useSelector(selectDeliveryDates);
  const currentDate = useSelector(selectCurrentDate);

  const handleDateSelect = useCallback(
    (date) => {
      dispatch(setCurrentDate(date));
      dispatch(setCurrentInterval(null));
    },
    [dispatch]
  );

  return (
    <HorizontalScroll
      hideArrow={isMobile}
      hideArrowLine
      scrollStep={200}
      className="CheckoutDeliveryDates_Root"
    >
      {deliveryDates.map((deliveryDate) => {
        return (
          <CheckoutDeliveryDay
            key={deliveryDate.id}
            title={deliveryDate.title}
            description={deliveryDate.description}
            isSelected={deliveryDate.id === currentDate?.id}
            onClick={() => handleDateSelect(deliveryDate)}
          />
        );
      })}
    </HorizontalScroll>
  );
};
