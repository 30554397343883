import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { MapPointerEvent, Marker } from '@2gis/mapgl/types';
import { useMapGLContext } from './MapGLContext';
import { USER_MARKER } from './const';
import { MapGLDispatcher } from './helpers/dispatcher';
import { requestAddressByCoords } from '../../../services/api/methods/geocodes';
import { isUserInRegion } from '../../../tools/region/isUserOutOfRegion';
import { resetIconSize } from '../../blocks/CheckoutPickup/Map/Placemarks/helpers';
import { createNotification } from '../../../services/notifications';

export type GeocodeAddress = {
  building: string;
  city: string;
  coords: {
    lat: number;
    lon: number;
  };
  country: string;
  entrance: string;
  label: string;
  locality: string;
  street: string;
};

type AddressRequest = {
  address: GeocodeAddress;
  status?: string;
  message?: string;
};

export const MapGLUserMarker = (): null => {
  const [userCoords, setUserCoords] = useState<number[]>(
    []
  );
  const { mapglInstance, mapgl } = useMapGLContext();
  const userMarker = useRef<Marker | null>(null);

  const getAddressByCoords = useCallback(
    async (coordinates: number[]) => {
      const { address, status, message }: AddressRequest =
        await requestAddressByCoords({
          coordinates
        });

      if (status && status === 'error') {
        createNotification({ title: 'Что-то пошло не так...', message })

        return;
      }

      if (isUserInRegion(address.city, address.locality)) {
        MapGLDispatcher.userAddress.dispatch(
          address.label,
          address
        );
      } else {
        createNotification({ title: 'Кажется, вы находитесь за пределами выбранного региона' })
      }
    },
    []
  );

  // Подписка на добавление маркера пользователя
  useEffect(() => {
    if (mapglInstance && mapgl) {
      const addUserMarker =
        MapGLDispatcher.userCoordinates.subscribe(
          (
            coordinates: number[],
            skipCentering?: boolean
          ) => {
            setUserCoords(coordinates);
            if (!skipCentering) {
              mapglInstance
                .setCenter(coordinates, {
                  duration: 750
                })
                .setPadding({
                  left: undefined,
                  right: undefined,
                  bottom: undefined
                })
                .setZoom(16, {
                  duration: 750
                });
            }
          }
        );

      mapglInstance.on(
        'click',
        (event: MapPointerEvent) => {
          const activePin = document.querySelector(
            '.MapGL_Marker.active'
          );
          if (!activePin) {
            MapGLDispatcher.userCoordinates.dispatch(
              event.lngLat,
              true
            );
          }
          MapGLDispatcher.userData.dispatch(null);
          setTimeout(resetIconSize, 50);
        }
      );

      return () => {
        addUserMarker();
        setUserCoords([]);
      };
    }
  }, [mapglInstance, mapgl]);

  // Создание и перемещение маркера пользователя
  useEffect(() => {
    function createMarker(lngLat: number[]) {
      userMarker.current = new mapgl!.Marker(
        mapglInstance!,
        {
          coordinates: lngLat,
          icon: USER_MARKER,
          size: [54, 54],
          anchor: [27, 54]
        }
      );
    }
    if (userCoords.length) {
      if (!userMarker.current) {
        createMarker(userCoords);
      } else {
        userMarker.current.setCoordinates(userCoords);
      }
      getAddressByCoords(userCoords);
    } else if (userMarker.current) {
      userMarker.current.destroy();
      userMarker.current = null;
    }
  }, [
    userCoords,
    getAddressByCoords,
    mapgl,
    mapglInstance
  ]);

  return null;
};
