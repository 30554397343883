import { ReactElement } from 'react';

export const createNotification = ({
  title = '',
  message = ''
}: {
  title: string;
  message?: string;
}) => {
  document.dispatchEvent(
    new CustomEvent('appNotificationsPushItem', {
      detail: {
        title,
        message,
        isTemporary: true,
        timer: 7000
      }
    })
  );
};

export const createDialog = ({ title = '', message = '', addon = '', onClose }: {
  title: string, message: string; addon: string | ReactElement; onClose?: () => void;
}) => {
  document.dispatchEvent(new CustomEvent(
    'appNotificationsSetDialog',
    {
      detail: {
        title,
        message,
        addonBottom: addon,
        onClose
      }
    })
  );
}

export const clearDialog = () => document.dispatchEvent(new CustomEvent(
  'appNotificationsClearDialog'
));
