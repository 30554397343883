import React from 'react';
import { FieldText } from '@npm-registry/eapteka-ui';
import { useRecipientCorporate } from './hooks/useRecipientCorporate';
import { checkoutDispatchers } from '../../../services/dispatchers';
import { CheckoutSection } from '../../elements/CheckoutSection';
import { CheckoutSectionHeader } from '../../elements/CheckoutSectionHeader';
import './CheckoutRecipientCorporate.scss';

export const CheckoutRecipientCorporate = () => {
  const {
    isCorporateVisible,
    isVisibleKPP,
    valueCorporateName,
    valueINN,
    valueKPP,
    errorINN,
    errorKPP,
    errorCorporateName,
    onChangeCorporateName,
    onChangeINN,
    onChangeKPP,
    onBlurINN,
    onBlurKPP,
    onBlurCorporateName
  } = useRecipientCorporate();

  checkoutDispatchers.errorCorporateFields.subscribe(() => {
    onBlurCorporateName();
    onBlurINN();
    if (valueINN && valueINN.length === 12) onBlurKPP();
  });

  if (!isCorporateVisible) return null;

  if (isCorporateVisible)
    return (
      <CheckoutSection>
        <CheckoutSectionHeader
          className="CheckoutSectionHeader--Corporate"
          image="documentCheckmark"
        >
          Компания
        </CheckoutSectionHeader>
        <fieldset className="CheckoutRecipientCorporate_Fieldset">
          <FieldText
            value={valueCorporateName || ''}
            onChange={onChangeCorporateName}
            onBlur={onBlurCorporateName}
            hasError={!!errorCorporateName}
            label={
              errorCorporateName
                ? errorCorporateName
                : 'Название компании'
            }
          />
          <FieldText
            maxLength={14}
            value={valueINN || ''}
            onChange={onChangeINN}
            onBlur={onBlurINN}
            hasError={!!errorINN}
            label={errorINN ? errorINN : 'ИНН'}
          />
          {isVisibleKPP && (
            <FieldText
              maxLength={11}
              value={valueKPP || ''}
              onChange={onChangeKPP}
              onBlur={onBlurKPP}
              hasError={!!errorKPP}
              label={errorKPP ? errorKPP : 'КПП'}
            />
          )}
        </fieldset>
      </CheckoutSection>
    );
};
