/* eslint-disable no-use-before-define */
import { Store } from "../store";
import { FilterKeys, Filters, PickupPoint, PickupPointCurrentResponse, PickupPointResponse } from "./types";
import { getAvailableFilters, getOffersStoredInfo, getPaymentInfo, serializeAvailabilityText, serializeButtonText, serializePharmacyTotalPrice } from './helpers';

export const serializePharmacies = (currentPickupPoints: PickupPointCurrentResponse, pickupPoints: PickupPointResponse):{ pharmacies: PickupPoint[], filters: Filters } => {
  const filters: Filters = {};
  const { isBNPLAvailable, minBnplSum, maxBnplSum } = Store.getState().handbook.features.web;
  const { hasItemBenefit, hasItemPreorder } = Store.getState().cart.cart.data;
  const { items, offersData } = Store.getState().cart.cart;
  const { totalPrice } = Store.getState().cart.cart.summary;
  const currentPickupPointIds = currentPickupPoints.data.map(point => point.id);
  const filteredPickupPoints = pickupPoints.data.filter(item => currentPickupPointIds.includes(item.id));
  const isCartSumValidForInstallment =
    totalPrice > minBnplSum &&
    totalPrice < maxBnplSum;

  const serializedCurrent = currentPickupPoints.data.map((pharmacy) => {
    const isPickupAvailable = pharmacy.common_unavailable_reason === '';
    const isPickupInAnHour = isPickupAvailable && pharmacy.in_one_hour;
    const unavailableOffers = pharmacy.unavailable.map(({ offer_id }) => offer_id) || [];
    const totalPharmacyPrice = serializePharmacyTotalPrice(unavailableOffers, items, totalPrice);
    const pickupDate = isPickupAvailable ? new Date(
      pharmacy.client_pickup_date.split('+')[0]
    ).getTime() : 0;
    const availabilityText = serializeAvailabilityText(pharmacy, offersData);

    return {
      availabilityText,
      availableOffers: pharmacy.available.map(({ offer_id }) => offer_id) || [],
      clientPickupDate: pharmacy.client_pickup_date,
      commonUnavailableReason: pharmacy.common_unavailable_reason,
      hasAllInStock: availabilityText === 'Всё в наличии',
      distance: '',
      id: pharmacy.id,
      inOneHour: pharmacy.in_one_hour,
      unavailableOffers,
      pickupDate,
      totalPrice: totalPharmacyPrice,
      pickupDatetime: pharmacy.pickup_date,
      pickupInterval: pharmacy.pickup_interval,
      preorder: pharmacy.preorder,
      isPickupAvailable,
      isPickupInAnHour,
      pickupActionText: isPickupAvailable ? serializeButtonText(pharmacy.in_one_hour, pickupDate) : '',
    }
  });

  const pharmacies = filteredPickupPoints.map((pharmacy) => {
    const isOnlinePayAvailable = pharmacy.availability_online_payment && (!hasItemBenefit || totalPrice > 0);
    const isInstallmentAvailable = isBNPLAvailable && isOnlinePayAvailable && isCartSumValidForInstallment && !pharmacy.is_partner;
    const { paymentIcon, paymentText } =  getPaymentInfo({ isOnlinePayAvailable, cashOnly: pharmacy.cash_only  })
    const paymentIconSize = paymentIcon === 'cashOnly' ? 's' : 'm';
    const currentPickupPoint = serializedCurrent.find(point => point.id === pharmacy.id);
    const totalPharmacyPrice = currentPickupPoint.totalPrice;
    const isLessThanMinPrice = totalPharmacyPrice < pharmacy.min_pickup && totalPharmacyPrice > 0;
    const isPickupAvailablePartly = currentPickupPoint.availabilityText !== 'Всё в наличии';
    const { storeFrom, storeTo } = getOffersStoredInfo({
      shelfLife: pharmacy.shelf_life,
      pickupDate: currentPickupPoint.pickupDate,
      isPickupInAnHour: currentPickupPoint.isPickupInAnHour,
      isPickupAvailable:
        currentPickupPoint.isPickupAvailable
    });

    const pickupPoint = {
      address: pharmacy.address,
      filtersVisible: [] as FilterKeys[],
      chargeServiceFee: pharmacy.charge_service_fee,
      coordinates: [pharmacy.longitude, pharmacy.latitude],
      id: pharmacy.id,
      isAroundTheClock: /круглосуточно/gi.test(pharmacy.time_schedule),
      workingHours: pharmacy.time_schedule,
      shelfLife: pharmacy.shelf_life,
      cashOnly: pharmacy.cash_only,
      storeFrom,
      storeTo,
      name: pharmacy.name,
      metroName: pharmacy.metro?.name || '',
      metroColor: pharmacy.metro?.color || '',
      minPickupPrice: pharmacy.min_pickup,
      phone: pharmacy.phone,
      isAvailabilityOnlinePayment: pharmacy.availability_online_payment,
      isPartner: pharmacy.is_partner,
      isOnlinePayAvailable,
      isPickupAvailablePartly,
      isInstallmentAvailable,
      isLessThanMinPrice,
      paymentText,
      paymentIcon,
      paymentIconSize,
      longitude: pharmacy.longitude,
      latitude: pharmacy.latitude,
      uuid: pharmacy.uuid,
      ...currentPickupPoint,
    }

    const filtersVisible:FilterKeys[] = getAvailableFilters(pickupPoint, hasItemPreorder);
    pickupPoint.filtersVisible = filtersVisible;

    filtersVisible.map((filter) => {
      if (!filters[filter]) {
        filters[filter] = false;
      }
    });


    return pickupPoint;
  });

  return { pharmacies, filters }
}

