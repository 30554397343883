/* eslint-disable no-use-before-define */
import { pluralizeWord } from '@npm-registry/helpers/pluralize';
import { CartItem, CartStore, Offer } from './cart/types';
import { Payment, PaymentDeliveryRequest } from './payment/types';
import { RegionStore } from './region/types';
import { LevelSerialized, UserStore } from './user/types';
import { Address, Interval } from './delivery/types';

export function isBonusVisible(cart: CartStore, user: UserStore, region: RegionStore): boolean {
  return (
    region.region.features.bonusProgram &&
    !cart.cart.data.hasItemBenefitOnly &&
    !user.user.isCorporate &&
    cart.cart.bonusProgram.withdraw > 0
  );
}

export function isBonusAvailable(
  cart: CartStore,
  user: UserStore,
  payment: Payment,
  paymentTypes: Payment[],
  isPickupSelected: boolean
): { isAvailable: boolean; description: string; title: string; level: string } {
  const isPromoCodeApplied = !!localStorage.getItem('promoCodeApplied');
  const levels = user.user.bonusProgram.levels;
  const level = user.user.bonusProgram.level;
  const balance = user.user.bonusProgram.balance;
  const withdraw = cart.cart.bonusProgram.withdraw;
  const isCashSelected = ['cash', 'pickup_payment'].includes(payment.code);
  const isOnlinePaymentAvailable = paymentTypes.some((p) => p.code === 'card');
  const isAvailableOnlyOnline = isCashSelected && isOnlinePaymentAvailable && isPickupSelected;
  const currentBonusLevel = levels ? levels[level] : null;
  const description = () => {
    if (isAvailableOnlyOnline) {
      return 'Доступно при онлайн-оплате';
    }
    if (payment.code === 'bnpl') {
      return 'Недоступно при оплате частями';
    }
    return serializeBonusDescription(isPromoCodeApplied, currentBonusLevel, payment);
  };

  const title = serializeBonusTitle(balance, withdraw);
  const currentLevel = serializeBonusLevel(isPromoCodeApplied, levels[level]);

  return {
    isAvailable:
      !(isPromoCodeApplied || !user.user.bonusProgram.phoneVerified) &&
      cart.cart.bonusProgram.canWithdraw &&
      payment.code !== 'bnpl',
    description: description(),
    title,
    level: currentLevel
  };
}

function serializeBonusDescription(
  isPromoCodeApplied: boolean,
  currentBonusLevel: LevelSerialized,
  currentPayment: Payment
) {
  if (isPromoCodeApplied) {
    return 'Недоступно, пока действует промокод';
  }

  if (currentPayment?.code === 'bnpl') {
    return 'Недоступно при оплате частями';
  }

  if (currentBonusLevel) {
    const { percent } = currentBonusLevel;

    return `можно оплатить до ${percent}%`;
  }
}

function serializeBonusLevel(isPromoCodeApplied: boolean, currentBonusLevel: LevelSerialized) {
  if (isPromoCodeApplied) return '';

  if (currentBonusLevel) {
    const { name } = currentBonusLevel;

    return `Уровень ${name}:`;
  }
}

function serializeBonusTitle(bonusProgramBalance: number, bonusProgramWithdraw: number) {
  const isSpendAllBonuses = bonusProgramWithdraw >= bonusProgramBalance;
  const bonusCount = isSpendAllBonuses ? bonusProgramWithdraw : bonusProgramBalance;
  const bonusCountText = isSpendAllBonuses
    ? bonusProgramWithdraw
    : `${bonusProgramWithdraw} из ${bonusProgramBalance}`;
  const bonusWord = pluralizeWord(bonusCount, ['бонус', 'бонуса', 'бонусов']);

  return `${bonusCountText} ${bonusWord}`;
}

export function getMostRecentDeliveryAddress(deliveryAddresses: Address[]): Address | null {
  if (deliveryAddresses.length < 1) return null;

  const recentDate = deliveryAddresses.reduce((timestamp, address) => {
    if (address.lastDeliveryDate) {
      if (Date.parse(address.lastDeliveryDate) > timestamp) {
        timestamp = Date.parse(address.lastDeliveryDate);
      }
    }
    return timestamp;
  }, 0);

  const mostRecentDeliveryAddress = deliveryAddresses.find(
    ({ lastDeliveryDate }) => lastDeliveryDate && Date.parse(lastDeliveryDate) === recentDate
  );

  return mostRecentDeliveryAddress ? mostRecentDeliveryAddress : null;
}

export function getDeliveryDateStart(offers: (CartItem & Offer)[]) {
  const deliveryDateStart = offers.reduce((deliveryDateStart, offer) => {
    const offerDeliveryDate = Date.parse(offer.deliveryDate);

    return deliveryDateStart > offerDeliveryDate ? deliveryDateStart : offerDeliveryDate;
  }, 0);
  const today = new Date().toISOString();
  const todayTimestamp = Date.parse(today);
  const intervalDate = deliveryDateStart > todayTimestamp ? deliveryDateStart : todayTimestamp;

  return new Date(intervalDate);
}

export function getDeliveryPaymentsPayload(
  address: Address,
  interval: Interval,
  totalSum: number
): PaymentDeliveryRequest {
  return {
    delivery: {
      address: {
        address_city: address.addressCity,
        address_country: address.addressCountry,
        address_house: address.addressHouse,
        address_street: address.addressStreet,
        apartment: address.apartment,
        comment: address.comment,
        entrance: address.entrance,
        entrance_code: address.entranceCode,
        floor: address.floor,
        id: address.id,
        last_delivery_date: address.lastDeliveryDate,
        latitude: address.latitude,
        longitude: address.longitude
      },
      interval: {
        from: interval.from,
        to: interval.to
      }
    },
    total_sum: totalSum
  };
}
