import React from 'react';
import { getUserLocation } from '../../helpers/getUserGeolocation';
import { useMapGLContext } from '../../MapGLContext';
import { MapControlsElement } from '../MapControlsElement';

import './MapControlsGeolocation.scss';

export const MapControlsGeolocation = () => {
  const { mapglInstance } = useMapGLContext();
  const showUser = () => getUserLocation(mapglInstance);

  return (
    <MapControlsElement>
      <button
        className="MapControlsGeolocation"
        onClick={showUser}
      />
    </MapControlsElement>
  );
};
