import { debouncePromise } from '@npm-registry/helpers/debouncePromise';
import { requestPickupAddressSuggestions } from '../geocodes';

export const getPickupAddressSuggestions = debouncePromise(
  async (address, coordinates = []) => {
    try {
      return await requestPickupAddressSuggestions({
        address,
        coordinates
      });
    } catch (error) {
      console.warn(
        'Ошибка в получении координат для подсказок',
        JSON.parse(error.config.data),
        error.response.data
      );
    }
  },
  300
);
