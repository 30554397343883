import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRegion } from '../../../../../services/redux/store/region/selectors';
import { useDeliveryAvailable } from './useDeliveryAvailable';
import { selectUserIsCorporate } from '../../../../../services/redux/store/user/selectors';
import { selectCartSummary } from '../../../../../services/redux/store/cart/selectors';
import { selectReceivingMethod } from '../../../../../services/redux/store/receiving/selectors';
import { selectCurrentAddress, selectCurrentInterval } from '../../../../../services/redux/store/delivery/selectors';

export function useDeliveryDescription() {
  const { totalPrice } = useSelector(selectCartSummary);
  const { isDeliverySelected } = useSelector(selectReceivingMethod);
  const deliveryAddress = useSelector(selectCurrentAddress);
  const currentDeliveryInterval = useSelector(selectCurrentInterval);

  const isCorporate = useSelector(selectUserIsCorporate);
  const isDeliveryCompany = useSelector(selectRegion).deliveryCompany;

  const isDeliveryAvailable = useDeliveryAvailable();
  const deliveryPrice = isDeliverySelected ? currentDeliveryInterval?.price || 0 : 0;

  return useMemo(() => {
    let deliveryTitle = 'Доставка курьером';
    let deliveryDescription = '';

    const freeDeliveryFrom =
      currentDeliveryInterval?.freeFrom || 0;

    const isDeliveryFree =
      (freeDeliveryFrom !== 999999999 &&
        totalPrice >=
          freeDeliveryFrom) ||
      deliveryPrice <= 0;

    if (!isDeliveryFree && deliveryPrice > 0) {
      deliveryTitle += ` — ${deliveryPrice} ₽`;
    }

    if (isCorporate && !isDeliveryCompany) {
      deliveryDescription =
        'Пока недоступна в вашем регионе';
    } else if (!isDeliveryAvailable && !isCorporate) {
      deliveryDescription = 'Недоступна';
    } else if (!deliveryAddress) {
      deliveryDescription = 'Выберите адрес';
    } else if (currentDeliveryInterval === null) {
      deliveryDescription = 'Выберите время доставки';
    } else if (!isDeliveryFree) {
      deliveryDescription = `${currentDeliveryInterval.price} ₽`;
    } else if (isDeliveryFree) {
      deliveryDescription = 'Бесплатно';
    }

    return {
      isDeliveryAvailable,
      deliveryDescription,
      deliveryTitle
    };
  }, [
    totalPrice,
    deliveryPrice,
    isCorporate,
    isDeliveryCompany,
    isDeliveryAvailable,
    deliveryAddress,
    currentDeliveryInterval
  ]);
}
