import { useMemo } from 'react';
import dateTimeHelper from '@npm-registry/helpers/datetime';
import { useSelector } from 'react-redux';
import { selectCurrentInterval } from '../../../../../services/redux/store/delivery/selectors';

export function useIntervalDescription() {
  const currentInterval = useSelector(selectCurrentInterval);

  return useMemo(() => {
    const deliveryPeriod = 'Выбрать время доставки';
    const intervalTitle = 'Время доставки:';
    const isIntervalSelected = !!currentInterval;

    let intervalDescription = '';

    if (isIntervalSelected) {
      const intervalDay = new Date(currentInterval.from).getDate();
      const intervalDate = new Date(currentInterval.from);
      const intervalMonth =
        dateTimeHelper
          .formatDatetimeToMonthNameGenitive(intervalDate)
          .charAt(0)
          .toUpperCase() +
        dateTimeHelper
          .formatDatetimeToMonthNameGenitive(intervalDate)
          .slice(1);
      const intervalTimes = currentInterval.description.split(
          '-'
        );
      const timeFrom = intervalTimes[0];
      const timeTo = intervalTimes[1];

      intervalDescription = `${intervalDay} ${intervalMonth}, с ${timeFrom} по ${timeTo}`;
    }

    return {
      isIntervalSelected,
      intervalDescription,
      deliveryPeriod,
      intervalTitle
    };
  }, [currentInterval]);
}
