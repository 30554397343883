import { useState } from 'react';
import { validateFullName } from '../../../../tools/validation/validateFullName/validateFullName';
import { UserFormFields } from '../consts';

export function useFormFullName(fullName, callback) {
  const [hasErrorFullName, setHasErrorFullName] =
    useState(false);
  const [errorFullNameMessage, setErrorFullNameMessage] =
    useState('');

  const onBlurFullName = () => {
    const { hasError, errorMessage } =
      validateFullName(fullName);

    setHasErrorFullName(hasError);
    setErrorFullNameMessage(errorMessage);

    callback(fullName);

    return [UserFormFields.name, fullName, hasError];
  };

  const onValidateFullName = (fullName) => {
    const { hasError, errorMessage } =
      validateFullName(fullName);

    setHasErrorFullName(hasError);
    setErrorFullNameMessage(errorMessage);
  };

  return {
    onBlurFullName,
    onValidateFullName,
    hasErrorFullName,
    errorFullNameMessage
  };
}
