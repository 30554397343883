import { sendAmplitudeAnalyticsEvent } from '../providers/AmplitudeAnalytics';
import { checkoutDispatchers } from '../../dispatchers';
import { getGeoDistance } from '../../../tools/geo/getDistance';
import { getDaysToDate } from '../../../tools/datetime/getDiffInDays';
import { isInstallmentPaymentAvailable } from '../../helpers/isInstallmentPaymentAvailable';
import { pickupButtonTypes } from '../../../components/blocks/CheckoutReceivingMethods/Pickup/hooks/useReceivingMethodPickup/consts';
import { getAuthType } from '../helpers/getAuthType';
import { Store } from '../../redux/store/store';
import { selectCurrentPayment, selectPaymentLoyaltyBonuses, selectSberSpasibo } from '../../redux/store/payment/selectors';
import { selectCurrentAddress, selectCurrentDate, selectCurrentInterval, selectDeliveryDates } from '../../redux/store/delivery/selectors';
import { selectCartData, selectCartItems, selectCartOffersData, selectCartSummary } from '../../redux/store/cart/selectors';
import { selectRegion } from '../../redux/store/region/selectors';
import { selectUserIsCorporate } from '../../redux/store/user/selectors';

function isDeliveryAvailable() {
  const { isDeliveryAvailable } = selectRegion(
    Store.getState()
  );
  const isCorporate = selectUserIsCorporate(
    Store.getState()
  );
  const {
    hasItemWithPku,
    hasItemWithRecipe,
    hasItemWithAlcohol,
    hasItemWithRecipeOnly,
    hasERecipes
  } = selectCartData(Store.getState());

  const cartHasRestrictions =
    hasItemWithPku ||
    hasItemWithRecipe ||
    hasItemWithAlcohol ||
    hasItemWithRecipeOnly;

  return (
    (isDeliveryAvailable && !cartHasRestrictions) ||
    isCorporate ||
    hasERecipes
  );
}


/**
 * Просмотр экрана с доступными вариантами интервалов дат
 */
const onDeliveryAvailableDateExpand = () => {
  const deliveryDates = selectDeliveryDates(Store.getState());

  const customParams = {
    delivery_date: []
  };

  if (deliveryDates && deliveryDates.length > 0) {
    deliveryDates.filter((deliveryDate) => {
      const date = new Date(deliveryDate.timestamp);
      customParams.delivery_date.push(date.toISOString());
    });
  }

  sendAmplitudeAnalyticsEvent({
    id: 'd-v1-e25',
    name: 'Delivery Available Date Expand',
    customParams
  });
};

/**
 * Пользователь зашел на страницу чекаута
 */
checkoutDispatchers.pageVisit.subscribe(async () => {

  // Надо вынести promoCodeApplied в хелпер или стор на локалсторадж
  const promoCode =
    localStorage.getItem('promoCodeApplied') || '';
  const currentAddress = selectCurrentAddress(Store.getState());
  const { isCorporate } = Store.getState().user.user;
  const currentPickupPoint = Store.getState().pharmacies.selectedPharmacy || {};
  const currentPickupPointId = currentPickupPoint?.id;

  const { name, isPickupAvailable } = Store.getState().region.region;
  const items = selectCartItems(Store.getState());
  const { totalOffersCount: itemsCount } = selectCartOffersData(Store.getState());
  const { totalPrice, discount: totalDiscount, promocodeAmount } = selectCartSummary(Store.getState());
  const user = Store.getState().user.user;
  const isSberPrimeActive = user.sberprime?.isActive;
  const isPromoCodeApplied = !!localStorage.getItem('promoCodeApplied');

  const { withdraw, isEnabled } =  Store.getState().payment.bonuses;
  const bnplAvailability = isInstallmentPaymentAvailable();

  const customParams = {
    item_id: [],
    item_price: [],
    item_name: [],
    item_prescription_type: [],
    item_delivery_type: [],
    item_is_in_stock: [],
    item_is_preorder: [],
    is_delivery_available: false,
    is_pick_up_available: isPickupAvailable,
    is_delivery_address_filled:
      !!currentAddress || false,
    is_pick_up_address_filled:
      !!currentPickupPointId || false,
    quantity: itemsCount || 0,
    revenue: totalPrice || 0,
    discount: totalDiscount || 0,
    promo_discount: isPromoCodeApplied
      ? Number(
          promocodeAmount / (totalPrice / 100)
        ).toFixed(0)
      : 0,
    item_is_online_recipe: [],
    selected_city: name || '',
    prime: isSberPrimeActive || false,
    bnpl_availability: bnplAvailability
      ? 'available'
      : 'not_available',
    loyalty_points:
      !isCorporate && isEnabled && withdraw > 0 // https://jira.eapteka.ru/browse/PLWEB-185
        ? withdraw
        : 0,
    item_is_isg: [],
    item_quantity: [],
    crossed_price_discount: Number(
      totalDiscount / (totalPrice / 100)
    ).toFixed(2),
    promocode_name: promoCode,
    authorization_type: getAuthType(),
    recipe_source: [], // всегда пустой
    is_online_recipe_client: '', // всегда пустой
    is_active_online_recipe_client: '', // всегда пустой
    item_is_vmt: [], // boolean[] - статус высокомаржинальности товара (ВМТ)
    item_is_stm: [], // boolean[] - статус товара с признаком собственной торговой марки
    item_brand: [], // string[] - бренд товара
    item_category: [] // string[] - категория товара
  };

  items.filter((item) => {
    let prescriptionType = 'no_recipe';

    if (item.isPku) {
      prescriptionType = 'PKU';
    } else if (item.byRecipe) {
      prescriptionType = 'RX';
    } else if (item.isRecipe) {
      prescriptionType = 'RX_is_recipe';
    }

    customParams.item_id.push(item.id);
    customParams.item_price.push(Math.round(item.price));
    customParams.item_name.push(item.name);
    customParams.item_prescription_type.push(
      prescriptionType
    );
    customParams.item_is_in_stock.push(item.count > 0);
    customParams.item_is_preorder.push(!!item?.isPreorder);
    customParams.item_is_online_recipe.push(
      !!item.recipe && !!item.provider
    );
    customParams.item_is_isg.push(item?.expired);
    customParams.item_quantity.push(item?.count);
    customParams.item_is_vmt.push(item?.isVmt);
    customParams.item_is_stm.push(item?.isStm);
    customParams.item_brand.push(item?.brand);
    customParams.item_category.push(item?.category);

    if (!(item.isPku || item.isRecipe || item.hasAlcohol)) {
      customParams.item_delivery_type.push(
        'Regular delivery'
      );
    }
    if (!isCorporate) {
      customParams.item_delivery_type.push('Pick up');
    }
  });

  if (isDeliveryAvailable()) {
    customParams.is_delivery_available = true;
  }

  sendAmplitudeAnalyticsEvent({
    id: 'd-v1-e8',
    name: 'Checkout Began',
    customParams
  });
});

/**
 * Выбор способа доставки - самовывоз
 */
checkoutDispatchers.receivingMethodPickupSelect.subscribe(
  () => {
    const currentPickupPoint = Store.getState().pharmacies.selectedPharmacy || {};

    const customParams = {
      getting_order_method: 'Pick up',
      item_button_type: 'Pickup radiobutton',
      is_partner: currentPickupPoint?.isPartner || false,
      is_interaction: !!currentPickupPoint?.id
    };

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e23',
      name: 'Getting Order Method Selected',
      customParams
    });
  }
);

/**
 * Выбор способа доставки - курьерская доставка
 */
checkoutDispatchers.receivingMethodDeliverySelect.subscribe(
  (props) => {
    const customParams = {
      getting_order_method: 'Regular delivery',
      item_button_type:
        props?.itemButtonType || 'Delivery radiobutton',
      is_partner: false,
      is_interaction: true
    };

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e23',
      name: 'Getting Order Method Selected',
      customParams
    });
  }
);

/**
 * Добавление адреса
 */
checkoutDispatchers.deliveryAddressAdd.subscribe(() => {
  const currentDeliveryAddress = selectCurrentAddress(Store.getState());

  const customParams = {
    is_delivery_available:
      isDeliveryAvailable() &&
      currentDeliveryAddress?.zoneId?.length > 0
  };

  sendAmplitudeAnalyticsEvent({
    id: 'd-v1-e24',
    name: 'Delivery Address Added',
    customParams
  });
});

/**
 * Просмотр интервалов доставки
 */
checkoutDispatchers.deliveryIntervalsVisit.subscribe(
  async () => {
    onDeliveryAvailableDateExpand();
  }
);

/**
 * Пользователь выбрал адрес из истории адресов
 */
checkoutDispatchers.deliveryAddressSelectFromHistory.subscribe(
  async () => {
    const currentDeliveryAddress = selectCurrentAddress(Store.getState());

    if (currentDeliveryAddress) {
      const zone = currentDeliveryAddress.zoneId || '';

      const customParams = {
        is_delivery_available:
          isDeliveryAvailable() && zone?.length > 0
      };

      sendAmplitudeAnalyticsEvent({
        id: 'd-v1-e29',
        name: 'Delivery History Address Selected',
        customParams
      });
    }
  }
);

/**
 * Просмотр экрана "Адрес"
 */
checkoutDispatchers.deliveryAddressVisit.subscribe(
  () => {
    const { name } = Store.getState().region.region;

    const customParams = {
      page: 'Checkout',
      city_selected: name || ''
    };

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e105',
      name: 'Address Screen Shown',
      customParams
    });
  }
);

/**
 * Пользователь кликнул по кнопке "выбрать" на карточке описания аптеки на карте или на списке
 */
checkoutDispatchers.pickupPharmacyClick.subscribe(() => {
  const { baseCoordinates } = Store.getState().pharmacies;
  const { isMapView } = Store.getState().ui.pickup;

  const currentPickupPoint = Store.getState().pharmacies.selectedPharmacy || {};
  let pharmacy_distance = '0 км';

  if (
    baseCoordinates?.latitude &&
    currentPickupPoint?.latitude
  ) {
    pharmacy_distance = `${getGeoDistance(
      baseCoordinates.latitude,
      baseCoordinates.longitude,
      currentPickupPoint.latitude,
      currentPickupPoint.longitude
    ).toFixed(3)} км`;
  }

  const customParams = {
    pharmacy_type: currentPickupPoint?.isPartner
      ? 'Партнерская'
      : 'Eapteka',
    items_available: currentPickupPoint?.availabilityText,
    pickup_time: currentPickupPoint?.pickupActionText,
    is_online_payment_available:
      currentPickupPoint?.isOnlinePayAvailable || false,
    is_on_map: isMapView ? 'map' : 'list',
    pharmacy_name: currentPickupPoint?.name,
    pharmacy_distance,
    is_pickup_available:
      currentPickupPoint?.isPickupAvailable || false
  };

  sendAmplitudeAnalyticsEvent({
    id: 'd-v1-e37',
    name: 'Pickup Pharmacy Selected',
    customParams
  });
});

/**
 * Возможные сопособы оплаты по отправке запроса
 */
checkoutDispatchers.availablePaymentMethods.subscribe(
  (props) => {
    const customParams = {
      payment_type: []
    };

    const { paymentMethods } = props;

    if (paymentMethods && paymentMethods.size > 0) {
      paymentMethods.forEach((payment) => {
        if (payment.isAvailable && !payment.bindingId) {
          customParams.payment_type.push(payment.title);
        }
        if (
          payment.isAvailable &&
          payment.bindingId &&
          customParams.payment_type.indexOf('binding') < 0
        ) {
          customParams.payment_type.push('binding');
        }
      });
    }

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e41',
      name: 'Payment Methods Available',
      customParams
    });
  }
);

/**
 * Пользователь выбрал тип оплаты
 */
checkoutDispatchers.paymentMethodSubmit.subscribe(
  (props = {}) => {
    const { is_interaction = false } = props;
    const paymentMethod = selectCurrentPayment(Store.getState());

    if (paymentMethod) {
      const customParams = {
        payment_type: paymentMethod.bindingId
          ? 'binding'
          : paymentMethod.code,
        is_interaction: is_interaction || false
      };

      sendAmplitudeAnalyticsEvent({
        id: 'd-v1-e40',
        name: 'Payment Method Selected',
        customParams
      });
    }
  }
);

/**
 * Пользователь активировал ползунок списания баллов ПЛ
 */
checkoutDispatchers.loyaltyPointsUsed.subscribe(() => {
  const { isEnabled } = selectPaymentLoyaltyBonuses(Store.getState());

  if (isEnabled) {
    const customParams = {
      loyalty_points: Store.getState().cart.cart.bonusProgram.withdraw || 0
    };

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e106',
      name: 'Loyalty Points Used',
      customParams
    });
  }
});

/**
 * Клик по плашке "Состав заказа"
 */
checkoutDispatchers.summaryOffersClick.subscribe(() => {
  const items = selectCartItems(Store.getState());
  const { totalOffersCount: itemsCount } = selectCartOffersData(Store.getState());
  const { totalPrice, discount: totalDiscount } = selectCartSummary(Store.getState());
  const { isCorporate } = Store.getState().user.user;
  const currentPickupPoint = Store.getState().pharmacies.selectedPharmacy || {};
  const unavailableOffers =
    currentPickupPoint?.unavailableOffers || [];

  const customParams = {
    items_available: 'Доступно всё',
    item_id: [],
    item_price: [],
    item_name: [],
    item_prescription_type: [],
    item_delivery_type: [],
    quantity: itemsCount || 0,
    revenue: totalPrice || 0,
    discount: totalDiscount || 0
  };

  items.filter((item) => {
    let prescriptionType = 'no_recipe';

    if (item.isPku) {
      prescriptionType = 'PKU';
    } else if (item.byRecipe) {
      prescriptionType = 'RX';
    } else if (item.isRecipe) {
      prescriptionType = 'RX_is_recipe';
    }

    customParams.item_id.push(item.id);
    customParams.item_price.push(Math.round(item.price));
    customParams.item_name.push(item.name);
    customParams.item_prescription_type.push(
      prescriptionType
    );

    if (!(item.isPku || item.isRecipe || item.hasAlcohol)) {
      customParams.item_delivery_type.push(
        'Regular delivery'
      );
    }
    if (!isCorporate) {
      customParams.item_delivery_type.push('Pick up');
    }
  });

  if (unavailableOffers?.length > 0) {
    customParams.items_available = `Доступно ${itemsCount - unavailableOffers?.length} товаров из ${itemsCount}`;
  }

  sendAmplitudeAnalyticsEvent({
    id: 'd-v1-e107',
    name: 'Order List Clicked',
    customParams
  });
});

/**
 * Пользователь кликнул по кнопке "оформить заказ"
 */
checkoutDispatchers.confirmOrderSubmit.subscribe(
  async () => {
    const currentPickupPoint = Store.getState().pharmacies.selectedPharmacy || {};
    const items = selectCartItems(Store.getState());
    const { totalOffersCount: itemsCount } = selectCartOffersData(Store.getState());
    const { totalPrice, discount: totalDiscount } = selectCartSummary(Store.getState());
    const paymentMethod = selectCurrentPayment(Store.getState());
    const bnplAvailability = isInstallmentPaymentAvailable();

    const customParams = {
      item_id: [],
      item_price: [],
      item_name: [],
      item_prescription_type: [],
      item_is_preorder: [],
      quantity: itemsCount || 0,
      revenue: totalPrice || 0,
      discount: totalDiscount || 0,
      bnpl_available: bnplAvailability,
      payment_type: paymentMethod?.bindingId
        ? 'binding'
        : paymentMethod?.name,
      delivery_type: '',
      pharmacy_type: '',
      pickup_time: '',
      delivery_time: '',
      delivery_date: '',
      pharmacy_name: '',
      item_is_online_recipe: [],
      quantity_online_recipe: 0
    };

    items.filter((item) => {
      const isOnlineRecipe =
        !!item.recipe && !!item.provider;
      let prescriptionType = 'no_recipe';

      if (item.isPku) {
        prescriptionType = 'PKU';
      } else if (item.byRecipe) {
        prescriptionType = 'RX';
      } else if (item.isRecipe) {
        prescriptionType = 'RX_is_recipe';
      }

      customParams.item_id.push(item.id);
      customParams.item_price.push(Math.round(item.price));
      customParams.item_name.push(item.name);
      customParams.item_prescription_type.push(
        prescriptionType
      );
      customParams.item_is_preorder.push(
        !!item?.isPreorder
      );
      customParams.item_is_online_recipe.push(
        isOnlineRecipe
      );
      if (isOnlineRecipe) {
        customParams.quantity_online_recipe++;
      }
    });

    if (Store.getState().receiving.method.isDeliverySelected) {
      const interval = selectCurrentInterval(Store.getState());
      const deliveryDate = selectCurrentDate(Store.getState());
      const delivery_date = deliveryDate?.timestamp
        ? new Date(deliveryDate.timestamp).toISOString()
        : '';

      customParams.delivery_type = 'Regular delivery';
      customParams.pharmacy_type = 'delivery';
      customParams.pickup_time = 'delivery';
      customParams.pharmacy_name = 'delivery';
      customParams.delivery_time = interval?.description || '';
      customParams.delivery_date = delivery_date;
    }

    if (Store.getState().receiving.method.isPickupSelected) {
      customParams.delivery_type = 'Pick up';
      customParams.delivery_time = 'pickup';
      customParams.delivery_date = 'pickup';
      customParams.pharmacy_type =
        currentPickupPoint?.isPartner
          ? 'Партнерская'
          : 'Eapteka';
      customParams.pickup_time =
        currentPickupPoint?.pickupActionText;
      customParams.pharmacy_name = currentPickupPoint?.name;
    }

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e43',
      name: 'Submit Order Clicked',
      customParams
    });
  }
);

/**
 * Успешное оформление заказа
 */
checkoutDispatchers.confirmOrderSuccess.subscribe(
  async (props) => {
    // Надо вынести promoCodeApplied в хелпер или стор на локалсторадж
    const promoCode = localStorage.getItem('promoCodeApplied') || '';
    const currentPickupPoint = Store.getState().pharmacies.selectedPharmacy || {};
    const isPromoCodeApplied = !!localStorage.getItem('promoCodeApplied');
    const items = selectCartItems(Store.getState());
    const { totalOffersCount: itemsCount } = selectCartOffersData(Store.getState());
    const { totalPrice, discount: totalDiscount, promocodeAmount } = selectCartSummary(Store.getState());
    const { isEnabled: isSberSpasiboEnabled, withdraw: sberSpasiboBonuses } = selectSberSpasibo(Store.getState());
    const user = Store.getState().user.user;
    const isSberPrimeActive = user.sberprime?.isActive;
    const { isCorporate } = user;
    const bnplAvailability = isInstallmentPaymentAvailable();
    const paymentMethod = selectCurrentPayment(Store.getState());

    const customParams = {
      item_id: [],
      item_price: [],
      item_name: [],
      item_prescription_type: [],
      item_is_in_stock: [],
      item_delivery_type: [],
      quantity: itemsCount || 0,
      revenue: totalPrice || 0,
      discount: totalDiscount || 0,
      bnpl_available: bnplAvailability,
      delivery_type: '',
      order_id: props?.orderId || null,
      item_is_online_recipe: [],
      quantity_online_recipe: 0,
      prime: isSberPrimeActive || false,
      item_brand: [], // string[] - бренд товара
      item_category: [], // string[] - категория товара
      recipe_source: [], // всегда пустой
      item_is_isg: [],
      item_is_vmt: [], // boolean[] - статус высокомаржинальности товара (ВМТ)
      item_is_stm: [], // boolean[] - статус товара с признаком собственной торговой марки
      promocode_name: promoCode,
      crossed_price_discount: Number(
        totalDiscount / (totalPrice / 100)
      ).toFixed(2),
      promo_discount: isPromoCodeApplied
        ? Number(
            promocodeAmount / (totalPrice / 100)
          ).toFixed(0)
        : 0,
      is_online_recipe_client: '', // всегда пустой
      is_active_online_recipe_client: '', // всегда пустой
      pay_status: props?.payStatus,
      pay_error_message: props?.payErrorMessage || ''
    };

    if (paymentMethod) {
      customParams.payment_type = paymentMethod.bindingId
        ? 'binding'
        : paymentMethod.title || '';
    }

    items.filter((item) => {
      const isOnlineRecipe =
        !!item.recipe && !!item.provider;
      let prescriptionType = 'no_recipe';

      if (item.isPku) {
        prescriptionType = 'PKU';
      } else if (item.byRecipe) {
        prescriptionType = 'RX';
      } else if (item.isRecipe) {
        prescriptionType = 'RX_is_recipe';
      }

      customParams.item_id.push(item.id);
      customParams.item_price.push(Math.round(item.price));
      customParams.item_name.push(item.name);
      customParams.item_is_in_stock.push(item.count > 0);
      customParams.item_prescription_type.push(
        prescriptionType
      );
      customParams.item_is_online_recipe.push(
        isOnlineRecipe
      );
      customParams.item_brand.push(item?.brand);
      customParams.item_category.push(item?.category);
      customParams.item_is_isg.push(item?.expired);
      customParams.item_is_vmt.push(item?.isVmt);
      customParams.item_is_stm.push(item?.isStm);

      if (
        !(item.isPku || item.isRecipe || item.hasAlcohol)
      ) {
        customParams.item_delivery_type.push(
          'Regular delivery'
        );
      }
      if (!isCorporate) {
        customParams.item_delivery_type.push('Pick up');
      }

      if (isOnlineRecipe) {
        customParams.quantity_online_recipe++;
      }
    });

    if (Store.getState().receiving.method.isDeliverySelected) {
      const interval = selectCurrentInterval(Store.getState());
      const deliveryDate = selectCurrentDate(Store.getState());
      const delivery_date = deliveryDate?.timestamp
        ? new Date(deliveryDate.timestamp).toISOString()
        : '';

      customParams.delivery_type = 'доставка';
      customParams.pharmacy_type = 'delivery';
      customParams.pickup_time = 'delivery';
      customParams.pharmacy_name = 'delivery';
      customParams.delivery_time =
        interval?.description || '';
      customParams.delivery_date = delivery_date;
    }

    if (Store.getState().receiving.method.isPickupSelected) {
      customParams.delivery_type = 'самовывоз';
      customParams.delivery_time = 'pickup';
      customParams.delivery_date = 'pickup';
      customParams.pharmacy_type =
        currentPickupPoint?.isPartner
          ? 'Партнерская'
          : 'Eapteka';
      customParams.pickup_time =
        currentPickupPoint?.pickupActionText;
      customParams.pharmacy_name = currentPickupPoint?.name;
    }

    if (isSberSpasiboEnabled && sberSpasiboBonuses > 0) {
      customParams.sberspasibo_points_used =
        sberSpasiboBonuses;
    }

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e9',
      name: 'Order Confirmed',
      customParams
    });
  }
);

/**
 * Ошибка оформления заказа d-v1-e44
 */ // checkoutDispatchers.confirmOrderFail
checkoutDispatchers.orderFailEvent.subscribe(
  async (props) => {
    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e44',
      name: 'Error',
      customParams: {
        error_text: props?.errorMessage || '',
        error_page: 'Checkout',
        error_event: 'Submit Order Clicked',
        server_response: props?.serverError || ''
      }
    });
  }
);

/**
 * Клик по тултипу в блоке с недоступной доставкой
 */
checkoutDispatchers.deliveryInformerClicked.subscribe(
  (message) => {
    if (message) {
      const customParams = {
        description: message || ''
      };

      sendAmplitudeAnalyticsEvent({
        id: 'd-v1-e137',
        name: 'Delivery Informer Clicked',
        customParams
      });
    }
  }
);

/**
 * Пользователь выбрал временной интервал доставки
 */
checkoutDispatchers.deliveryIntervalsSelect.subscribe(
  (currentInterval) => {
    if (currentInterval) {
      sendAmplitudeAnalyticsEvent({
        id: 'd-v1-e28',
        name: 'Delivery Time Selected',
        customParams: {
          delivery_time: currentInterval?.description || '',
          interval_price: currentInterval?.price,
          days_passed: getDaysToDate(currentInterval?.start)
        }
      });
    }
  }
);

/**
 * Пользователь кликнул по чекбоксу "Запоминать карты для будущих покупок"
 */
checkoutDispatchers.saveCardCheckboxChanged.subscribe(
  () => {
    const isCardRemembered = Store.getState().payment.cardRemembered;

    const customParams = {
      status: isCardRemembered
    };

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e158',
      name: 'Save Card Checkbox Clicked',
      customParams
    });
  }
);

/**
 * Пользователь кликнул по кнопке "Добавить адрес"
 */
checkoutDispatchers.deliveryAddressAddButtonClick.subscribe(
  () => {
    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e30',
      name: 'Delivery History Address Add Clicked'
    });
  }
);

/**
 * Пользователь включил ползунок "бесконтактная доставка"
 */
checkoutDispatchers.deliveryContactlessToggle.subscribe(
  (props) => {
    const { isContactLess } = props;

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e32',
      name: 'Delivery Contactless Delivery Checkbox Clicked',
      customParams: {
        status: isContactLess
      }
    });
  }
);

/**
 * Пользователю показываются фильтр со всеми вариантами времени самовывоза: сегодня, забрать завтра, забрать за час etc.
 */
checkoutDispatchers.pickupFiltersShow.subscribe(() => {
  const filtersVisible = Store.getState().pharmacies.filters;

  const customParams = {
    available_filters: Object.keys(filtersVisible),
  };

  sendAmplitudeAnalyticsEvent({
    id: 'd-v1-e33',
    name: 'Pickup Filter Viewed',
    customParams
  });
});

/**
 * Пользователь выбрал один из фильтров
 */
checkoutDispatchers.pickupFilterSelect.subscribe(() => {
  const filtersEnabled = [];
  const filters = Store.getState().pharmacies.filters;

  for (const filter in filters) {
    if (filters[filter] === true) {
      filtersEnabled.push(filter);
    }
  }

  const { isMapView } = Store.getState().ui.pickup;

  const customParams = {
    filter_name: filtersEnabled,
    is_on_map: isMapView ? 'map' : 'list'
  };

  sendAmplitudeAnalyticsEvent({
    id: 'd-v1-e34',
    name: 'Pickup Filter Selected',
    customParams
  });
});

/**
 * Выбор конкретного саджеста адреса на экране "Выбора аптеки"
 */
checkoutDispatchers.pickupAddressSuggestSelect.subscribe(
  (props) => {
    const { suggestionLabel } = props;

    const customParams = {
      suggest_name: suggestionLabel || ''
    };

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e35',
      name: 'Pickup Suggest Selected',
      customParams
    });
  }
);

/**
 * Пользователь открыл аптеку на карте (кликнув по кружку аптеки)
 */
checkoutDispatchers.pickupPharmacyPinClick.subscribe(
  (props) => {
    const { balloonData } = props;
    const { baseCoordinates } = Store.getState().pharmacies;
    let pharmacy_distance = '0 км';

    if (
      baseCoordinates?.latitude &&
      balloonData?.latitude
    ) {
      pharmacy_distance = `${getGeoDistance(
        baseCoordinates.latitude,
        baseCoordinates.longitude,
        balloonData.latitude,
        balloonData.longitude
      ).toFixed(3)} км`;
    }

    const customParams = {
      pharmacy_type: balloonData?.isPartner
        ? 'Партнерская'
        : 'Eapteka',
      items_available: balloonData?.availabilityText,
      pickup_time: balloonData?.pickupActionText,
      is_online_payment_available:
        balloonData?.isOnlinePayAvailable || false,
      pharmacy_name: balloonData?.name,
      pharmacy_distance,
      is_pickup_available:
        balloonData?.isPickupAvailable || false
    };

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e36',
      name: 'Pickup Map Pharmacy Pin Shown',
      customParams
    });
  }
);

/**
 * Пользователь кликнул "Изменить аптеку"
 */
checkoutDispatchers.pickupPharmacyChange.subscribe(
  (buttonType) => {
    const currentPickupPoint = Store.getState().pharmacies.selectedPharmacy || {};
    const customParams = {
      pharmacy_type: 'none'
    };

    if (buttonType === pickupButtonTypes.changePharmacy) {
      customParams.pharmacy_type =
        currentPickupPoint?.isPartner
          ? 'Партнерская'
          : 'Eapteka';
    }

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e39',
      name: 'Pickup Pharmacy Changed',
      customParams
    });
  }
);

/**
 * В блоке оплаты показалась ссылка для смены аптеки для перевода на карту с фильтром bnpl
 */
checkoutDispatchers.bnplPharmaciesButtonShown.subscribe(
  () => {
    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e155',
      name: 'Bnpl Pharmacies Button Shown'
    });
  }
);

/**
 * Клик по ссылке смены аптеки в блоке оплаты, переводит на карту с фильтром по bnpl
 */
checkoutDispatchers.bnplPharmaciesButtonClicked.subscribe(
  () => {
    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e156',
      name: 'Bnpl Pharmacies Button Clicked'
    });
  }
);

/**
 * Пользователь активировал ползунок списания баллов СберСпасибо
 */
checkoutDispatchers.sberSpasiboToggle.subscribe(() => {
  const { isEnabled: isSberSpasiboEnabled, withdraw: sberSpasiboBonuses } = selectSberSpasibo(Store.getState());

  if (isSberSpasiboEnabled) {
    const customParams = {
      sberspasibo_points_used: sberSpasiboBonuses
    };

    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e157',
      name: 'Checkout Sberspasibo Points Used',
      customParams
    });
  }
});

/**
 * Пользователь кликнул на дату в окне выбора интервалов
 */
checkoutDispatchers.deliveryDateSelected.subscribe(
  (currentDate) => {
    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e26',
      name: 'Delivery Date Selected',
      customParams: {
        is_delivery_available: true,
        days_passed: getDaysToDate(currentDate.timestamp),
        legal_person: Store.getState().user.user.isCorporate,
      }
    });
  }
);

/**
 * Отправляется в окне с интервалами при клике на дату и при открытии окна интервалов
 */
checkoutDispatchers.deliveryAvailableTimeExpand.subscribe(
  (intervalsList) => {
    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e27',
      name: 'Delivery Available Time Expand',
      customParams: {
        delivery_time: intervalsList
      }
    });
  }
);
