export function formatPhoneNumberToString(value = '') {
  if (typeof value === 'number') {
    value = value.toString();
  }

  if (typeof value !== 'string' || !value.length) {
    return '';
  }

  value = value.replace(/\s/g, '');

  if (/\D/.test(value)) {
    value = value.replace(/[^\d]/g, '');
  }

  // eslint-disable-next-line no-useless-escape
  const pattern =
    /[\+7|7|8]?(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d*)/;

  return value.replace(pattern, (match, p1, p2, p3, p4) => {
    let result = value.length > 0 ? '+7' : '';
    result += p1 ? ` (${p1}` : '';
    result += p2 ? `) ${p2}` : '';
    result += p3 ? ` ${p3}` : '';
    result += p4 ? ` ${p4}` : '';

    return result;
  });
}
