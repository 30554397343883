import { nanoid } from '@reduxjs/toolkit';
import dateTimeHelper, { DATE_DESCRIPTIONS } from '@npm-registry/helpers/datetime';
import { CURRENT_REGION_ID } from '../../../../tools/const';
import type {
  Address,
  AddressesResponse,
  AddressResponse,
  DeliveryDate,
  DeliveryIntervalsResponse,
  Interval,
  IntervalResponse,
  PostAddressPayload,
  PutAddressPayload
} from './types';

export function formatAddress(response: AddressResponse) {
  const { apartment, entrance, entrance_code, floor, address } = response;
  const entranceLabel = CURRENT_REGION_ID === 618 ? ', парадная ' : ', подъезд ';
  const result =
    `${address}` +
    `${apartment ? ', кв.' + apartment : ''}` +
    `${floor ? ', этаж ' + floor : ''}` +
    `${entrance ? entranceLabel + entrance : ''}` +
    `${entrance_code ? ', домофон ' + entrance_code : ''}`;

  return result.trim();
}

export const serializeDeliveryAddress = (response: AddressResponse): Address => {
  return {
    address: response.address,
    addressCity: response.address_city,
    addressCountry: response.address_country,
    addressHouse: response.address_house,
    addressStreet: response.address_street,
    apartment: response.apartment,
    comment: response.comment,
    entrance: response.entrance,
    fullAddress: formatAddress(response),
    entranceCode: response.entrance_code,
    floor: response.floor,
    id: response.id,
    fastDelivery: response.fast_delivery,
    lastDeliveryDate: response.last_delivery_date,
    latitude: response.latitude,
    longitude: response.longitude,
    name: response.name,
    regionId: response.region_id,
    zoneId: response.zone_id
  };
};

export const serializeDeliveryAddresses = (
  response: AddressesResponse,
  regionId: number
): Address[] => {
  return response.data
    .filter((a) => a.region_id === regionId)
    .map((adrs) => serializeDeliveryAddress(adrs));
};

export function deserializeDeliveryAddress({
  floor,
  comment,
  entrance,
  latitude,
  longitude,
  regionId: region_id,
  apartment,
  id,
  fullAddress: address,
  addressCity: address_city,
  addressCountry: address_country,
  addressStreet: address_street,
  addressHouse: address_house,
  entranceCode: entrance_code,
  lastDeliveryDate: last_delivery_date,
  zoneId: zone_id,
}: Address): PostAddressPayload | PutAddressPayload {
  return {
    last_delivery_date,
    address_country,
    address_street,
    address_house,
    region_id,
    entrance_code,
    address_city,
    apartment,
    longitude,
    latitude,
    entrance,
    address,
    zone_id,
    comment,
    floor,
    id
  };
}

function serializeDeliveryInterval(interval: IntervalResponse) {
  const formatPadStart = (number: number) => {
    return number.toString().padStart(2, '0');
  };
  const { price, express, free_from, from, to } = interval;
  const offset = 3; // +3 часа вшитых в интервал
  const startDate = new Date(from);
  const endDate = new Date(to);
  const start = startDate.getTime();
  const end = endDate.getTime();
  const id = nanoid();
  const description = [
    `${formatPadStart(startDate.getUTCHours() + offset)}:${formatPadStart(startDate.getMinutes())}`,
    `${formatPadStart(endDate.getUTCHours() + offset)}:${formatPadStart(endDate.getMinutes())}`
  ].join(' - ');

  return {
    id,
    end,
    start,
    price,
    description,
    freeFrom: free_from,
    express,
    from,
    to
  };
}

export function serializeDeliveryIntervals(res: DeliveryIntervalsResponse) {
  const intervals: Interval[] = [];
  const logisticSchemeId = res.logistic_scheme_id || '';

  const clones = res.intervals.filter((interval) => {
    return res.intervals.some((interval2) => {
      return (
        interval.from === interval2.from &&
        interval2.to === interval.to &&
        interval.price !== interval2.price
      );
    });
  });
  const maxPrice: IntervalResponse = clones?.find(
    (interv) => interv.price === Math.max(...clones.map((inter) => inter.price))
  );

  if (maxPrice) {
    maxPrice.excluded = true;
  }

  const filteredIntervals = res.intervals.filter((interval) => !interval.excluded);

  filteredIntervals.forEach((interval) => {
    const deliveryInterval = serializeDeliveryInterval(interval);

    intervals.push(deliveryInterval);
  });

  const deliveryIntervals = intervals.filter((interval, index) => {
    return index === intervals.findIndex((obj) => obj.id === interval.id);
  });

  return {
    deliveryIntervals,
    logisticSchemeId
  };
}

function serializeDeliveryDate(timestamp: number) {
  const id = nanoid();
  const date = new Date(timestamp);
  const month = dateTimeHelper.formatDatetimeToMonthShortName(date);
  const title = `${date.getDate()} ${month}`;
  const intervals: Interval[] = [];
  const description = dateTimeHelper.isToday(date)
    ? DATE_DESCRIPTIONS.today
    : dateTimeHelper.isTomorrow(date)
      ? DATE_DESCRIPTIONS.tomorrow
      : dateTimeHelper.formatDatetimeToDayOfWeekName(date);

  return {
    description,
    intervals,
    timestamp,
    title,
    id
  };
}

const getUniqueDates = (deliveryIntervals: Interval[]) => {
  const datesOnly = deliveryIntervals.map(({ start }) => {
    const date = new Date(start).toISOString();

    return new Date(date.slice(0, -14)).toISOString();
  });
  return [...new Set(datesOnly)];
};

export function serializeDeliveryDates(deliveryIntervals: Interval[]) {
  const dates = deliveryIntervals.map(({ start }) => new Date(start).toISOString().slice(0, -14));
  const availableDays = [...new Set(dates)];
  const deliveryDates = [] as DeliveryDate[];
  const uniqueDates = getUniqueDates(deliveryIntervals);

  uniqueDates.forEach((date) => {
    const timestamp = Date.parse(date);
    const deliveryDate = serializeDeliveryDate(timestamp);
    const deliveryDay = new Date(date).toISOString().slice(0, -14);

    if (availableDays.includes(deliveryDay)) {
      deliveryDate.intervals = deliveryIntervals.filter(({ start }) => {
        const intervalDate = new Date(start).toISOString().slice(0, -14);

        return intervalDate === deliveryDay;
      });
    }
    deliveryDates.push(deliveryDate);
  });

  return {
    deliveryDates
  };
}
