import React, { useEffect, useMemo, useState } from 'react';
import { useInstallmentContext } from '../InstallmentContext';
import { PaymentTabSerialized } from '../types';
import { Title } from '@npm-registry/eapteka-ui';
import { Bubbles } from '../Bubbles';

export const BNPLTabs = () => {
  const { content } = useInstallmentContext();
  const tabs = content?.tabs.filter(
    (tab) => tab.showTabForSum
  );
  const [activeTab, setActiveTab] = useState(
    tabs ? tabs[0] : undefined
  );
  const title = useMemo(() => {
    if (activeTab) {
      return activeTab.titleSum ? (
        <>
          {activeTab.titleSum}
          {' ₽ '}
          <small className="InstallmentBNPL_Subtitle">
            {activeTab.title}
          </small>
        </>
      ) : (
        activeTab.title
      );
    }
    const titleSum = content?.tabs[0].titleSum;
    const titleText = content?.tabs[0].title;
    return titleSum ? (
      <>
        {titleSum}
        {' ₽ '}
        <small className="InstallmentBNPL_Subtitle">
          {titleText}
        </small>
      </>
    ) : (
      titleText
    );
  }, [activeTab, content]);
  const paymentDates = useMemo(() => {
    return activeTab?.payments || content?.tabs[0].payments;
  }, [activeTab, content]);
  const bubblesCount = useMemo(() => {
    return (
      activeTab?.bubblesCount ||
      content?.tabs[0].bubblesCount
    );
  }, [activeTab, content]);
  const description = useMemo(() => {
    return (
      activeTab?.descriptionCart ||
      content?.tabs[0].descriptionCart ||
      content?.description
    );
  }, [activeTab, content]);

  const onClick = (tab: PaymentTabSerialized) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (content) {
      setActiveTab(
        content.tabs.find((tab) => tab.showTabForSum)
      );
    }
  }, [content]);

  if (!content) return null;

  return (
    <>
      <p className="InstallmentBNPL_Description">
        {description}
      </p>
      {tabs && tabs.length > 0 && (
        <div className="InstallmentBNPL_Chips">
          {tabs.map((tab) => {
            const isChecked = activeTab?.name === tab.name;
            return (
              // eslint-disable-next-line jsx-a11y/role-supports-aria-props
              <button
                key={tab.name}
                type="button"
                onClick={() => onClick(tab)}
                aria-checked={isChecked}
                className="InstallmentBNPL_Chip"
              >
                {tab.name}
              </button>
            );
          })}
        </div>
      )}
      <Title tag="h3" weight="bold">
        {title}
      </Title>
      <Bubbles
        paymentDates={paymentDates}
        bubblesCount={bubblesCount}
      />
    </>
  );
};
