import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { pluralizeWord } from '@npm-registry/helpers/pluralize';
import { LogoPrime, Icon, useViewSize } from '@npm-registry/eapteka-ui';
import { selectUser, selectUserIsCorporate } from '../../../services/redux/store/user/selectors';
import { selectWebFeatures } from '../../../services/redux/store/handbook/selectors';
import { formatCurrency } from '../../../tools/formatters/formatCurrency/formatCurrency';
import { CheckoutSection } from '../../elements/CheckoutSection';
import { CheckoutOrderSummaryItem } from './Item';
import { CheckoutTooltip } from '../../elements/CheckoutTooltip';
import { selectReceivingMethod } from '../../../services/redux/store/receiving/selectors';
import {
  selectCartBonusProgram,
  selectCartOffersData,
  selectCartSummary,
  selectCartTotalPriceComponents
} from '../../../services/redux/store/cart/selectors';
import {
  selectCurrentPayment,
  selectPaymentLoyaltyBonuses,
  selectSberSpasibo
} from '../../../services/redux/store/payment/selectors';
import './CheckoutOrderSummary.scss';
import { selectCurrentInterval } from '../../../services/redux/store/delivery/selectors';

export const CheckoutOrderSummary = () => {
  const tooltipRef = useRef();
  const { isMobile } = useViewSize();
  const currentDeliveryInterval = useSelector(selectCurrentInterval);
  const { discount, totalPrice } = useSelector(selectCartSummary);
  const { availableOffersPrice, fee } = useSelector(selectCartTotalPriceComponents);
  const { earn, withdraw } = useSelector(selectCartBonusProgram);
  const { availableOffersCount } = useSelector(selectCartOffersData);
  const user = useSelector(selectUser);
  const webFeatures = useSelector(selectWebFeatures);
  const isSberPrimeFreeDeliveryDisabled = user.sberprime?.delivery.freeDisabled;
  const isSberPrimeActive = user.sberprime?.isActive;
  const { isSberBonusesEnabled } = webFeatures;
  const isCorporate = useSelector(selectUserIsCorporate);
  const {
    isEnabled: isSberSpasiboEnabled,
    withdraw: sberSpasiboBonuses,
    earn: sberSpasiboEarn
  } = useSelector(selectSberSpasibo);
  const { isEnabled: isBonusEnabled } = useSelector(selectPaymentLoyaltyBonuses);
  const { isDeliverySelected } = useSelector(selectReceivingMethod);
  const isIntervalSelected = !!currentDeliveryInterval
  const currentPaymentMethod = useSelector(selectCurrentPayment);
  const deliveryPrice = isDeliverySelected ? currentDeliveryInterval?.price : 0;
  const isSberBonusesEarnEnabled =
    isSberBonusesEnabled &&
    sberSpasiboEarn > 0 &&
    !isCorporate &&
    !['sbp', 'cash', 'AGENT', 'pickup_payment'].includes(currentPaymentMethod?.code);

  const feeFormatted = formatCurrency(fee, true);
  const isFeeVisible = fee > 0;

  const discountText = formatCurrency(discount, true);
  const offersPrice = formatCurrency(availableOffersPrice + discount, true);
  const totalPriceWithCurrency = formatCurrency(totalPrice, true);
  const sberSpasiboBonusesFormatted = formatCurrency(sberSpasiboBonuses);
  const sberSpasibEarnFormatted = formatCurrency(sberSpasiboEarn);
  const loyaltyBonuses = formatCurrency(earn);

  const isEarningAvailable = !isCorporate && earn > 0;
  const availableOffersLabel = `${availableOffersCount} ${pluralizeWord(availableOffersCount, [
    'товар',
    'товара',
    'товаров'
  ])}`;

  const isSberPrimeDelivery = isSberPrimeActive && !isSberPrimeFreeDeliveryDisabled && !isCorporate;

  const deliveryValue = useMemo(() => {
    if (deliveryPrice < 1) {
      return <span className="CheckoutOrderSummary-accentValue">Бесплатно</span>;
    }

    return formatCurrency(deliveryPrice, true);
  }, [deliveryPrice]);

  useEffect(() => {
    const isFeeAknowledged = !!localStorage.getItem('checkout-fee-aknowledged');

    if (isFeeVisible && !isFeeAknowledged) {
      tooltipRef.current.focus();
      localStorage.setItem('checkout-fee-aknowledged', true);
    }
  }, [isFeeVisible]);

  return (
    <CheckoutSection className="CheckoutSection--Summary">
      <CheckoutOrderSummaryItem label={availableOffersLabel} value={offersPrice} />
      {isDeliverySelected && isIntervalSelected && (
        <CheckoutOrderSummaryItem
          label={
            <>
              Доставка{' '}
              {isSberPrimeDelivery && <LogoPrime alt="СберПрайм" size={57} kind="compactFill" />}
            </>
          }
          value={deliveryValue}
        />
      )}
      {discount > 0 && (
        <CheckoutOrderSummaryItem label="Скидка" value={<span>-{discountText}</span>} />
      )}
      {isSberSpasiboEnabled && sberSpasiboBonuses > 0 && (
        <CheckoutOrderSummaryItem
          label="СберСпасибо"
          value={
            <>
              <span>-{sberSpasiboBonusesFormatted}</span>
              <Icon className="CheckoutOrderSummary_BonusIcon SberSpasibo " name="sberSpasibo" />
            </>
          }
        />
      )}
      {isFeeVisible && (
        <CheckoutOrderSummaryItem
          label={
            <>
              Сервисный сбор
              <CheckoutTooltip
                ref={tooltipRef}
                side={isMobile ? 'right' : 'left'}
                message="Сервисный сбор за комплектацию заказа"
              />
            </>
          }
          value={feeFormatted}
        />
      )}
      {isBonusEnabled && (
        <CheckoutOrderSummaryItem
          label="Спишется бонусами"
          value={
            <>
              <span>-{withdraw}</span>
              <Icon
                className="CheckoutOrderSummary_BonusIcon"
                name="eaptekaIcon"
                gradientName="callicarpa"
              />
            </>
          }
        />
      )}
      <CheckoutOrderSummaryItem
        className="CheckoutOrderSummary_Row-footer"
        classNameLabel="CheckoutOrderSummary_Label-black"
        classNameValue="CheckoutOrderSummary_Label-black"
        label="Итого"
        value={totalPriceWithCurrency}
      />
      {isEarningAvailable && (
        <CheckoutOrderSummaryItem
          label="Начислится бонусов"
          value={
            <>
              <span>+{loyaltyBonuses}</span>
              <Icon
                className="CheckoutOrderSummary_BonusIcon"
                name="eaptekaIcon"
                gradientName="callicarpa"
              />
            </>
          }
        />
      )}
      {isSberBonusesEarnEnabled && (
        <CheckoutOrderSummaryItem
          label="Начислится СберСпасибо"
          value={
            <>
              <span>до +{sberSpasibEarnFormatted}</span>
              <Icon
                className="CheckoutOrderSummary_BonusIcon SberSpasibo"
                name="sberSpasibo"
                gradientName="callicarpa"
              />
            </>
          }
        />
      )}
    </CheckoutSection>
  );
};
