import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { paymentActions } from './slice';
import { postPaymentRequest } from '../../../api/endpoints/payment/postPaymentRequest';
import { PaymentDeliveryRequest, PaymentPickupRequest, SberspasiboPayload } from './types';
import { serializeLoyalty, serializePayments } from './serializers';
import { TRootState } from '../rootReducer';
import {
  getLoyaltyRequest,
  getSberspasiboEarnRequest,
  postSberspasiboRequest
} from '../../../api/endpoints/loyalty/getLoyaltyRequest';

const paymentsCache = new Map();
const loyaltyCache = new Map();
const sberspasiboCache = new Map();
const sberspasiboEarnCache = new Map();

export const getPayments = createAsyncThunk(
  STORE_NAMES.PAYMENT + '_action',
  async (requestData: PaymentDeliveryRequest | PaymentPickupRequest, thunkAPI) => {
    thunkAPI.dispatch(paymentActions.setPaymentStatus(FETCH_STATUS.PENDING));
    const key = JSON.stringify(requestData);

    if (paymentsCache.has(key)) {
      thunkAPI.dispatch(paymentActions.setPaymentTypes(paymentsCache.get(key)));;
      thunkAPI.dispatch(paymentActions.setPaymentStatus(FETCH_STATUS.SUCCESS));
      return;
    }

    await postPaymentRequest(requestData)
      .then((response) => {
        const { handbook } = thunkAPI.getState() as TRootState;
        const { isSberpayEnabled } = handbook.features.web;
        const payments = serializePayments(response, isSberpayEnabled);

        paymentsCache.set(key, payments);
        thunkAPI.dispatch(paymentActions.setPaymentTypes(payments));
        thunkAPI.dispatch(paymentActions.setPaymentStatus(FETCH_STATUS.SUCCESS));
      })
      .catch((err) => {
        paymentsCache.clear();
        console.error('Ошибка получения данных о способах оплаты: ', err);
        thunkAPI.dispatch(paymentActions.setPaymentStatus(FETCH_STATUS.ERROR));
      });
  }
);

export const getLoyalty = createAsyncThunk(
  STORE_NAMES.PAYMENT + '_loyalty',
  async (bindingId: string | undefined, thunkAPI) => {
    if (loyaltyCache.has(bindingId)) {
      return thunkAPI.dispatch(paymentActions.setSberLoyalty(loyaltyCache.get(bindingId)));
    }

    await getLoyaltyRequest(bindingId).then((response) => {
      const result = serializeLoyalty(response);
      loyaltyCache.set(bindingId, result);
      thunkAPI.dispatch(paymentActions.setSberLoyalty(result));
    });
  }
);

export const getSberspasibo = createAsyncThunk(
  STORE_NAMES.PAYMENT + '_sberspasibo',
  async (payload: SberspasiboPayload, thunkAPI) => {
    const key = JSON.stringify(payload);
    thunkAPI.dispatch(paymentActions.setSberspasiboStatus('PENDING'));

    if (sberspasiboCache.has(key)) {
      thunkAPI.dispatch(paymentActions.setSberspasibo(sberspasiboCache.get(key)));
      thunkAPI.dispatch(paymentActions.setSberspasiboStatus('SUCCESS'));
      return;
    }

    await postSberspasiboRequest(payload)
      .then((response) => {
        sberspasiboCache.set(key, response);
        thunkAPI.dispatch(paymentActions.setSberspasibo(response));
        thunkAPI.dispatch(paymentActions.setSberspasiboStatus('SUCCESS'));
      })
      .catch((err) => {
        console.error('Ошибка получения данных программы лояльности СберСпасибо: ', err)
        thunkAPI.dispatch(paymentActions.setSberspasiboStatus('ERROR'));
      });
  }
);


export const getSberspasiboEarn = createAsyncThunk(
  STORE_NAMES.PAYMENT + '_sberspasibo/earn',
  async (payload: number, thunkAPI) => {
    const key = String(payload);
    if (sberspasiboEarnCache.has(key)) {
      thunkAPI.dispatch(paymentActions.setSberspasiboEarn(sberspasiboEarnCache.get(key)));
      return;
    } else {
      await getSberspasiboEarnRequest(payload).then((response) => {
        sberspasiboEarnCache.set(key, response.sberspasibo_earn);
        thunkAPI.dispatch(paymentActions.setSberspasiboEarn(response.sberspasibo_earn));
      });
    }
  }
);
