import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { HandbookSber, HandbookStore, HandbookWeb } from './types';
import { FetchStatus } from '../types';

const initialState = {
  status: FETCH_STATUS.IDLE,
  features: {
    web: null,
    sber: null,
  },
} as HandbookStore;

export const handbookSlice = createSlice({
  name: STORE_NAMES.HANDBOOK,
  initialState,
  reducers: {
    setHandbook: (
      state,
      { payload }: PayloadAction<{ web: HandbookWeb; sber: HandbookSber }>
    ) => {
      state.features = payload;
    },
    setHandbookStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.status = payload;
    },
  },
});

const handbookReducer = handbookSlice.reducer;
const handbookActions = handbookSlice.actions;

export { handbookReducer, handbookActions };
