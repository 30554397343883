import React, {
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { checkoutDispatchers } from '../../../services/dispatchers';
import { CheckoutHeader } from '../../layouts/CheckoutHeader';
import { ScreenModal } from '../../core/ScreenModal/Screen';
import { CheckoutAddressSearch } from './../CheckoutAddressSearch';
import { CheckoutPickupMap } from './Map';
import { CheckoutPickupList } from './List';
import { Balloon } from './Map/Balloon';
import { clearFilters } from '../../../services/redux/store/pharmacies/slice';
import { selectUIPickup } from '../../../services/redux/store/ui/selectors';
import { useReceivingMethodPickup } from '../CheckoutReceivingMethods/Pickup/hooks/useReceivingMethodPickup';
import './CheckoutPickup.scss';

export const CheckoutPickup = () => {
  const dispatch = useDispatch();
  const { isMapView } = useSelector(selectUIPickup);
  const { onPickupScreenClose } = useReceivingMethodPickup();

  const onAddressSuggestionSelect = useCallback(
    (suggestion) => {
      checkoutDispatchers.pickupAddressSuggestSelect.dispatch(
        {
          suggestionLabel: suggestion.label
        }
      );
    },
    []
  );

  const rootClassNames = useMemo(() => {
    return clsx('CheckoutPickup_Root', {
      CheckoutPickup_Root__mapViewType: isMapView
    });
  }, [isMapView]);

  useEffect(() => {
    checkoutDispatchers.pickupFiltersShow.dispatch();

    return () => {
      dispatch(clearFilters());
    };
  }, [dispatch]);

  return (
    <ScreenModal className={rootClassNames}>
      <CheckoutHeader
        title="Выбрать аптеку"
        onClickUndo={onPickupScreenClose}
        className="CheckoutPickup_HeaderTitle"
      >
        <CheckoutAddressSearch
          onAddressSelect={onAddressSuggestionSelect}
        />
      </CheckoutHeader>
      <div className="CheckoutPickup_ViewType">
        <Balloon />
        {isMapView ? (
          <CheckoutPickupMap />
        ) : (
          <CheckoutPickupList />
        )}
      </div>
    </ScreenModal>
  );
};
