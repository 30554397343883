/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { FC, forwardRef } from 'react';
import { Icon } from '@npm-registry/eapteka-ui';
import { CheckoutCustomTooltip } from './types';
import { TOOLTIP_SIDE_CLASS_MAP } from './consts';
import './CheckoutTooltip.scss';

export const CheckoutTooltip: FC<CheckoutCustomTooltip> = forwardRef(({
  message,
  iconName = 'infoRound',
  side = 'left',
}, ref) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <span className="InfoContainer" tabIndex={0} ref={ref}>
    <Icon name={iconName} className="InfoIcon" />
    <b
      className={
        'InfoDescription ' + TOOLTIP_SIDE_CLASS_MAP[side]
      }
    >
      {message}
    </b>
  </span>
));
