import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { pluralizeWord } from '@npm-registry/helpers/pluralize';
import { CheckoutCollapse } from '../../elements/CheckoutCollapse';
import { CheckoutSectionHeader } from '../../elements/CheckoutSectionHeader';
import { CheckoutSection } from '../../elements/CheckoutSection';
import { selectCartOffersData } from '../../../services/redux/store/cart/selectors';
import { checkoutDispatchers } from '../../../services/dispatchers';
import { OrderDrawer } from '../../core/OrderDrawer';

export const CheckoutOffersList = () => {
  const [isOpen, setOpen] = useState(false);
  const { availableOffersCount, unavailableOffersCount, totalOffersCount } = useSelector(selectCartOffersData);
  const subtitle = useMemo(() => {
    const hasUnavailable = unavailableOffersCount > 0;
    const offer = pluralizeWord(availableOffersCount, [
      'товар',
      hasUnavailable ? 'товаров' : 'товара',
      'товаров'
    ]);

    return hasUnavailable
      ? `${availableOffersCount} из ${totalOffersCount} ${offer}`
      : `${totalOffersCount} ${offer}`;
  }, [
    unavailableOffersCount,
    availableOffersCount,
    totalOffersCount
  ]);

  const handleOpening = (e) => {
    if (['Space', 'Enter'].includes(e.code)) {
      setOpen(!isOpen);
    }
  };

  useEffect(() => {
    const toggleList =
      checkoutDispatchers.toggleProductList.subscribe(
        () => {
          setOpen(true);
          setTimeout(() => {
            window.scrollTo({
              top: window.visualViewport.height,
              behavior: 'smooth'
            });
          }, 300);
        }
      );
    return () => {
      toggleList();
    };
  }, []);

  return (
    <CheckoutSection>
      <CheckoutSectionHeader
        onClick={() => setOpen(!isOpen)}
        onKeyDown={handleOpening}
        image="cart"
        subtitle={subtitle}
        isLightTitle
        isCollapsed={isOpen}
      >
        Состав
      </CheckoutSectionHeader>
      <CheckoutCollapse isOpen={isOpen}>
        <OrderDrawer />
      </CheckoutCollapse>
    </CheckoutSection>
  );
};
