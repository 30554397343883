import { useMemo } from 'react';

function sortNumeric(a, b) {
  return a > b ? 1 : a < b ? -1 : 0;
}

function sortByDistance(a, b) {
  return sortNumeric(a.distance, b.distance);
}

function sortByPartner(a, b) {
  return sortNumeric(+a.isPartner, +b.isPartner);
}

function sortByPickupDate(a, b) {
  const aPickupDate = a.pickupDate;
  const bPickupDate = b.pickupDate;

  return sortNumeric(aPickupDate, bPickupDate);
}

function sortByUnavailableOffers(a, b) {
  const aLength = a.unavailableOffers
    ? a.unavailableOffers.length
    : 0;
  const bLength = b.unavailableOffers
    ? b.unavailableOffers.length
    : 0;

  return sortNumeric(aLength, bLength);
}

function sortByPickupUnavailable(a, b) {
  return !a.isPickupAvailable && b.isPickupAvailable
    ? 1
    : a.isPickupAvailable && !b.isPickupAvailable
      ? -1
      : 0;
}

export function sortPickupPoints(pickupPoints) {
  return pickupPoints
    .slice()
    .sort(sortByPickupDate)
    .sort(sortByUnavailableOffers)
    .sort(sortByPartner)
    .sort(sortByDistance)
    .sort(sortByPickupUnavailable);
}

export function usePickupPointsSort(pickupPoints) {
  return useMemo(
    () => sortPickupPoints(pickupPoints),
    [pickupPoints]
  );
}
