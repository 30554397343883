import React, { FC } from 'react';
import { CheckoutPaymentMethodSelectItem } from '../Item';
import type { Payment } from '../../../../../services/redux/store/payment/types';

interface PaymentList {
  payments: Payment[];
  title?: string;
  currentPaymentId?: string | null;
  onSelect: () => void;
  onClose: () => void;
}

export const CheckoutPaymentMethodSelectMethods: FC<
  PaymentList
> = ({
  payments,
  title,
  currentPaymentId,
  onSelect,
}) => {
  if (payments.length < 1) return null;

  return (
    <>
      <div className="CheckoutPaymentMethodSelect_Divider">
        {title}
      </div>
      <div>
        {payments.map((payment) => {
          const checked = currentPaymentId === payment.uuid;

          if (payment.bindingId && !payment.isAvailable) return;

          return (
            <CheckoutPaymentMethodSelectItem
              key={payment.uuid}
              payment={payment}
              onSelect={onSelect}
              checked={checked}
            />
          );
        })}
      </div>
    </>
  );
};
