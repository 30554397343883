import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { getPharmacies } from './pharmacies/actions';
import {
  cartActions,
  setAvailableOffersCount,
  setAvailablePositionsCount,
  setCartTotalPrice,
  setSummaryAvailableOffersPrice,
  setSummaryBonusWithdraw,
  setSummaryFee,
  setSummarySpasiboWithdraw,
  setSummaryUnavailablePrice,
  setUnavailableOffersCount,
  setUnavailablePositionsCount
} from './cart/slice';
import { getDeliveryERecipe } from '../../api/endpoints/cart/getCartRequest';
import { checkoutDispatchers } from '../../dispatchers';
import { userActions } from './user/slice';
import { pharmaciesActions } from './pharmacies/slice';
import { getLoyalty, getPayments, getSberspasibo, getSberspasiboEarn } from './payment/actions';
import { PaymentPickupRequest, SberspasiboPayload } from './payment/types';
import {
  setReceivingMethodDelivery,
  setReceivingMethodPickup
} from './receiving/slice';
import {
  paymentActions,
  setBonusToggled,
  setCurrentPayment,
  setSberLoyalty,
  setSberspasiboToggled
} from './payment/slice';
import { selectWebFeatures } from './handbook/selectors';
import {
  selectCurrentPayment,
  selectPaymentLoyaltyBonuses,
  selectSberSpasibo
} from './payment/selectors';
import {
  selectCartData,
  selectCartItems,
  selectCartOffersData,
  selectCartSummary
} from './cart/selectors';
import { selectRegion, selectRegionFeatures } from './region/selectors';
import {
  getDeliveryDateStart,
  getDeliveryPaymentsPayload,
  getMostRecentDeliveryAddress,
  isBonusAvailable,
  isBonusVisible
} from './helpers';
import { getCartWithOffers } from './cart/actions';
import { TRootState } from './rootReducer';
import { selectCurrentPharmacy } from './pharmacies/selectors';
import { selectReceivingMethod } from './receiving/selectors';
import { regionActions } from './region/slice';
import { getAddresses } from './delivery/actions';
import {
  deleteAddress,
  setCurrentAddress,
  setCurrentDate,
  setCurrentInterval,
  setDeliveryDates,
  setIntervals,
  setIntervalStatus,
  setLogisticsSchemeId
} from './delivery/slice';
import {
  deleteUserAddressRequest,
  getAddressZone,
  getDeliveryIntervalsRequest
} from '../../api/endpoints/delivery/getDeliveryRequest';
import { selectCurrentAddress, selectCurrentInterval } from './delivery/selectors';
import { setIsDeliveryAddressModalOpen, setIsDeliveryIntervalModalOpen } from './ui/slice';
import { serializeDeliveryDates, serializeDeliveryIntervals } from './delivery/serializers';
import { DeliveryIntervalsPayload } from './delivery/types';
import { createNotification } from '../../notifications';

export const listenerMiddleware = createListenerMiddleware();

// Получение сохраненных адресов для доставки и подстановка адреса с недавней доставкой
listenerMiddleware.startListening({
  actionCreator: regionActions.setRegion,
  effect: async (action, listener) => {
    if (action.payload.isDeliveryAvailable) {
      listener.dispatch(getAddresses(action.payload.id)).then(() => {
        const store = listener.getState() as TRootState;
        const mostRecentDeliveryAddress = getMostRecentDeliveryAddress(store.delivery.addresses);

        if (mostRecentDeliveryAddress?.zoneId) {
          listener.dispatch(setCurrentAddress(mostRecentDeliveryAddress));
        }
      });
    }
    listener.cancel();
  }
});

// Проверка на Электронный Рецепт, получение списка аптек
listenerMiddleware.startListening({
  actionCreator: getCartWithOffers.fulfilled,
  effect: async (_, listener) => {
    const { user, cart } = listener.getState() as TRootState;
    if (user.user?.acceptedCheckErecipe && cart.cart.data.hasItemWithRecipe) {
      const erecipe = await getDeliveryERecipe();
      listener.dispatch(cartActions.setCartERecipe(erecipe));

      if (erecipe.result) {
        createNotification({ title: 'Теперь вы можете оформить доставку по электронному рецепту' });
      }
    }
    if (cart.cart.data.hasItemPreorder) {
      listener.dispatch(userActions.setUserCorporate(false));
    }
    listener.dispatch(
      paymentActions.setBonuses({
        canWithdraw: cart.cart.bonusProgram.canWithdraw,
        earn: cart.cart.bonusProgram.earn,
        withdraw: cart.cart.bonusProgram.withdraw
      })
    );
    listener.dispatch(getPharmacies()); // API CALL: /current & /pickup_points
    listener.cancel();
  }
});

// Определение и подстановка избранной аптеки
listenerMiddleware.startListening({
  actionCreator: getPharmacies.fulfilled,
  effect: (_, listener) => {
    const { user, pharmacies } = listener.getState() as TRootState;
    const { favoriteStore } = user.user;
    if (favoriteStore) {
      const pharmacy = pharmacies.data.find((item) => item.id === favoriteStore);
      listener.dispatch(pharmaciesActions.setSelectedPharmacy(pharmacy));
      listener.dispatch(setReceivingMethodPickup());
    }
    listener.cancel();
  }
});

// Перерасчет стоимости товаров в пункте самовывоза + сервисный сбор
listenerMiddleware.startListening({
  actionCreator: setReceivingMethodPickup,
  effect: (_, listener) => {
    const store = listener.getState() as TRootState;
    const selectedPharmacy = selectCurrentPharmacy(store);
    if (selectedPharmacy) {
      const { isEnabled: isSberSpasiboEnabled, withdraw: spasiboBonuses } =
        selectSberSpasibo(store);
      const { isEnabled: isBonusEnabled, withdraw: loyaltyBonuses } =
        selectPaymentLoyaltyBonuses(store);
      const spasibo = isSberSpasiboEnabled ? spasiboBonuses : 0;
      const bonuses = isBonusEnabled ? loyaltyBonuses : 0;
      const fee = selectedPharmacy.chargeServiceFee;
      const offers = selectCartItems(store);
      const availableOffers = offers.filter((offer) =>
        selectedPharmacy.availableOffers.includes(offer.id as never)
      );
      const unavailableOffers = offers.filter((offer) =>
        selectedPharmacy.unavailableOffers.includes(offer.id as never)
      );
      const availableOffersCount = availableOffers.reduce(
        (prev, { amount }) => (prev += amount),
        0
      );
      const unavailableOffersCount = unavailableOffers.reduce(
        (prev, { amount }) => (prev += amount),
        0
      );
      const avaialbleOffersPrice = availableOffers.reduce(
        (prev, { price, amount }) => (prev += price * amount),
        0
      );
      const unavailableOffersPrice = unavailableOffers.reduce(
        (prev, { price, amount }) => (prev += price * amount),
        0
      );
      const totalPrice = avaialbleOffersPrice + fee - spasibo - bonuses;
      const data: PaymentPickupRequest = {
        pickup: {
          pickup_date: selectedPharmacy.clientPickupDate,
          pickup_point_id: selectedPharmacy.id
        },
        total_sum: totalPrice
      };

      listener.dispatch(setAvailablePositionsCount(availableOffers.length));
      listener.dispatch(setUnavailablePositionsCount(unavailableOffers.length));
      listener.dispatch(setAvailableOffersCount(availableOffersCount));
      listener.dispatch(setUnavailableOffersCount(unavailableOffersCount));
      listener.dispatch(setSummarySpasiboWithdraw(spasibo));
      listener.dispatch(setSummaryBonusWithdraw(bonuses));
      listener.dispatch(setSummaryAvailableOffersPrice(avaialbleOffersPrice));
      listener.dispatch(setSummaryUnavailablePrice(unavailableOffersPrice));
      listener.dispatch(setSummaryFee(selectedPharmacy.chargeServiceFee));
      listener.dispatch(setCartTotalPrice(totalPrice));
      if (!isSberSpasiboEnabled && !isBonusEnabled) {
        listener.dispatch(getPayments(data));
      }
    }
  }
});

// Перерасчет стоимости товаров и сервисного сбора в доставке
listenerMiddleware.startListening({
  actionCreator: setReceivingMethodDelivery,
  effect: (_, listener) => {
    const store = listener.getState() as TRootState;
    const { serviceFee } = selectRegion(store);
    const { availableOffersPrice, unavailableOffersPrice } = selectCartOffersData(store);
    const interval = selectCurrentInterval(store);
    const address = selectCurrentAddress(store);
    const { isEnabled: isSberSpasiboEnabled, withdraw: spasiboBonuses } = selectSberSpasibo(store);
    const { isEnabled: isBonusEnabled, withdraw: loyaltyBonuses } =
      selectPaymentLoyaltyBonuses(store);
    const spasibo = isSberSpasiboEnabled ? spasiboBonuses : 0;
    const bonuses = isBonusEnabled ? loyaltyBonuses : 0;
    const deliveryPrice = interval ? interval.price : 0;
    const totalPrice = availableOffersPrice + serviceFee + deliveryPrice - spasibo - bonuses;
    const isNewPrice = !isBonusEnabled && !isSberSpasiboEnabled;

    listener.dispatch(setSummarySpasiboWithdraw(spasibo));
    listener.dispatch(setSummaryBonusWithdraw(bonuses));
    listener.dispatch(setSummaryUnavailablePrice(unavailableOffersPrice));
    listener.dispatch(setSummaryAvailableOffersPrice(availableOffersPrice));
    listener.dispatch(setSummaryFee(serviceFee));
    listener.dispatch(setCartTotalPrice(totalPrice));
    if (isNewPrice && interval) {
      const data = getDeliveryPaymentsPayload(address, interval, totalPrice)
      listener.dispatch(getPayments(data));
    }
  }
});

// Получение баланса бонусов программы лояльности и СберСпасибо при выборе способа оплаты
listenerMiddleware.startListening({
  actionCreator: setCurrentPayment,
  effect: (action, listener) => {
    if (!action.payload) {
      listener.dispatch(paymentActions.setBonusToggled(false));
      listener.dispatch(paymentActions.setSberspasiboToggled(false));
      return;
    };

    const { region, cart, user, payment, receiving } = listener.getState() as TRootState;
    const { isBindingIdLoyaltyQueryEnabled } = selectWebFeatures(listener.getState() as TRootState);
    const bindingId = isBindingIdLoyaltyQueryEnabled ? action.payload.bindingId : '';
    const isLoyaltyBonusVisible = isBonusVisible(cart, user, region);
    const { isAvailable, description, level, title } = isBonusAvailable(
      cart,
      user,
      action.payload,
      payment.payment.paymentTypes,
      receiving.method.isPickupSelected
    );

    listener.dispatch(getLoyalty(bindingId));
    listener.dispatch(paymentActions.setBonusesVisbile(isLoyaltyBonusVisible));
    listener.dispatch(paymentActions.setBonusesAvailable(isAvailable));
    listener.dispatch(paymentActions.setBonusesText({ description, level, title }));
    if (!isAvailable) {
      listener.dispatch(paymentActions.setBonusToggled(false));
    }
  }
});

// Получение информации о доступности сберспасибо
listenerMiddleware.startListening({
  actionCreator: setSberLoyalty,
  effect: (action, listener) => {
    const { isNewGate } = selectRegionFeatures(listener.getState() as TRootState);
    const { code } = selectCurrentPayment(listener.getState() as TRootState);
    const { totalPrice } = selectCartSummary(listener.getState() as TRootState);

    if (!action.payload.message && isNewGate && code) {
      const requestData: SberspasiboPayload = {
        payment_code: code,
        price: totalPrice,
        sber_state: action.payload.state,
        sber_balance: action.payload.balance
      };

      listener.dispatch(getSberspasibo(requestData));
      listener.dispatch(getSberspasiboEarn(totalPrice));
    }
  }
});

// Перерасчёт корзины в зависимости от бонусов ПЛ/СберСпасибо/доставки
listenerMiddleware.startListening({
  matcher: isAnyOf(setSberspasiboToggled, setBonusToggled, setCurrentInterval),
  effect: (_, listener) => {
    const store = listener.getState() as TRootState;
    const { isDeliverySelected, isPickupSelected } = selectReceivingMethod(store);

    if (isDeliverySelected) {
      listener.dispatch(setReceivingMethodDelivery());
      return;
    }
    if (isPickupSelected) {
      listener.dispatch(setReceivingMethodPickup());
      return;
    }
  }
});

// Перерасчет начисления сберспасибо при изменении общей стоимости заказа
listenerMiddleware.startListening({
  actionCreator: setCartTotalPrice,
  effect: (action, listener) => {
    const { isNewGate } = selectRegionFeatures(listener.getState() as TRootState);
    const { message } = selectSberSpasibo(listener.getState() as TRootState);

    if (!message && isNewGate) {
      listener.dispatch(getSberspasiboEarn(action.payload));
    }
  }
});

// Удаление адреса для доставки из списка
listenerMiddleware.startListening({
  actionCreator: deleteAddress,
  effect: async (_, listener) => {
    const store = listener.getState() as TRootState;
    const selectedAddress = selectCurrentAddress(store);

    await deleteUserAddressRequest(selectedAddress.id);
    listener.dispatch(setCurrentAddress(null));
    listener.dispatch(setCurrentInterval(null));
    listener.dispatch(setIsDeliveryAddressModalOpen(false));
  }
});

// Проверка зоны доставки и доступности ЭР
listenerMiddleware.startListening({
  actionCreator: setCurrentAddress,
  effect: async (action, listener) => {
    if (!action.payload) return;
    const store = listener.getState() as TRootState;
    const { hasERecipes } = selectCartData(store);

    if (action.payload.latitude && !action.payload.zoneId) {
      const coordinates = [action.payload.latitude, action.payload.longitude];
      const { is_erecipie_delivery_allowed, zone } = await getAddressZone(coordinates);

      if (!zone) {
        createNotification({ title: 'Адрес вне зоны доставки' });
        return;
      }

      if (!is_erecipie_delivery_allowed && hasERecipes) {
        createNotification({ title: 'Доставка доступна для региона, где вы получали электронный рецепт' });
        return;
      }
    }
  }
});

// Подгрузка интервалов доставки
listenerMiddleware.startListening({
  actionCreator: setIsDeliveryIntervalModalOpen,
  effect: async (action, listener) => {
    const store = listener.getState() as TRootState;
    const currentInterval = selectCurrentInterval(store);

    if (action.payload && !currentInterval) {
      listener.dispatch(setIntervalStatus('PENDING'));
      const address = selectCurrentAddress(store);
      const offers = selectCartItems(store);
      const { totalPrice } = selectCartSummary(store);
      const date = getDeliveryDateStart(offers);

      const params: DeliveryIntervalsPayload = {
        date,
        latitude: address.latitude,
        longitude: address.longitude,
        totalSum: totalPrice
      };
      await getDeliveryIntervalsRequest(params)
        .then((response) => {
          const { deliveryIntervals, logisticSchemeId } = serializeDeliveryIntervals(response);
          if (deliveryIntervals.length < 1) {
            listener.dispatch(setIsDeliveryIntervalModalOpen(false));
            listener.dispatch(setIntervalStatus('ERROR'));
            checkoutDispatchers.deliveryIntervalsAreMissing.dispatch();
            return;
          }
          const { deliveryDates } = serializeDeliveryDates(deliveryIntervals);
          listener.dispatch(setIntervals(deliveryIntervals));
          listener.dispatch(setDeliveryDates(deliveryDates));
          listener.dispatch(setCurrentDate(deliveryDates[0]));
          listener.dispatch(setLogisticsSchemeId(logisticSchemeId));
          listener.dispatch(setIntervalStatus('SUCCESS'));
        })
        .catch(() => {
          listener.dispatch(setIntervalStatus('ERROR'));
          checkoutDispatchers.deliveryIntervalsAreMissing.dispatch();
        });
    }
  }
});
