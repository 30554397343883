import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutDeliveryInterval } from './Interval';
import { setCurrentInterval } from '../../../../../services/redux/store/delivery/slice';
import { selectCurrentDate, selectCurrentInterval } from '../../../../../services/redux/store/delivery/selectors';
import './CheckoutDeliveryIntervals.scss';

export const CheckoutDeliveryIntervals = () => {
  const dispatch = useDispatch();
  const currentInterval = useSelector(selectCurrentInterval);
  const { intervals } = useSelector(selectCurrentDate);

  const handleIntervalSelect = useCallback(
    (interval) => {
      dispatch(setCurrentInterval(interval));
      window.sessionStorage.setItem(
        'previouslySelectedAddressId',
        interval
      );
    },
    [dispatch]
  );

  return (
    <ul className="CheckoutReceivingMethodsDeliveryIntervals_TimesList">
      {intervals.map((interval) => {
        return (
          <li
            key={interval.id}
            className="CheckoutReceivingMethodsDeliveryIntervals_Time"
          >
            <CheckoutDeliveryInterval
              price={interval.price}
              description={interval.description}
              isFastDelivery={interval.express}
              isSelected={
                interval.id ===
                currentInterval?.id
              }
              onClick={() =>
                handleIntervalSelect(interval)
              }
            />
          </li>
        );
      })}
    </ul>
  );
};
