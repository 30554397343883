import React from 'react';
import cn from 'clsx';
import { useFreeDeliveryFrom } from '../../hooks/useFreeDeliveryFrom';
import { CheckoutOrderSummary } from '../CheckoutOrderSummary';
import { SubmitOrderButton } from '../CheckoutOrderSummary/SubmitOrderButton';
import { CheckoutPolicyAgreement } from '../CheckoutPolicyAgreement';
import { CheckoutFreeDeliveryPrice } from './FreeDeliveryPrice';
import './CheckoutSummary.scss';

export const CheckoutSummary = () => {
  const freeDeliveryFrom = useFreeDeliveryFrom();
  const isFreeDeliveryInformerRequired = freeDeliveryFrom > 0;

  return (
    <div className="CheckoutSummary">
      <CheckoutOrderSummary />
      <div
        className={cn('CheckoutSummaryButtonContainer', {
          'CheckoutSummaryButtonContainer--withDelivery':
            isFreeDeliveryInformerRequired
        })}
      >
        {isFreeDeliveryInformerRequired && (
          <CheckoutFreeDeliveryPrice
            freeDeliveryFrom={freeDeliveryFrom}
          />
        )}
        <SubmitOrderButton />
      </div>
      <CheckoutPolicyAgreement />
    </div>
  );
};
