/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
// @ts-ignore
import eAptekaLogo from './images/eaptekaLogo.svg';
import { CITY_DIRECTORY } from '../../../tools/const';

export const CheckoutSectionHeadDefaultLeft = () => (
  <a
    href={CITY_DIRECTORY}
    aria-label="Вернуться на главную"
  >
    <img src={eAptekaLogo} alt="EAPTEKA" />
  </a>
);
