import config from '../../../config';
import { apiService } from '../apiService';

const addAddressIdToUrl = (addressId, currentUrl) =>
  `${currentUrl}/${addressId}`;

export const requestUserCards = apiService.createGetMethod(
  config.apiUrl.getUserCards
);

export const requestUser = apiService.createGetMethod(
  config.apiUrl.getUser
);

// addresses
export const requestUserAddresses =
  apiService.createGetMethod(config.apiUrl.addresses);

export const requestUserAddressUpdate =
  apiService.createPostMethod(config.apiUrl.addresses, {
    mapRequest: (
      { addressId, addressProps },
      { currentUrl }
    ) => ({
      url: addAddressIdToUrl(addressId, currentUrl),
      body: JSON.stringify(addressProps)
    })
  });

export const requestUserAddressesDelete =
  apiService.createDeleteMethod(config.apiUrl.addresses, {
    mapRequest: ({ addressId }, { currentUrl }) => ({
      url: addAddressIdToUrl(addressId, currentUrl)
    })
  });

export const requestUserAddressesAdd =
  apiService.createPutMethod(config.apiUrl.addresses, {
    mapRequest: ({ addressProps }) => ({
      body: JSON.stringify(addressProps)
    })
  });
