import config from '../../../config';
import { getTokensFromStorage } from '../../../tools/getTokensFromStorage';
import { apiService } from '../apiService';

export const requestCartUpdate =
  apiService.createPostMethod(config.apiUrl.updateCart, {
    mapRequest: ({ params }) => ({
      headers: getTokensFromStorage('cartToken'),
      body: JSON.stringify(params)
    })
  });

export const requestCart = apiService.createGetMethod(
  config.apiUrl.getCart,
  {
    mapRequest: () => ({
      headers: {
        ...getTokensFromStorage('cartToken'),
        features: 'erecipe_new_api_flow'
      }
    })
  }
);

export const requestCartItemsChange =
  apiService.createPostMethod(config.apiUrl.setCart, {
    mapRequest: ({ params }) => ({
      headers: getTokensFromStorage('cartToken'),
      body: JSON.stringify(params)
    })
  });
