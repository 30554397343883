import React, { useState, useCallback } from 'react';
import { FieldText } from '@npm-registry/eapteka-ui';
import { formatPhoneNumberToString } from '../../../../tools/formatters/formatPhoneNumberToString/formatPhoneNumberToString';
import { formatFullName } from '../../../../tools/formatters/formatFullName/formatFullName';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import { useFormFullName } from '../hooks/useFormFullName';
import { useFormPhone } from '../hooks/useFormPhone';

import './CheckoutRecipientForm.scss';

export const CheckoutRecipientForm = ({
  phone,
  fullName,
  onChangeFullName,
  onChangePhone
}) => {
  const [fullNameValue, setFullNameValue] =
    useState(fullName);
  const [phoneValue, setPhoneValue] = useState(phone);

  const {
    onBlurFullName,
    hasErrorFullName,
    errorFullNameMessage,
    onValidateFullName
  } = useFormFullName(fullNameValue, onChangeFullName);
  const {
    onBlurPhone,
    hasErrorPhone,
    errorPhoneMessage,
    onValidatePhone
  } = useFormPhone(phoneValue, onChangePhone);

  checkoutDispatchers.errorRecipientData.subscribe(() => {
    onValidatePhone(phoneValue);
    onValidateFullName(fullNameValue);
  });

  const onChangePhoneHandler = useCallback((event) => {
    setPhoneValue(
      formatPhoneNumberToString(event.target.value)
    );
  }, []);

  const onChangeFullNameHandler = useCallback((event) => {
    setFullNameValue(formatFullName(event.target.value));
  }, []);

  return (
    <div className="CheckoutRecipientForm_Root">
      <FieldText
        className="CheckoutRecipientForm_Input"
        name="phone"
        label="Телефон"
        value={phoneValue}
        onBlur={onBlurPhone}
        onChange={onChangePhoneHandler}
        hasError={hasErrorPhone}
        errorMessage={errorPhoneMessage}
      />
      <FieldText
        className="CheckoutRecipientForm_Input"
        name="fullName"
        label="ФИО"
        value={fullNameValue}
        onBlur={onBlurFullName}
        onChange={onChangeFullNameHandler}
        hasError={hasErrorFullName}
        errorMessage={errorFullNameMessage}
      />
    </div>
  );
};
