import React from 'react';
import { Skeleton } from '@npm-registry/eapteka-ui';

export const CheckoutReceivingMethodSkeleton = () => {
  return (
    <>
      <Skeleton
        width="100%"
        height="56px"
        style={{ marginBottom: '16px' }}
      />
      <Skeleton
        width="80%"
        height="40px"
        style={{ marginBottom: '12px' }}
      />
      <Skeleton
        width="66%"
        height="24px"
        style={{ marginBottom: '8px' }}
      />
      <Skeleton
        width="66%"
        height="24px"
        style={{ marginBottom: '8px' }}
      />
      <Skeleton
        width="66%"
        height="24px"
        style={{ marginBottom: '16px' }}
      />
      <Skeleton
        width="50%"
        height="44px"
        style={{ borderRadius: '30px' }}
      />
    </>
  );
};
