export function validateFullName(fullName) {
  const fullNameString = String(fullName).trim();
  const isEmpty = fullNameString.length === 0;
  // eslint-disable-next-line no-useless-escape
  const hasWrongSymbols = fullNameString.match(
    /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]$/g
  );
  const isTooShort = fullNameString.length < 2;
  const isTooLong = fullNameString.length > 45;
  const errors = new Map([
    [0, 'Укажите ФИО'],
    [1, 'ФИО могут содержать только буквы'],
    [2, 'В ФИО должно быть от 2 символов'],
    [3, 'В ФИО должно быть до 45 символов'],
    [4, 'В ФИО должно быть от 2 до 45 символов']
  ]);

  const errorMessage = isEmpty
    ? errors.get(0)
    : hasWrongSymbols
      ? errors.get(1)
      : isTooShort
        ? errors.get(2)
        : isTooLong
          ? errors.get(3)
          : errors.get(4);

  const hasError =
    isEmpty || hasWrongSymbols || isTooShort || isTooLong;

  return {
    hasError,
    errorMessage: hasError ? errorMessage : ''
  };
}
