import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from '../rootReducer';
import { ReceivingMethodStore } from './types';
import { selectPharmaciesStatus } from '../pharmacies/selectors';

const selectReceivingState = (state: TRootState): ReceivingMethodStore => state.receiving;

export const selectReceivingMethod = createSelector(selectReceivingState, (state) => state.method);
export const selectReceivingMethodStatus = createSelector(
  [selectReceivingState, selectPharmaciesStatus],
  (state, pharmacyStatus) => {
    if ([pharmacyStatus].includes('PENDING')) return 'PENDING';
  }
);
