import React from 'react';
import { Provider } from 'react-redux';
import { Store } from './services/redux/store/store';
import {
  configureRootTheme,
  externalTheme
} from '@npm-registry/eapteka-ui';
import { Checkout } from './components/entry';

const Root = () => {
  configureRootTheme({ theme: externalTheme });
  return (
    <Provider store={Store}>
      <Checkout />
    </Provider>
  );
};

export default Root;
