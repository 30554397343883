export const getTokensFromStorage = (type = '') => {
  const userToken = localStorage.getItem('user-token');
  const cartToken = localStorage.getItem('cart-token');

  const userTokenObject = userToken
    ? { 'user-token': userToken }
    : {};
  const cartTokenObject = cartToken
    ? { 'cart-token': cartToken }
    : {};
  const authBy = localStorage.getItem('authBy');

  switch (type) {
    case 'userToken':
      return userTokenObject;
    case 'cartToken':
      return cartTokenObject;
    case 'authBy':
      return authBy;
    default:
      return { ...userTokenObject, ...cartTokenObject };
  }
};

export const removeTokensFromStorage = (type = '') => {
  if (type) {
    localStorage.removItem(type);
  } else {
    localStorage.removeItem('user-token');
    localStorage.removeItem('cart-token');
    localStorage.removeItem('authBy');
  }
};
