import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Title } from '@npm-registry/eapteka-ui';
import './CheckoutDeliveryDay.scss';

export const CheckoutDeliveryDay = ({
  title,
  description,
  isSelected,
  onClick
}) => {
  const rootClassName = useMemo(() => {
    return clsx('CheckoutDeliveryDay_Root', {
      'CheckoutDeliveryDay_Root--selected': isSelected
    });
  }, [isSelected]);

  return (
    <div className={rootClassName} onClick={onClick}>
      <Title
        tag="h4"
        weight="regular"
        className="CheckoutDeliveryDay_Title"
      >
        {title}
      </Title>
      <Title
        tag="h6"
        weight="regular"
        className="CheckoutDeliveryDay_Description"
      >
        {description}
      </Title>
    </div>
  );
};
