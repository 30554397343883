import {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegion } from '../../../../services/redux/store/region/selectors';
import { setBaseCoordinates } from '../../../../services/redux/store/pharmacies/slice';
import { MapGLDispatcher } from '../../../core/2GIS/helpers/dispatcher';
import { getPickupAddressSuggestions } from '../../../../services/api/methods/addressSuggestionsGet';
import { formatSearchQuery } from '../helpers/formatSearchQuery';
import { SEARCH_QUERY_ERROR_MESSAGES } from '../const';
import { checkAddressZone } from '../../../../services/api/methods/checkAddressZone';
import { selectReceivingMethod } from '../../../../services/redux/store/receiving/selectors';
import type { Suggestion, SuggestionsSearch } from './types';
import { createNotification } from '../../../../services/notifications';


export const useAddressSearchSuggestions = ({
  onAddressSelect
}: SuggestionsSearch) => {
  const dispatch = useDispatch();
  const { latitude, longitude, name, region: description } = useSelector(selectRegion);
  const { isDeliverySelected } = useSelector(selectReceivingMethod);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isPending, setPending] = useState(false);
  const prevSuggestionSearchQuery = useRef('');

  const preparedSuggestions = useMemo(
    () =>
      suggestions.filter(
        ({ street, building, label }) =>
          !searchQuery.includes(label) ||
          (street && building)
      ),
    [suggestions, searchQuery]
  );

  const onSuggestionsGet = useCallback(
    (regionSuggestions) => {
      const region = description.split(',')[0];
      const filteredSuggestions = regionSuggestions.filter(
        ({ city, locality }: { city: string, locality: string }) => {
          return (
            city === name ||
            locality === region ||
            name.split(' ').includes(city) ||
            name.split(' ').includes(locality)
          );
        }
      );

      if (filteredSuggestions.length > 0) {
        setSuggestions(filteredSuggestions);
        setErrorMessage(null);
      } else {
        const message =
          regionSuggestions.length > 0
            ? SEARCH_QUERY_ERROR_MESSAGES.addressOutOfRegion
            : SEARCH_QUERY_ERROR_MESSAGES.addressNotFound;

        setErrorMessage(message);
      }
    },
    [description, name]
  );

  const onSearchQueryUpdate = useCallback(
    (inputValue) => {
      const query = formatSearchQuery(inputValue);
      const isSearchQueryValid =
        query.length > 2 &&
        query !== prevSuggestionSearchQuery.current;

      setSearchQuery(inputValue);

      if (isSearchQueryValid) {
        prevSuggestionSearchQuery.current = query;
        setPending(true);
        getPickupAddressSuggestions(query, [
          latitude,
          longitude
        ])
          .then(onSuggestionsGet)
          .catch(() => onSuggestionsGet([]))
          .finally(() => setPending(false));
      }
    },
    [longitude, latitude, onSuggestionsGet]
  );

  const onSuggestionSelect = useCallback(
    async (suggestion) => {
      const { latitude, longitude } =
        suggestion.coordinates;

      if (!suggestion.building) {
        onSearchQueryUpdate(suggestion.label);
        return;
      }

      if (isDeliverySelected) {
        const { zone } = await checkAddressZone({ latitude, longitude });
        if (!zone) {
          setErrorMessage(
            SEARCH_QUERY_ERROR_MESSAGES.addressIsOutOfDeliveryZone
          );
        }

        suggestion.zone = zone;
      }
      onAddressSelect(suggestion);
      dispatch(setBaseCoordinates({ latitude: suggestion.coordinates[0], longitude: suggestion.coordinates[1] }));
      MapGLDispatcher.userAddress.dispatch(
        suggestion.label
      );
      MapGLDispatcher.userCoordinates.dispatch([
        longitude,
        latitude
      ]);
      setSuggestions([]);
      setErrorMessage(null);
      return setSearchQuery(suggestion.label);
    },
    [dispatch, isDeliverySelected, onAddressSelect, onSearchQueryUpdate]
  );

  const onClear = useCallback(() => {
    onAddressSelect(null);
    setSearchQuery('');
    setSuggestions([]);
    setErrorMessage(null);
  }, [onAddressSelect]);

  const onSearchQuery = useCallback(
    (e) => {
      if (e.target.value.length === 0) {
        onClear();
      }
      onSearchQueryUpdate(e.target.value);
    },
    [onClear, onSearchQueryUpdate]
  );

  const updateQuery = useCallback(
    (label, address) => {
      setSearchQuery(label);
      if (isDeliverySelected) {
        if (address && !address.building) {
          createNotification({ title: 'Укажите точный номер дома' })
        }
      }
    },
    [isDeliverySelected]
  );

  useEffect(() => {
    const updateSearchBar =
      MapGLDispatcher.userAddress.subscribe(updateQuery);

    return () => {
      updateSearchBar();
      setSearchQuery('');
    };
  }, [updateQuery]);

  return {
    onSuggestionSelect,
    onSearchQuery,
    errorMessage,
    suggestions: preparedSuggestions,
    isPending,
    onClear,
    searchQuery
  };
};
