import { Dispatcher } from '@npm-registry/helpers/dispatcher';

export const checkoutDispatchers = {
  // Посещение страницы:
  pageVisit: new Dispatcher(),

  // Передавать возможные способы оплаты при загрузке страницы:
  availablePaymentMethods: new Dispatcher(),

  // Отправлять, если пользователь сталкивается с любой ошибкой на чекауте:
  error: new Dispatcher(),

  // Выбор способа получения / самовывоз:
  receivingMethodPickupSelect: new Dispatcher(),

  // Выбор способа получения / доставка:
  receivingMethodDeliverySelect: new Dispatcher(),

  // Просмотр экрана "Ваш адрес":
  deliveryAddressVisit: new Dispatcher(),

  // Выбор конкретного саджеста адреса на экране "Ваш адрес":
  deliveryAddressSuggestSelect: new Dispatcher(),

  // Перемещение пина на карте:
  deliveryAddressMovePinPosition: new Dispatcher(),

  // Сохранить адрес получения:
  deliveryAddressSave: new Dispatcher(),

  // Выбрать ранее сохраненный адрес получения:
  deliveryAddressSelect: new Dispatcher(),

  // Выбрать адрес получения из списка адресов:
  deliveryAddressSelectFromHistory: new Dispatcher(),

  // Клик на иконку редактирования около адреса:
  deliveryAddressEditClick: new Dispatcher(),

  // Добавление адреса
  deliveryAddressAdd: new Dispatcher(),

  // Клик по кнопке "Добавить адрес"
  deliveryAddressAddButtonClick: new Dispatcher(),

  // Клик по кнопке удалить адрес доставки:
  deliveryAddressRemove: new Dispatcher(),

  // Пользователь переключил ползунок "бесконтактная доставка":
  deliveryContactlessToggle: new Dispatcher(),

  // Пользователь включил ползунок "бесконтактная доставка":
  deliveryContactlessEnable: new Dispatcher(),

  // Пользователь выключил ползунок "бесконтактная доставка":
  deliveryContactlessDisable: new Dispatcher(),

  // Просмотр интервалов доставки (ожидается передача массива с интервалами):
  deliveryIntervalsVisit: new Dispatcher(),

  // Пользователь кликнул на дату в календаре:
  deliveryIntervalsDateClick: new Dispatcher(),

  // Пользователь выбрал временной интервал доставки из списка кликом по радио кнопке:
  deliveryIntervalsSelect: new Dispatcher(),

  // Нет интервалов для выбранного адреса
  deliveryIntervalsAreMissing: new Dispatcher(),

  // Не удалось заменить товары в мультикорзине
  multicartUpdateFail: new Dispatcher(),

  // Клик по кнопке "Забрать тогда-то" на карточке аптеки (на карте или в списке):
  pickupPharmacyClick: new Dispatcher(),

  // Клик по пину аптеки на карте
  pickupPharmacyPinClick: new Dispatcher(),

  // Пользователь кликнул по иконке редактировать для выбранной аптеки самовывоза:
  pickupPharmacyChange: new Dispatcher(),

  // Открытие карты редактирования аптеки
  pickupEditOpen: new Dispatcher(),

  // Пользователь тапнул на переключатель вида адреса (карта или список):
  pickupViewChange: new Dispatcher(),

  // Пользователь выбрал один из фильтров:
  pickupFiltersShow: new Dispatcher(),

  // Пользователь выбрал один из фильтров:
  pickupFilterSelect: new Dispatcher(),

  // Пользователь активировал кликом адресную строку:
  pickupAddressSearchInputFocus: new Dispatcher(),

  // Выбор конкретного саджеста адреса на экране "Выбора аптеки":
  pickupAddressSuggestSelect: new Dispatcher(),

  // Пользователь открыл аптеку на карте (кликнув по кружку аптеки):
  pickupAddressShowPharmacyByPinClick: new Dispatcher(),

  // Пользователь выбрал тип оплаты и нажал "Выбрать":
  paymentMethodSubmit: new Dispatcher(),

  // Пользователь активировал ползунок списания баллов ПЛ
  loyaltyPointsUsed: new Dispatcher(),

  // Пользователь нажал на ссылку “Другой получатель”:
  anotherRecipientClick: new Dispatcher(),

  // Показ экрана с ошибкой: В текущем регионе нет такого адреса:
  regionAddressNotFound: new Dispatcher(),

  // Показ экрана с ошибкой "Адрес не найден, попробуй еще раз":
  addressNotFoundTryAgain: new Dispatcher(),

  // Клик по ссылке с составом заказа:
  summaryOffersClick: new Dispatcher(),

  // Пользователь кликнул по кнопке "Оформить заказ":
  confirmOrderSubmit: new Dispatcher(),

  // Успешная отправка запроса оформления заказа:
  confirmOrderSuccess: new Dispatcher(),

  // Неудачная отправка запроса оформления заказа:
  confirmOrderFail: new Dispatcher(),

  // Ошибка оправки/валидации данных запроса оформления заказа:
  orderFailEvent: new Dispatcher(),

  // Клик по тултипу в блоке с недоступной доставкой
  deliveryInformerClicked: new Dispatcher(),

  // Нотификация. Показываем при ошибке запроса на получение адреса:
  errorGetCheckoutDeliveryAddress: new Dispatcher(),

  // Нотификация. Показываем при ошибке запроса на получение адреса по координатам на карте:
  errorGetMovePointerAddress: new Dispatcher(),

  // Нотификация. Показываем если пришел пустой список аптек или ошибка запроса:
  errorGetPharmacies: new Dispatcher(),

  // Нотификация. Показываем при ошибке в запросе на пункты самовывоза:
  errorGetPickupPoints: new Dispatcher(),

  // Нотификация. Показываем при незаполненных данных получателя (ФИО/email):
  errorRecipientData: new Dispatcher(),

  // Нотификация. Показываем при технических сбоях сети или проблемах в запросах:
  errorTechnical: new Dispatcher(),

  // Нотификация. Показываем при незаполненных данных юр. лица (Название компании/ИНН/КПП):
  errorCorporateFields: new Dispatcher(),

  // Нотификация. Показываем при удалении адреса из списка
  deleteDeliveryAddress: new Dispatcher(),

  // Нотификация. Очистка уведомлений.
  notificationsClear: new Dispatcher(),

  // Отображение большого прелоадера на весь экран
  preloaderShowing: new Dispatcher(),

  // Недоступна доставка по электронному рецепту
  deliveryERecipeNotAllowed: new Dispatcher(),

  // Смена состояния запоминания карты для будущих покупок
  saveCardCheckboxChanged: new Dispatcher(),

  // В блоке оплаты показалась ссылка для смены аптеки для перевода на карту с фильтром bnpl
  bnplPharmaciesButtonShown: new Dispatcher(),

  // Клик по ссылке смены аптеки в блоке оплаты, переводит на карту с фильтром по bnpl
  bnplPharmaciesButtonClicked: new Dispatcher(),

  // Пользователь активировал/деактивировал ползунок списания баллов СберСпасибо
  sberSpasiboToggle: new Dispatcher(),

  // Переключение табов способа получения. Не для аналитики.
  toggleReceivingMethod: new Dispatcher(),

  // Открытие списка состава корзины. Не для аналитики.
  toggleProductList: new Dispatcher(),

  // Недоступно оформление заказа у юр. лиц
  deliveryCompanyUnavailable: new Dispatcher(),

  // Пользователь кликнул на дату в окне выбора интервалов
  deliveryDateSelected: new Dispatcher(),

  // Отправляется в окне с интервалами при клике на дату и при открытии окна интервалов
  deliveryAvailableTimeExpand: new Dispatcher(),

  // Нотификация. Показываем при неуспешном списание бонусов СберСпасибо
  sberLoyaltyFail: new Dispatcher()
};
