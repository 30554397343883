import React, {
  FC,
  MouseEventHandler,
  ReactNode
} from 'react';
import { Button, Link } from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import './CheckoutReceivingMethodPlaceholder.scss';

interface ReceivingMethodPlaceholder {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  title?: ReactNode;
  buttonTitle?: string;
  receiverType?:
    | 'Delivery'
    | 'DeliveryUnavailable'
    | 'Pickup'
    | 'Corporate';
  linkTo?: string;
  customButtonBlock?: ReactNode;
}

export const CheckoutReceivingMethodPlaceholder: FC<
  ReceivingMethodPlaceholder
> = ({
  onClick,
  receiverType = 'Pickup',
  title,
  buttonTitle,
  linkTo,
  customButtonBlock
}) => {
  const rootClass = clsx(
    'CheckoutReceivingMethodPlaceholder',
    `CheckoutReceivingMethodPlaceholder--${receiverType}`
  );

  return (
    <div className={rootClass}>
      <p className="CheckoutReceivingMethodPlaceholder_Title">
        {title}
      </p>
      {linkTo && (
        <Link
          href={linkTo}
          className="CheckoutReceivingMethodPlaceholder_Button--LikeLink"
          aria-label={buttonTitle}
        >
          {buttonTitle}
        </Link>
      )}
      {onClick && (
        <Button
          size="s"
          variant="outlinedSolid"
          className="CheckoutReceivingMethodPlaceholder_Button"
          theme="primary"
          onClick={onClick}
        >
          {buttonTitle}
        </Button>
      )}
      {customButtonBlock}
    </div>
  );
};
