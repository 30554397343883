import { useState } from 'react';
import { validatePhoneNumber } from '../../../../tools/validation/validatePhoneNumber/validatePhoneNumber';

export function useFormPhone(phone, callback) {
  const [hasErrorPhone, setHasErrorPhone] = useState(false);
  const [errorPhoneMessage, setErrorPhoneMessage] =
    useState('');

  const onBlurPhone = () => {
    const { hasError, errorMessage } =
      validatePhoneNumber(phone);

    setHasErrorPhone(hasError);
    setErrorPhoneMessage(errorMessage);

    if (!hasError) {
      callback(phone);
    } else {
      callback('');
    }
  };

  const onValidatePhone = () => {
    const { hasError, errorMessage } =
      validatePhoneNumber(phone);

    setHasErrorPhone(hasError);
    setErrorPhoneMessage(errorMessage);
  };

  return {
    onBlurPhone,
    onValidatePhone,
    hasErrorPhone,
    errorPhoneMessage
  };
}
