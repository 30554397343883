import React, {
  type FC,
  type ReactNode,
  type MouseEventHandler
} from 'react';
import cn from 'clsx';
import './Tab.scss';

export interface ITabProps {
  className: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isActive: boolean;
  isDisabled: boolean;
}

export const Tab: FC<ITabProps> = ({
  className,
  children,
  onClick,
  isActive,
  isDisabled
}) => (
  <button
    className={cn('CheckoutTabItemRoot', className, {
      CheckoutTabItemDisabled: isDisabled,
      CheckoutTabItemSelectedColourful:
        isActive && !isDisabled,
      CheckoutTabItemSelectedColourfulDisabled:
        isActive && isDisabled
    })}
    data-active={isActive}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </button>
);
