import React from 'react';
import clsx from 'clsx';
import './CheckoutOrderSummaryItem.scss';

export const CheckoutOrderSummaryItem = ({
  label,
  value,
  children,
  className,
  classNameLabel,
  classNameValue,
  ...props
}) => {
  const rootClassNames = clsx(
    'CheckoutOrderSummaryItem',
    className
  );
  const labelClassNames = clsx(
    'CheckoutOrderSummary_Label',
    classNameLabel
  );
  const valueClassNames = clsx(
    'CheckoutOrderSummaryItem_Value',
    classNameValue
  );

  return (
    <div {...props} className={rootClassNames}>
      <span className={labelClassNames}>{label}</span>
      <span className={valueClassNames}>{value}</span>
      {children}
    </div>
  );
};
