import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectCartItems } from '../../../../services/redux/store/cart/selectors';
import { CheckoutOffer } from './../../CheckoutOffer';
import { selectCurrentPharmacy } from '../../../../services/redux/store/pharmacies/selectors';
import './CheckoutPharmacyOffers.scss';
import { selectReceivingMethod } from '../../../../services/redux/store/receiving/selectors';

export const CheckoutPharmacyOffers = ({
  unavailableOffers,
  className,
}) => {
  const { isPickupSelected } = useSelector(selectReceivingMethod);
  const currentPharmacy = useSelector((selectCurrentPharmacy));
  const rootClassName = useMemo(
    () => clsx('CheckoutPharmacyOffers_Root', [className]),
    [className]
  );

  const offers = useSelector(selectCartItems);

  const onlyUnavailableOffers = useMemo(
    () =>
      offers.filter((offer) =>
        unavailableOffers.includes(offer.id) || !offer.inOrder
      ),
    [offers, unavailableOffers]
  );

  const onlyAvailableOffers = useMemo(
    () =>
      offers.filter(
        (offer) => !unavailableOffers.includes(offer.id) && offer.inOrder
      ),
    [offers, unavailableOffers]
  );

  const warning = isPickupSelected && currentPharmacy
    ? 'Товары ниже нельзя забрать из выбранной аптеки'
    : 'Товары ниже недоступны для заказа';

  return (
    <div className={rootClassName}>
      <div className="CheckoutPharmacy_AvailableOffers">
        {onlyAvailableOffers.map(
          ({
            id,
            name,
            preview,
            price,
            oldPrice,
            recipeDeliveryAllowed,
            isERecipe,
            amount
          }) => (
            <CheckoutOffer
              key={id}
              title={name}
              preview={preview}
              isAvailable
              count={amount}
              oldPrice={oldPrice}
              price={price}
              isRecipeDeliveryAllowed={
                recipeDeliveryAllowed
              }
              isERecipe={isERecipe}
            />
          )
        )}
      </div>
      {onlyUnavailableOffers.length > 0 && (
        <>
          <div className="CheckoutPharmacy_Warning">
            {warning}
          </div>
          <div className="CheckoutPharmacy_UnavailableOffers">
            {onlyUnavailableOffers.map(
              ({
                id,
                name,
                preview,
                price,
                oldPrice,
                recipeDeliveryAllowed,
                isERecipe,
                amount
              }) => (
                <CheckoutOffer
                  key={id}
                  title={name}
                  preview={preview}
                  count={amount}
                  oldPrice={oldPrice}
                  price={price}
                  isRecipeDeliveryAllowed={
                    recipeDeliveryAllowed
                  }
                  isERecipe={isERecipe}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};
