import React, { useState, useEffect } from 'react';
import { Preloader as PreloaderUI } from '@npm-registry/eapteka-ui';
import { checkoutDispatchers } from '../../../services/dispatchers';
import './CheckoutPreloader.scss';

export const Preloader = () => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const preloaderUpdate =
      checkoutDispatchers.preloaderShowing.subscribe(
        setVisible
      );

    return () => {
      preloaderUpdate();
    };
  }, []);

  if (isVisible)
    return (
      <div className="CheckoutPreloader_Root">
        <PreloaderUI className="CheckoutPreloader_Icon" />
      </div>
    );

  return null;
};
