import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './CheckoutPharmacySection.scss';

export const CheckoutPharmacySection = ({
  children,
  icon,
  className
}) => {
  const rootClassNames = useMemo(() => {
    return clsx('CheckoutPharmacySection_Root', className);
  }, [className]);

  return (
    <div className={rootClassNames}>
      <div className="CheckoutPharmacySection_Icon">
        {icon}
      </div>
      <p className="CheckoutPharmacySection_Text">
        {children}
      </p>
    </div>
  );
};

CheckoutPharmacySection.propTypes = {
  /**
   * Дополнительный класс для root элемента компонента
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Содержимое компонента
   */
  children: PropTypes.node,
  /**
   * Иконка компонента
   */
  icon: PropTypes.node.isRequired
};
