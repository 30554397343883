import mapgl from '@2gis/mapgl/global';

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';

interface MapContextState {
  mapglInstance?: mapgl.Map;
  mapgl?: typeof mapgl;
}

const MapglContext = createContext<{
  mapgl?: typeof mapgl;
  mapglInstance?: mapgl.Map;
  setMapglContext: Dispatch<
    SetStateAction<MapContextState>
  >;
}>({
  mapgl: undefined,
  mapglInstance: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMapglContext: () => {}
});

export function useMapGLContext() {
  return useContext(MapglContext);
}

export function MapGLContextProvider({
  children
}: {
  children: ReactNode;
}) {
  const [{ mapglInstance, mapgl }, setMapglContext] =
    useState<MapContextState>({
      mapglInstance: undefined,
      mapgl: undefined
    });
  return (
    <MapglContext.Provider
      value={{ mapgl, mapglInstance, setMapglContext }}
    >
      {children}
    </MapglContext.Provider>
  );
}
