import React from 'react';

import './CheckoutReceivingMethodLayout.scss';

export const CheckoutReceivingMethodLayout = ({
  children
}) => {
  return (
    <div className="CheckoutReceivingMethodsLayout_Root">
      {children}
    </div>
  );
};
