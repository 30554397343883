import React from 'react';
import { Skeleton } from '@npm-registry/eapteka-ui';
import { useSelector } from 'react-redux';
import { selectEssentialDataLoaded } from '../../services/redux/store/selectors';
import { selectUserIsCorporate } from '../../services/redux/store/user/selectors';
import { CheckoutPageLayout } from '../layouts/CheckoutPageLayout';
import { CheckoutReceivingMethods } from '../blocks/CheckoutReceivingMethods';
import { CheckoutPayment } from '../blocks/CheckoutPayment';
import { CheckoutUser } from '../blocks/CheckoutUser';
import { CheckoutRecipientCorporate } from '../blocks/CheckoutRecipientCorporate';
import { CheckoutMainHeader } from '../blocks/CheckoutMainHeader';
import { Preloader } from '../core/Preloader';
import { CheckoutSectionHead } from '../elements/CheckoutSectionHead';
import { CheckoutOffersList } from '../blocks/CheckoutOffersList';
import { CheckoutReceivingMethodsDeliveryDatetime } from '../blocks/CheckoutReceivingMethods/DeliveryDatetime';
import { CheckoutSummary } from '../blocks/CheckoutSummary';
import { useCorporateSumInsufficient } from '../hooks/useCorporateSumInsufficient';
import { useInactivityNotification } from './hooks/useInactivityNotification';
import { CheckoutFooter } from '../blocks/CheckoutFooter';
import '../../services/notifications/notifications';
import '../../services/telemetry/trackers/telemetry';

export const Checkout = () => {
  const isEssentialDataLoaded = useSelector(selectEssentialDataLoaded);
  const isCorporate = useSelector(selectUserIsCorporate);

  const isCorporateSumInsufficient =
    useCorporateSumInsufficient();
  const showCorporateBlocks =
    !isCorporate || !isCorporateSumInsufficient;

  useInactivityNotification(isEssentialDataLoaded);

  if (!isEssentialDataLoaded) {
    return (
      <CheckoutPageLayout>
        <CheckoutSectionHead />
        <main className="CheckoutMain_Container">
          <article className="CheckoutMain_Left">
            <Skeleton borderRadius={16} height={18} />
            <Skeleton borderRadius={16} height={40} />
            <Skeleton borderRadius={16} height={356} />
            <Skeleton borderRadius={16} height={146}/>
            <Skeleton borderRadius={16} height={80} />
          </article>
          <article className="CheckoutMain_Right">
            <Skeleton borderRadius={16} height={356} />
          </article>
        </main>
      </CheckoutPageLayout>
    )
  }

  return (
    <CheckoutPageLayout>
      <CheckoutSectionHead />
      <CheckoutMainHeader />
      <main className="CheckoutMain_Container">
        <article className="CheckoutMain_Left">
          <CheckoutReceivingMethods />
          {showCorporateBlocks && (
            <>
              <CheckoutReceivingMethodsDeliveryDatetime />
              <CheckoutRecipientCorporate />
              <CheckoutPayment />
            </>
          )}
          <CheckoutUser />
          <CheckoutOffersList />
        </article>
        <article className="CheckoutMain_Right">
          <CheckoutSummary />
        </article>
      </main>
      <CheckoutFooter />
      <Preloader />
    </CheckoutPageLayout>
  );
};
