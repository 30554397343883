import { apiService } from '../apiService';
import config from '../../../config';

export const requestOffers = apiService.createGetMethod(
  config.apiUrl.getOffers,
  {
    mapRequest: ({ offerIds }, { currentUrl }) => ({
      url: `${currentUrl}/${offerIds}`
    })
  }
);
