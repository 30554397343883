import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRegion } from '../../services/redux/store/region/selectors';
import { selectUserIsCorporate } from '../../services/redux/store/user/selectors';
import { selectCartSummary } from '../../services/redux/store/cart/selectors';

export const useCorporateSumInsufficient = () => {
  const isCorporate = useSelector(selectUserIsCorporate);
  const { minPriceCompany, isDeliveryCompany } = useSelector(selectRegion);
  const { totalPrice } = useSelector(selectCartSummary);

  return useMemo(() => {
    return isCorporate && (totalPrice < minPriceCompany || !isDeliveryCompany);
  }, [isCorporate, minPriceCompany, totalPrice, isDeliveryCompany]);
};
