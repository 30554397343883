import clsx from 'clsx';
import React, { useState, useCallback } from 'react';
import {
  Button,
  FieldText
} from '@npm-registry/eapteka-ui';
import { formatPhoneNumberToString } from '../../../../tools/formatters/formatPhoneNumberToString/formatPhoneNumberToString';
import { formatFullName } from '../../../../tools/formatters/formatFullName/formatFullName';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import { useFormEmail } from '../hooks/useFormEmail';
import { useFormFullName } from '../hooks/useFormFullName';
import { useFormPhone } from '../hooks/useFormPhone';
import {
  EMOJI_CODES,
  MAX_FULL_NAME_LENGTH
} from '../../../../tools/const';
import './CheckoutUserForm.scss';

export const CheckoutUserForm = ({
  isFullScreen = false,
  isPhoneDisabled = true,
  isEmailDisabled = false,
  phone,
  email,
  name,
  onChangeEmail = Function.prototype,
  onChangeFullName = Function.prototype,
  onChangePhone = Function.prototype,
  onSave
}) => {
  const hasOnSaveCallback = typeof onSave === 'function';

  const [emailValue, setEmailValue] = useState(email);
  const [fullNameValue, setFullNameValue] = useState(name);
  const [phoneValue, setPhoneValue] = useState(phone);

  const {
    onBlurEmail,
    hasErrorEmail,
    errorEmailMessage,
    onValidateEmail
  } = useFormEmail(
    emailValue,
    hasOnSaveCallback ? () => {} : onChangeEmail
  );
  const {
    onBlurFullName,
    hasErrorFullName,
    errorFullNameMessage,
    onValidateFullName
  } = useFormFullName(
    fullNameValue,
    hasOnSaveCallback ? () => {} : onChangeFullName
  );
  const {
    onBlurPhone,
    hasErrorPhone,
    errorPhoneMessage,
    onValidatePhone
  } = useFormPhone(
    phoneValue,
    hasOnSaveCallback ? () => {} : onChangePhone
  );

  // костыль, нужно переделать всё
  const onBlurField = (blurHandler) => (event) => {
    // аккуратней тут сайд эффект
    const [fieldKey, fieldValue] = blurHandler(event);

    sessionStorage.setItem(
      'checkout-user-temp-data',
      JSON.stringify({
        phone: phoneValue,
        email: emailValue,
        name: fullNameValue,
        [fieldKey]: fieldValue
      })
    );
  };

  checkoutDispatchers.errorRecipientData.subscribe(() => {
    onValidatePhone(phoneValue);
    onValidateEmail(emailValue);
    onValidateFullName(fullNameValue);
  });

  const onChangeEmailHandler = useCallback((event) => {
    const emailValue = event.target.value.replace(
      EMOJI_CODES,
      ''
    );
    setEmailValue(emailValue);
  }, []);

  const onChangePhoneHandler = useCallback((event) => {
    const formatedPhoneNumber = formatPhoneNumberToString(
      event.target.value
    );
    setPhoneValue(formatedPhoneNumber);
  }, []);

  const onChangeFullNameHandler = useCallback((event) => {
    const formatedFullName = formatFullName(
      event.target.value
    );
    if (formatedFullName.length <= MAX_FULL_NAME_LENGTH) {
      setFullNameValue(formatedFullName);
    }
  }, []);

  const rootClassNames = clsx('CheckoutUserForm_Root', {
    'CheckoutUserForm_Root--fullScreen': isFullScreen
  });

  return (
    <>
      <div className={rootClassNames}>
        <FieldText
          name="phone"
          label="Телефон"
          value={phoneValue}
          onBlur={onBlurField(onBlurPhone)}
          onChange={onChangePhoneHandler}
          disabled={isPhoneDisabled}
          hasError={hasErrorPhone}
          errorMessage={errorPhoneMessage}
        />
        <FieldText
          name="email"
          label="Почта"
          value={emailValue}
          disabled={isEmailDisabled}
          onBlur={onBlurField(onBlurEmail)}
          onChange={onChangeEmailHandler}
          hasError={hasErrorEmail}
          errorMessage={errorEmailMessage}
        />
        <FieldText
          name="fullName"
          label="ФИО"
          value={fullNameValue}
          onBlur={onBlurField(onBlurFullName)}
          onChange={onChangeFullNameHandler}
          hasError={hasErrorFullName}
          errorMessage={errorFullNameMessage}
        />
      </div>
      {isFullScreen && (
        <Button
          className="CheckoutUserForm_Confirm"
          disabled={
            !emailValue ||
            !fullNameValue ||
            !phoneValue ||
            hasErrorEmail ||
            hasErrorFullName ||
            hasErrorPhone
          }
          onClick={() =>
            onSave({
              phone: phoneValue,
              email: emailValue,
              name: fullNameValue
            })
          }
        >
          Продолжить оформление
        </Button>
      )}
    </>
  );
};
