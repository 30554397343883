import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import type { Address, DeliveryDate, DeliveryStore, Interval } from './types';
import type { FetchStatus } from '../types';

const initialState = {
  status: FETCH_STATUS.IDLE,
  intervalStatus: FETCH_STATUS.IDLE,
  addresses: [],
  intervals: [],
  dates: [],
  currentAddress: null,
  currentInterval: null,
  currentDate: null,
  logisticSchemeId: ''
} as DeliveryStore;

export const deliverySlice = createSlice({
  name: STORE_NAMES.DELIVERY,
  initialState,
  reducers: {
    setAddresses: (state, { payload }: PayloadAction<Address[]>) => {
      state.addresses = payload;
    },
    setIntervals: (state, { payload }: PayloadAction<Interval[]>) => {
      state.intervals = payload;
    },
    setCurrentAddress: (state, { payload }: PayloadAction<Address | null>) => {
      state.currentAddress = payload;
    },
    setCurrentInterval: (state, { payload }: PayloadAction<Interval | null>) => {
      state.currentInterval = payload;
    },
    setCurrentDate: (state, { payload }: PayloadAction<DeliveryDate | null>) => {
      state.currentDate = payload;
    },
    setDeliveryDates: (state, { payload }: PayloadAction<DeliveryDate[]>) => {
      state.dates = payload;
    },
    setStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.status = payload;
    },
    setIntervalStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.intervalStatus = payload;
    },
    setLogisticsSchemeId: (state, { payload }: PayloadAction<string>) => {
      state.logisticSchemeId = payload;
    },
    deleteAddress: (state) => {
      state.addresses = state.addresses.filter((a) => a.id !== state.currentAddress.id);
    },
    addAddress: (state, { payload }: PayloadAction<Address>) => {
      state.addresses.unshift(payload);
    },
  }
});

const deliveryReducer = deliverySlice.reducer;
const deliveryActions = deliverySlice.actions;

export const {
  setCurrentAddress,
  setCurrentInterval,
  setIntervals,
  deleteAddress,
  addAddress,
  setLogisticsSchemeId,
  setIntervalStatus,
  setAddresses,
  setCurrentDate,
  setDeliveryDates,
  setStatus
} = deliveryActions;

export { deliveryReducer, deliveryActions };
