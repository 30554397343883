type Sibling =
  | 'nextElementSibling'
  | 'previousElementSibling';

const focus = (key: Sibling) => {
  if (document.activeElement) {
    (document.activeElement[key] as HTMLElement)?.focus();
  }
};

export const ACTIONS = {
  ArrowDown: () => focus('nextElementSibling'),
  ArrowUp: () => focus('previousElementSibling')
};
