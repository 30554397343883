import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUIPickup } from '../../../../services/redux/store/ui/selectors';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import { CheckoutPickup } from '../../CheckoutPickup';
import { CheckoutReceivingMethodLayout } from '../Layout';
import { CheckoutSelectedPharmacy } from './SelectedPharmacy';
import { useReceivingMethodPickup } from './hooks/useReceivingMethodPickup';
import './CheckoutReceivingMethodPickup.scss';

export const CheckoutReceivingMethodPickup = () => {
  const { isPickupScreenOpen } = useSelector(selectUIPickup);
  const { onPickupEdit } = useReceivingMethodPickup();

  useEffect(() => {
    const pickupEditOpenSubscriber = (sendTelemetryEvent = true) => {
      onPickupEdit(null, sendTelemetryEvent);
    };

    const openEditPickup = checkoutDispatchers.pickupEditOpen.subscribe(pickupEditOpenSubscriber);

    return () => {
      openEditPickup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckoutReceivingMethodLayout>
      {isPickupScreenOpen ? (
        <CheckoutPickup />
      ) : (
        <CheckoutSelectedPharmacy onPickupEdit={onPickupEdit} />
      )}
    </CheckoutReceivingMethodLayout>
  );
};
