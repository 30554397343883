import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegionFeatures } from '../../../../services/redux/store/region/selectors';
import { useDeliveryAvailable } from '../Delivery/hooks/useDeliveryAvailable';
import { selectUserIsCorporate } from '../../../../services/redux/store/user/selectors';
import { selectReceivingMethod } from '../../../../services/redux/store/receiving/selectors';
import {
  setReceivingMethodDelivery,
  setReceivingMethodPickup
} from '../../../../services/redux/store/receiving/slice';
import { setBonusToggled, setCardRemembered, setCurrentPayment, setSberspasiboToggled } from '../../../../services/redux/store/payment/slice';

// Нужно унести первоначальный выбор типа получения из реакт
export const useReceivingMethodView = () => {
  const dispatch = useDispatch();
  const isCorporate = useSelector(selectUserIsCorporate);
  const { isPickupSelected, isDeliverySelected } = useSelector(selectReceivingMethod);
  const {
    delivery: isDeliveryAvailableInRegionForIndividual,
    pickup: isPickupAvailableInRegionForIndividual
  } = useSelector(selectRegionFeatures);

  const isDeliveryAvailable = useDeliveryAvailable();

  const onChangeReceivingMethod = useCallback(
    (key: 'delivery' | 'pickup') => {
      const isPickup = key === 'pickup';
      dispatch(setCurrentPayment(null));
      dispatch(setCardRemembered(false));
      dispatch(setBonusToggled(false));
      dispatch(setSberspasiboToggled(false));

      if (isPickup) {
        dispatch(setReceivingMethodPickup());
      } else {
        dispatch(setReceivingMethodDelivery());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isCorporate && !isDeliveryAvailable) {
      onChangeReceivingMethod('pickup');
    }
  }, [isCorporate, isDeliveryAvailable, onChangeReceivingMethod]);

  useEffect(() => {
    if (!isCorporate) {
      if (isPickupSelected && !isPickupAvailableInRegionForIndividual) {
        onChangeReceivingMethod('delivery');
      } else if (!isPickupSelected && !isDeliveryAvailableInRegionForIndividual) {
        onChangeReceivingMethod('pickup');
      }
    }
  }, [
    isPickupAvailableInRegionForIndividual,
    isDeliveryAvailableInRegionForIndividual,
    isCorporate,
    isPickupSelected,
    onChangeReceivingMethod
  ]);

  useEffect(() => {
    if (!isDeliverySelected && !isPickupSelected) {
      if (!isCorporate) {
        if (isPickupAvailableInRegionForIndividual) {
          onChangeReceivingMethod('pickup');
        } else if (isDeliveryAvailableInRegionForIndividual && isDeliveryAvailable) {
          onChangeReceivingMethod('delivery');
        }
      }
    }
  }, [
    isCorporate,
    isDeliveryAvailable,
    isDeliveryAvailableInRegionForIndividual,
    isDeliverySelected,
    isPickupAvailableInRegionForIndividual,
    isPickupSelected,
    onChangeReceivingMethod
  ]);

  return { isPickupSelected, onChangeReceivingMethod };
};
