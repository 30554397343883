import { useEffect } from 'react';

export const useHiddenOverflow = (isHidden = true) => {
  useEffect(() => {
    document.querySelector('html').style.overflow = isHidden
      ? 'hidden'
      : '';

    return () =>
      (document.querySelector('html').style.overflow = '');
  }, [isHidden]);
};
