import React from 'react';
import { ScreenModal } from '../../../core/ScreenModal';
import { CheckoutHeader } from '../../../layouts/CheckoutHeader';
import './CheckoutUserEdit.scss';

export const CheckoutUserEdit = ({ onClose, children }) => {
  return (
    <ScreenModal className="CheckoutUserEdit_Root">
      <CheckoutHeader
        title="Личные данные"
        className="CheckoutUserEdit_Header"
        onClickUndo={onClose}
      />
      <div className="CheckoutUserEdit_Container">
        {children}
      </div>
    </ScreenModal>
  );
};
