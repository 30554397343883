import React from 'react';
import {
  Icon,
  Tag,
  TagsInline
} from '@npm-registry/eapteka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilter } from '../../../../../services/redux/store/pharmacies/slice';
import type { FilterKeys } from '../../../../../services/redux/store/pharmacies/types';
import { selectPharmaciesFilters } from '../../../../../services/redux/store/pharmacies/selectors';
import { checkoutDispatchers } from '../../../../../services/dispatchers';
import { FILTER_NAMES } from './const';
import './CheckoutPickupFilters.scss';

const addonsMap = {
  inAnHour: <Icon name="lightning" />,
  installment: <Icon name="bnpl" />,
  sberSpasibo: <Icon name="sberSpasibo" />
};

export const CheckoutPickupFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectPharmaciesFilters);

  const onFilterChange = (filterKey: FilterKeys) => {
    dispatch(updateFilter(filterKey));
    checkoutDispatchers.pickupFilterSelect.dispatch();
  };

  return (
    <TagsInline className="CheckoutPickupFilters_Root">
      {Object.keys(filters).map((filter: FilterKeys) => {
        return (
          <Tag
            addonBefore={addonsMap[filter as keyof typeof addonsMap] || null}
            checked={filters[filter]}
            onClick={() => onFilterChange(filter)}
            label={FILTER_NAMES[filter]}
            key={filter}
            id={filter}
            className={
              'CheckoutPickupFilters_Tab ' + filter
            }
            theme="default"
            size="m"
            variant="outlined"
          />
        );
      })}
    </TagsInline>
  );
};
