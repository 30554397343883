import React, { memo, useMemo } from 'react';
import { Button, Icon } from '@npm-registry/eapteka-ui';
import { BNPLTabs } from '../Tabs/BNPLTabs';
import { useInstallmentContext } from '../InstallmentContext';
import { formatReference } from '../helpers/formatReference';
import banner from '../assets/banner.png';
import './InstallmentBNPL.scss';

const ICON_NAMES = {
  car: 'usualDelivery' as const,
  card: 'card' as const,
  cart: 'cart' as const,
  points: 'bnpl' as const
};

export const BNPLContainer = memo(
  ({ onClose }: { onClose: () => void }) => {
    const { content } = useInstallmentContext();
    const hasContent = !content?.tabs[0].payments;
    const formattedReference = useMemo(() => {
      if (!content) return '';
      return formatReference(
        content.reference,
        content.referenceLinks
      );
    }, [content]);

    if (!content) return null;

    return (
      <div className="InstallmentBNPL_Container">
        <img src={banner} alt="Баннер плати частями" />
        <BNPLTabs />
        {hasContent && (
          <>
            {content.content.map((point) => {
              const icon = ICON_NAMES[point.icon];
              return (
                <div className="InstallmentBNPL_Content">
                  <Icon name={icon} />
                  <p className="InstallmentBNPL_Content_Text">
                    {point.title}
                    {point.text && (
                      <>
                        <br />
                        <small className="InstallmentBNPL_Content_TextSmall">
                          {point.text}
                        </small>
                      </>
                    )}
                  </p>
                </div>
              );
            })}
          </>
        )}
        <small
          className="InstallmentBNPL_Reference"
          dangerouslySetInnerHTML={{
            __html: formattedReference
          }}
        />
        <Button
          onClick={onClose}
          variant="filled"
          fullWidth
        >
          Понятно
        </Button>
      </div>
    );
  }
);
