import config from '../../../config';
import { getTokensFromStorage } from '../../../tools/getTokensFromStorage';
import { apiService } from '../apiService';

export const requestDeliveryIntervals =
  apiService.createPostMethod(config.apiUrl.getDelivery, {
    mapRequest: ({ params }) => ({
      headers: getTokensFromStorage('cartToken'),
      body: JSON.stringify(params)
    })
  });

export const requestDeliveryERecipe =
  apiService.createGetMethod(
    config.apiUrl.deliveryErecipe,
    {
      mapRequest: ({ params }) => ({
        headers: {
          ...getTokensFromStorage('cartToken'),
          features: 'erecipe_new_api_flow'
        },
        body: JSON.stringify(params)
      })
    }
  );
