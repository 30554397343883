import React from 'react';
import { useSelector } from 'react-redux';
import { selectRegion } from '../../../../services/redux/store/region/selectors';
import { formatCurrency } from '../../../../tools/formatters/formatCurrency/formatCurrency';
import { CART_LINK } from '../../../../tools/const';
import { CheckoutReceivingMethodPlaceholder } from '../../../elements/CheckoutReceivingMethodPlaceholder';
import { CheckoutSection } from '../../../elements/CheckoutSection';

export const CorporateDeliveryUnavailableBlock = ({
  isSumLow,
  isDeliveryUnavailable
}) => {
  const { minPriceCompany } = useSelector(selectRegion);

  if (isDeliveryUnavailable) {
    return (
      <CheckoutSection className="CheckoutSection--ReceivingMethods">
        <CheckoutReceivingMethodPlaceholder
          isButtonUnavailable={true}
          title="Доставка пока недоступна в вашем регионе"
          buttonTitle=""
          receiverType="Delivery"
        />
      </CheckoutSection>
    );
  }

  if (isSumLow) {
    return (
      <CheckoutSection className="CheckoutSection--ReceivingMethods">
        <CheckoutReceivingMethodPlaceholder
          linkTo={CART_LINK}
          title={`Доступно при заказе от ${formatCurrency(
            minPriceCompany,
            true
          )}`}
          buttonTitle="Вернуться в корзину"
          receiverType="Corporate"
        />
      </CheckoutSection>
    );
  }

  return null;
};
