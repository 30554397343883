import React from 'react';
import { FieldText } from '@npm-registry/eapteka-ui';
import { CURRENT_REGION_ID, EMOJI_CODES, MAX_COMMENT_LENGTH } from '../../../../../tools/const';
import { Address } from '../../../../../services/redux/store/delivery/types';
import { useDispatch } from 'react-redux';
import { setCurrentAddress } from '../../../../../services/redux/store/delivery/slice';

export const CheckoutReceivingMethodsDeliveryFields = ({
  currentDeliveryAddress,
}: {
  currentDeliveryAddress: Address;
}) => {
  const dispatch = useDispatch();
  const { apartment, floor, entrance, entranceCode, comment } = currentDeliveryAddress;
  const entranceLabel = CURRENT_REGION_ID === 618 ? 'Парадная' : 'Подъезд';

  const onChangeField: React.ComponentProps<"input">["onChange"] = (event) => {
    const { value, name } = event.target;
    const text = value.replace(EMOJI_CODES, '');

    if (text.length > MAX_COMMENT_LENGTH) {
      return;
    }
    dispatch(setCurrentAddress({ ...currentDeliveryAddress, [name]: text }))
  }

  const onChangeTextarea: React.ComponentProps<"textarea">["onChange"] = (event) => {
    const { value } = event.target;
    const text = value.replace(EMOJI_CODES, '');

    if (text.length > MAX_COMMENT_LENGTH) {
      return;
    }

    dispatch(setCurrentAddress({ ...currentDeliveryAddress, comment: text }))
  }

  return (
    <div className="CheckoutReceivingMethodsDelivery_Fields">
      <FieldText name="apartment" label="Кв / офис" value={apartment} onChange={onChangeField} />
      <FieldText name="floor" label="Этаж" value={floor} onChange={onChangeField} />
      <FieldText name="entrance" label={entranceLabel} value={entrance} onChange={onChangeField} />
      <FieldText name="entranceCode" label="Домофон" value={entranceCode} onChange={onChangeField} />
      <div className="CheckoutReceivingMethodsDelivery_Сomment">
        <label htmlFor="ReceivingMethodsDelivery_Сomment" data-filled={!!comment}>
          Комментарий по доставке
        </label>
        <textarea
          id="ReceivingMethodsDelivery_Сomment"
          value={comment}
          name="comment"
          onChange={onChangeTextarea}
          rows={4}
          aria-multiline="true"
        >
          {comment}
        </textarea>
      </div>
    </div>
  );
};
