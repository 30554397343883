import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import type { Payment, PaymentStore, SberspasiboResponse } from './types';
import type { FetchStatus } from '../types';

const initialState = {
  status: FETCH_STATUS.IDLE,
  payment: {
    cards: [],
    errors: [],
    paymentTypes: []
  },
  bonuses: {
    description: '',
    title: '',
    level: '',
    canWithdraw: false,
    isVisible: false,
    isEnabled: false,
    withdraw: 0,
    earn: 0
  },
  cardRemembered: false,
  needChange: 0,
  sberSpasibo: {
    balance: 0,
    earn: 0,
    isAvailable: false,
    isEnabled: false,
    message: '',
    percent: 0,
    state: '',
    status: 'IDLE',
    text: '',
    title: 'СберСпасибо',
    tooltip: '',
    withdraw: 0
  },
  selectedPaymentType: null
} as PaymentStore;

export const paymentSlice = createSlice({
  name: STORE_NAMES.PAYMENT,
  initialState,
  reducers: {
    setPaymentTypes: (state, { payload }: PayloadAction<PaymentStore['payment']>) => {
      state.payment = payload;
    },
    setPaymentStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.status = payload;
    },
    setCurrentPayment: (state, { payload }: PayloadAction<Payment | null>) => {
      state.selectedPaymentType = payload;
    },
    setPaymentNeedChange: (state, { payload }: PayloadAction<number>) => {
      state.needChange = payload;
    },
    setSberLoyalty: (
      state,
      { payload }: PayloadAction<{ balance: number; message: string; state: string }>
    ) => {
      state.sberSpasibo.balance = payload.balance;
      state.sberSpasibo.message = payload.message;
      state.sberSpasibo.state = payload.state;
    },
    setSberspasibo: (state, { payload }: PayloadAction<SberspasiboResponse>) => {
      let title = 'СберСпасибо';

      state.sberSpasibo.isAvailable = payload.is_available;
      state.sberSpasibo.tooltip = payload.tooltip;
      state.sberSpasibo.text = payload.text;
      state.sberSpasibo.withdraw = payload.withdraw;

      if (state.sberSpasibo.balance > 0) {
        if (payload.is_available) {
          title = `Списать ${payload.withdraw} из ${state.sberSpasibo.balance}`;
        } else {
          title = `${payload.withdraw} из ${state.sberSpasibo.balance} бонусов`;
        }
      }
      state.sberSpasibo.title = title;
    },
    setCardRemembered: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.cardRemembered = payload === undefined ? !state.cardRemembered : payload;
    },
    setBonusToggled: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.bonuses.isEnabled = payload === undefined ? !state.bonuses.isEnabled : payload;
    },
    setBonuses: (
      state,
      {
        payload
      }: PayloadAction<{
        withdraw: number;
        earn: number;
        canWithdraw: boolean;
      }>
    ) => {
      state.bonuses = {
        ...state.bonuses,
        earn: payload.earn,
        withdraw: payload.withdraw,
        canWithdraw: payload.canWithdraw
      };
    },
    setBonusesVisbile: (state, { payload }: PayloadAction<boolean>) => {
      state.bonuses.isVisible = payload;
    },
    setBonusesAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.bonuses.isAvailable = payload;
    },
    setBonusesText: (state, { payload }: PayloadAction<{ description: string, title: string, level: string }>) => {
      state.bonuses.description = payload.description;
      state.bonuses.title = payload.title;
      state.bonuses.level = payload.level;
    },
    setSberspasiboToggled: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.sberSpasibo.isEnabled = payload === undefined ? !state.sberSpasibo.isEnabled : payload;
    },
    setSberspasiboPercent: (state, { payload }: PayloadAction<number>) => {
      state.sberSpasibo.percent = payload;
    },
    setSberspasiboStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.sberSpasibo.status = payload;
    },
    setSberspasiboEarn: (state, { payload }: PayloadAction<number>) => {
      state.sberSpasibo.earn = payload;
    }
  }
});

const paymentActions = paymentSlice.actions;
const paymentReducer = paymentSlice.reducer;

export const {
  setCurrentPayment,
  setPaymentStatus,
  setPaymentTypes,
  setCardRemembered,
  setSberLoyalty,
  setSberspasiboEarn,
  setSberspasiboToggled,
  setPaymentNeedChange,
  setBonusToggled,
  setSberspasiboPercent
} = paymentActions;
export { paymentActions, paymentReducer };
