import { Region, RegionResponse } from "../types";

export const serializeRegion = (region: RegionResponse): Region => ({
  active: region.data[0].active,
  chatAvailable: region.data[0].chat_available,
  code: region.data[0].code,
  deliveryCompany: region.data[0].delivery_company,
  features: {
    allHours: region.data[0].features.all_hours,
    bonusProgram: region.data[0].features.bonus_program,
    delivery: region.data[0].features.delivery,
    isInstallmentAvailableInRegion: region.data[0].features.is_installment_available_in_region,
    isNewGate: region.data[0].features.is_new_gate,
    isUserCardsAvailable: region.data[0].features.is_user_cards_available,
    pickup: region.data[0].features.pickup,
  },
  freeDeliveryFrom: region.data[0].free_delivery_from,
  id: region.data[0].id,
  isDeliveryAvailable: region.data[0].is_delivery_available,
  isPickupAvailable: region.data[0].is_pickup_available,
  isVirtual: region.data[0].is_virtual,
  kladr: region.data[0].kladr,
  latitude: region.data[0].latitude,
  license: region.data[0].license,
  longitude: region.data[0].longitude,
  maxPriceCompany: +region.data[0].max_price_company || '',
  minPriceCompany: +region.data[0].min_price_company,
  name: region.data[0].name,
  phone: region.data[0].phone,
  region: region.data[0].region,
  serviceCompany: region.data[0].service_company,
  serviceFee: region.data[0].service_fee,
})