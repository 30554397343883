import React, { FC } from 'react';
import clsx from 'clsx';
import {
  Button,
  Icon,
  Title
} from '@npm-registry/eapteka-ui';
import { ButtonClose } from '../CheckoutButtonClose';
import './CheckoutModalHeader.scss';

interface ModalHeader {
  title: string;
  className?: string;
  onClose: () => void;
}

export const CheckoutModalHeader: FC<ModalHeader> = ({
  title,
  className,
  onClose
}) => {
  const rootClass = clsx(
    'CheckoutModalHeader_Root',
    className
  );
  return (
    <header className={rootClass}>
      <Button
        onClick={onClose}
        aria-label="Назад"
        theme="transparent"
        className="CheckoutModalHeader_Button"
      >
        <Icon
          name="arrowBack"
          size="m"
          className="CheckoutModalHeader_Icon"
        />
      </Button>
      <Title tag="h2">{title}</Title>
      <ButtonClose onClick={onClose} />
    </header>
  );
};
