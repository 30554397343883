import {
  useState,
  useCallback,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegion } from '../../../../services/redux/store/region/selectors';
import { validateINN } from '../../../../tools/validation/validateINN/validateINN';
import { validateKPP } from '../../../../tools/validation/validateKPP/validateKPP';
import { formatINNToString } from '../../../../tools/formatters/formatINNToString/formatINNToString';
import { formatKPPToString } from '../../../../tools/formatters/formatKPPToString/formatKPPToString';
import {
  MAX_COMPANY_LENGTH,
  EMOJI_CODES
} from '../../../../tools/const';
import { selectUserCompany, selectUserIsCorporate } from '../../../../services/redux/store/user/selectors';
import { setUserCompanyINN, setUserCompanyKPP, setUserCompanyName } from '../../../../services/redux/store/user/slice';
import { selectCartSummary } from '../../../../services/redux/store/cart/selectors';

export function useRecipientCorporate() {
  const dispatch = useDispatch();
  const isCorporate = useSelector(selectUserIsCorporate);
  const { inn, kpp, name: corporateName } = useSelector(selectUserCompany);
  const { totalPrice } = useSelector(selectCartSummary);
  const { deliveryCompany: isDeliveryCompany, minPriceCompany } = useSelector(selectRegion)
  const [isVisibleKPP, setIsVisibleKPP] = useState(inn && inn.length === 12);
  const [valueCorporateName, setValueCorporateName] = useState(corporateName);
  const [valueINN, setValueINN] = useState(inn);
  const [valueKPP, setValueKPP] = useState(kpp);
  const [errorCorporateName, setErrorCorporateName] = useState('');
  const [errorINN, setErrorINN] = useState('');
  const [errorKPP, setErrorKPP] = useState('');

  const validateValueKPP = (value) => {
    const { isValid, errorMessage } = validateKPP(value);

    if (isValid) {
      setErrorKPP('');
    } else {
      setErrorKPP(errorMessage);
    }

    return { isValid };
  };

  const validateValueINN = (value) => {
    const { isValid, errorMessage } = validateINN(value);

    if (isValid) {
      setErrorINN('');
    } else {
      setErrorINN(errorMessage);
    }

    return isValid;
  };

  const isCorporateNameValid = (value) => {
    if (!value) {
      setErrorCorporateName('Укажите название компании');
      return false;
    }
    setErrorCorporateName('');
    return true;
  };

  const onChangeCorporateName = useCallback(
    (e) => {
      const corporateNameValue = e.target.value.replace(
        EMOJI_CODES,
        ''
      );

      if (corporateNameValue.length > MAX_COMPANY_LENGTH)
        return;

      setValueCorporateName(corporateNameValue);

      if (errorCorporateName) {
        isCorporateNameValid(corporateNameValue);
      }
    },
    [errorCorporateName]
  );

  const onChangeINN = useCallback(
    (e) => {
      const innValue = formatINNToString(e.target.value);

      setValueINN(innValue);

      if (errorINN) {
        validateValueINN(innValue);
      }

      const strInn =
        innValue && innValue.length > 4
          ? innValue.replace(/\s/g, '')
          : '';

      setIsVisibleKPP(strInn.length === 10);
    },
    [errorINN]
  );

  const onChangeKPP = useCallback(
    (e) => {
      const kppValue = formatKPPToString(e.target.value);
      setValueKPP(kppValue);

      if (errorKPP) {
        validateValueKPP(kppValue);
      }
    },
    [errorKPP]
  );

  const onBlurINN = () => {
    const isValid = validateValueINN(valueINN);
    if (isValid) {
      dispatch(setUserCompanyINN(valueINN));
    }
  };

  const onBlurKPP = () => {
    const { isValid } = validateValueKPP(valueKPP);

    if (isValid) {
      dispatch(setUserCompanyKPP(valueKPP));
    }
  };

  const onBlurCorporateName = () => {
    const isValid  = isCorporateNameValid(
      valueCorporateName
    );
    if (isValid) {
      dispatch(setUserCompanyName(valueCorporateName));
    }
  };

  const isCorporateVisible = useMemo(() => {
    return (
      isCorporate &&
      isDeliveryCompany &&
      minPriceCompany > 0 &&
      totalPrice > 0 &&
      totalPrice >= minPriceCompany
    );
  }, [
    isCorporate,
    isDeliveryCompany,
    minPriceCompany,
    totalPrice
  ]);

  return {
    isCorporateVisible,
    valueCorporateName,
    valueINN,
    valueKPP,
    isVisibleKPP,
    errorINN,
    errorKPP,
    errorCorporateName,
    onChangeCorporateName,
    onChangeINN,
    onChangeKPP,
    onBlurINN,
    onBlurKPP,
    onBlurCorporateName
  };
}
