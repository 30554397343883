import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCartItems } from '../../../services/redux/store/cart/selectors';
import { CheckoutPharmacyOffers } from '../../blocks/CheckoutPharmacy/Offers';
import { selectCurrentPharmacy } from '../../../services/redux/store/pharmacies/selectors';
import { selectReceivingMethod } from '../../../services/redux/store/receiving/selectors';

export const OrderDrawer = () => {
  const offers = useSelector(selectCartItems);
  const { isPickupSelected } = useSelector(selectReceivingMethod);
  const currentPickupPoint = useSelector(selectCurrentPharmacy);

  const unavailableOffers = useMemo(() => {
    if (isPickupSelected && currentPickupPoint) {
      return offers
        .filter((offer) => currentPickupPoint.unavailableOffers.includes(offer.id))
        .map(({ id }) => id);
    }

    return offers.filter((offer) => !offer.isActive).map(({ id }) => id);
  }, [isPickupSelected, currentPickupPoint, offers]);

  return <CheckoutPharmacyOffers unavailableOffers={unavailableOffers} />;
};
