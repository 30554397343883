import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { regionActions } from './slice';
import { getRegionRequest } from '../../../api/endpoints/region/getRegionRequest';
import { serializeRegion } from './serializers/serialize';

export const getRegion = createAsyncThunk(STORE_NAMES.REGION + '_action', async (_, thunkAPI) => {
  thunkAPI.dispatch(regionActions.setRegionStatus(FETCH_STATUS.PENDING));

  await getRegionRequest().then((response) => {
    const region = serializeRegion(response);

    thunkAPI.dispatch(regionActions.setRegion(region));
    thunkAPI.dispatch(regionActions.setRegionStatus(FETCH_STATUS.SUCCESS));
  }).catch((err) => {
    console.error('Ошибка получения данных о регионе: ', err)
    thunkAPI.dispatch(regionActions.setRegionStatus(FETCH_STATUS.ERROR));
  });
});