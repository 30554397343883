import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectReceivingMethod } from '../../../../services/redux/store/receiving/selectors';
import { useDeliveryWarningMessage } from '../../../hooks/useDeliveryWarningMessage';
import { useDeliveryDescription } from '../Delivery/hooks/useDeliveryDescription';
import { Tab, Tabs } from '../../../elements/Tabs';
import {
  RECEIVING_METHODS_KEYS,
  RECEIVING_METHODS_VIEW_TYPE_LABELS
} from './const';
import { CheckoutReceivingMethodsTabItem } from './CheckoutReceivingMethodsTabItem';
import './CheckoutReceivingMethodsTabs.scss';

export const CheckoutReceivingMethodsTabs = ({
  onChange
}) => {
  const { isPickupSelected, isDeliverySelected } = useSelector(selectReceivingMethod);
  const isNothingSelected = !isPickupSelected && !isDeliverySelected;
  const deliveryWarningMessage = useDeliveryWarningMessage();
  const { deliveryDescription, isDeliveryAvailable } = useDeliveryDescription();
  const lockedTabs = !isDeliveryAvailable ? [1] : [];
  const activeTabIndex = isNothingSelected || isPickupSelected ? 0 : 1;

  const onTabClick = useCallback(
    (tabIndex) => {
      if (tabIndex !== activeTabIndex) {
        onChange(RECEIVING_METHODS_KEYS[tabIndex]);
      }
    },
    [activeTabIndex, onChange]
  );

  return (
    <Tabs className="CheckoutReceivingMethodsTabs">
      {Object.values(
        RECEIVING_METHODS_VIEW_TYPE_LABELS
      ).map(({ key, label, subtitle }, index) => (
        <Tab
          key={key}
          isActive={index === activeTabIndex}
          isDisabled={lockedTabs.includes(index)}
          className="CheckoutReceivingMethodsTab"
          onClick={() => onTabClick(index)}
        >
          <CheckoutReceivingMethodsTabItem
            keyValue={key}
            label={label}
            subtitle={subtitle}
            deliveryWarningMessage={deliveryWarningMessage}
            deliveryDescription={deliveryDescription}
          />
        </Tab>
      ))}
    </Tabs>
  );
};
