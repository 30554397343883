import React, { FC, ReactElement, useRef } from 'react';
import { Collapse } from '@npm-registry/eapteka-ui';

interface CheckoutCollapse {
  className?: string;
  isOpen: boolean;
  children: ReactElement;
}

export const CheckoutCollapse: FC<CheckoutCollapse> = ({
  className,
  children,
  isOpen = false
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const height = isOpen
    ? `${ref.current?.firstElementChild?.clientHeight}px`
    : 0;

  return (
    <Collapse
      className={className}
      controlRef={ref}
      style={{ height }}
      isEnter={isOpen}
    >
      {children}
    </Collapse>
  );
};
