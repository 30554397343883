import { throttlePromise } from '@npm-registry/helpers/throttlePromise';
import { requestAddressZoneCheck } from '../geocodes';

export const checkAddressZone = throttlePromise(
  async ({ latitude, longitude }) => {
    if (!latitude || !longitude) {
      console.warn(
        'Не указаны координаты для зоны доставки'
      );
      return {
        zone: null,
        isErecipeDeliveryAllowed: false
      };
    }

    try {
      return await requestAddressZoneCheck({
        latitude,
        longitude
      });
    } catch (error) {
      console.warn(
        'Ошибка в проверке адреса по зоне доставки',
        JSON.parse(error.config.data),
        error.response.data
      );
    }
  },
  5000
);
