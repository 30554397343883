import config from '../../../../config';
import type { HandbookSberResponse, HandbookWebResponse } from '../../../redux/store/handbook/types';
import { API_SERVICE } from '../../api';

export const getSberFeaturesRequest = API_SERVICE.createGetMethod<
  undefined,
  HandbookSberResponse
>(config.apiUrl.handbookSber);

export const getWebFeaturesRequest = API_SERVICE.createGetMethod<
  undefined,
  HandbookWebResponse
>(config.apiUrl.handbook);
