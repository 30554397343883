/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useEffect } from 'react';
import { Skeleton } from '@npm-registry/eapteka-ui';
import { CheckoutPickupMapSearchBar } from '../CheckoutPickup/Overlay/SearchBar';
import { useAddressSearchSuggestions } from './hooks/useAddressSearchSuggestions';
import { formatSuggestion } from './helpers/formatSuggestion';
import { formatLocality } from './helpers/formatLocality';
import { ACTIONS } from './helpers/arrowNavigation';
import errorBackground from './../../../assets/images/search-error-bg.png';
import type { Suggestion } from './hooks/types';

import './CheckoutAddressSearch.scss';

const SkeletonsListComponent = () =>
  new Array(4).fill('40px').map((height, index) => (
    <div
      key={index}
      className="CheckoutAddressSearch_addressItem"
    >
      <Skeleton height={height} />
    </div>
  ));

const ListPreloader = SkeletonsListComponent();

/**
 * Поисковая адресная строка с эффектом появления
 * @param {Function} onAddressSelect - коллбэк, возвращающий объект: { country, locality, city, street, building, label, coordinates: { longitude, latitude }}.
 */

type AddressSearch = {
  // eslint-disable-next-line no-unused-vars
  onAddressSelect: (suggestion: Suggestion) => void;
  hasLabel: boolean;
}
export const CheckoutAddressSearch = ({
  onAddressSelect,
  hasLabel
}: AddressSearch) => {
  const container = useRef(null);
  const searchBlockRef = useRef(null);
  const inputRef = useRef(null);
  const {
    onClear,
    isPending,
    suggestions,
    searchQuery,
    errorMessage,
    onSearchQuery,
    onSuggestionSelect
  } = useAddressSearchSuggestions({
    onAddressSelect
  });

  const switchFocus = (e: KeyboardEvent) => {
    const firstChild = container.current?.firstChild;
    const isSuggestionVisible =
      firstChild?.className ===
      'CheckoutAddressSearch_addressItem';

    if (
      e.key === 'ArrowDown' &&
      isSuggestionVisible &&
      firstChild
    ) {
      e.preventDefault();
      firstChild.focus();
    }
  };

  const handleSuggestFocus = (e: KeyboardEvent, suggestion: Suggestion) => {
    // @ts-ignore
    const handler = ACTIONS[e.key];
    if (handler) {
      e.preventDefault();
      handler(e);
    } else if (e.key === 'Enter') {
      onSuggestionSelect(suggestion).finally(() => {
        if (!suggestion.building) {
          e.preventDefault();
          inputRef.current.focus();
        }
      });
    }
  };
  const isContentVisible =
    (suggestions.length > 0 && searchQuery.length > 0) ||
    isPending ||
    errorMessage !== null;

  useEffect(() => {
    const handlerClickOutside = (event: MouseEvent) => {
      if (
        container.current &&
        !container.current.contains(event.target) &&
        !searchBlockRef.current.contains(event.target)
      ) {
        onClear();
        inputRef.current.focus();
      }
    };

    const onKeyDownEscape = (event: React.ComponentProps<"input">) => {
      if (event.key === 'Escape' && isContentVisible) {
        onClear();
        inputRef.current.focus();
      }
    };

    document.addEventListener('click', handlerClickOutside);
    document.addEventListener('keydown', onKeyDownEscape);

    return () => {
      document.removeEventListener(
        'click',
        handlerClickOutside
      );
      document.removeEventListener(
        'keydown',
        onKeyDownEscape
      );
    };
  }, [isContentVisible, onClear]);

  return (
    <div
      className="CheckoutAddressSearch_Root"
      ref={searchBlockRef}
    >
      <CheckoutPickupMapSearchBar
        inputRef={inputRef}
        hasLabel={hasLabel}
        onChange={onSearchQuery}
        onKeyDown={switchFocus}
        value={searchQuery}
        onClear={onClear}
        isInvalid={!!errorMessage}
        message={errorMessage}
      />
      {isContentVisible && (
        <div
          className="CheckoutAddressSearch_content"
          ref={container}
        >
          {errorMessage && (
            <div className="CheckoutAddressSearch_error">
              <img
                src={errorBackground}
                alt={errorMessage}
              />
              <p>{errorMessage}</p>
            </div>
          )}
          {isPending
            ? ListPreloader
            : suggestions.map((suggestion) => (
                <button
                  key={suggestion.label}
                  type="button"
                  className="CheckoutAddressSearch_addressItem"
                  onClick={() =>
                    onSuggestionSelect(suggestion)
                  }
                  onKeyDown={(e) =>
                    // @ts-ignore
                    handleSuggestFocus(e, suggestion)
                  }
                >
                  {formatSuggestion(suggestion)}
                  <br />
                  <span className="CheckoutAddressSearch_addressItem_subtext">
                    {formatLocality(suggestion)}
                  </span>
                </button>
              ))}
        </div>
      )}
    </div>
  );
};
