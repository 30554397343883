import React from 'react';
import { Title } from '@npm-registry/eapteka-ui';
import { useSelector } from 'react-redux';
import { selectRegionFeatures } from '../../../../services/redux/store/region/selectors';
import { useDeliveryDescription } from '../Delivery/hooks/useDeliveryDescription';
import { CheckoutReceivingMethodsTabs } from '../Tabs/CheckoutReceivingMethodsTabs';
import { RECEIVING_METHODS_VIEW_TYPE_LABELS, RECEIVING_METHODS_VIEW_TYPE_KEYS  } from '../Tabs/const';
import { selectUserIsCorporate } from '../../../../services/redux/store/user/selectors';
import './DeliveryTypeSelector.scss';

export const DeliveryTypeSelector = ({
  onChange
}) => {
  const isCorporate = useSelector(selectUserIsCorporate);
  const {
    delivery: isDeliveryAvailableInRegionForIndividual,
    pickup: isPickupAvailableInRegionForIndividual
  } = useSelector(selectRegionFeatures);

  const { deliveryDescription } = useDeliveryDescription();

  const pickupLabel =
    RECEIVING_METHODS_VIEW_TYPE_LABELS[
      RECEIVING_METHODS_VIEW_TYPE_KEYS.pickup
    ];
  const deliveryLabel =
    RECEIVING_METHODS_VIEW_TYPE_LABELS[
      RECEIVING_METHODS_VIEW_TYPE_KEYS.delivery
    ];
  const isDeliveryTypeSelectorRequired =
    isDeliveryAvailableInRegionForIndividual &&
    isPickupAvailableInRegionForIndividual;

  return (
    <>
      {!isDeliveryTypeSelectorRequired &&
        isPickupAvailableInRegionForIndividual && (
          <Title
            className="DeliveryTypeSelectorTitle"
            tag="h3"
          >
            {pickupLabel.label} - {pickupLabel.subtitle}
          </Title>
        )}
      {!isDeliveryTypeSelectorRequired &&
        isDeliveryAvailableInRegionForIndividual && (
          <Title
            className="DeliveryTypeSelectorTitle"
            tag="h3"
          >
            {deliveryLabel.label} -{' '}
            {deliveryDescription || deliveryLabel.subtitle}
          </Title>
        )}
      {isDeliveryTypeSelectorRequired && !isCorporate && (
        <CheckoutReceivingMethodsTabs
          onChange={onChange}
        />
      )}
    </>
  );
};
