import { useEffect, useState } from 'react';
import { validateEmail } from '../../../../tools/validation/validateEmail/validateEmail';
import { UserFormFields } from '../consts';

export function useFormEmail(email, callback) {
  const [hasErrorEmail, setHasErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] =
    useState('');

  useEffect(() => {
    const { hasError, errorMessage } = validateEmail(email);

    setHasErrorEmail(hasError);
    setErrorEmailMessage(errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email.hasError, email.errorMessage]);

  const onBlurEmail = () => {
    const { hasError, errorMessage } = validateEmail(email);

    setHasErrorEmail(hasError);
    setErrorEmailMessage(errorMessage);

    callback(email);

    return [UserFormFields.email, email, hasError];
  };

  const onValidateEmail = () => {
    const { hasError, errorMessage } = validateEmail(email);

    setHasErrorEmail(hasError);
    setErrorEmailMessage(errorMessage);
  };

  return {
    onBlurEmail,
    onValidateEmail,
    hasErrorEmail,
    errorEmailMessage
  };
}
