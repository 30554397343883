import React from 'react';
import { MapGLContextProvider } from '../../../core/2GIS/MapGLContext';
import { CheckoutPickupMapPlacemarks } from './Placemarks/CheckoutPickupMapPlacemarks';
import {
  MapControls,
  MapControlsGeolocation,
  MapControlsZoom,
  MapGL,
  MapGLUserMarker
} from '../../../core/2GIS';

export const CheckoutPickupMap = () => {
  return (
    <MapGLContextProvider>
      <CheckoutPickupMapPlacemarks />
      <MapGLUserMarker />
      <MapGL />
      <MapControls>
        <MapControlsZoom />
        <MapControlsGeolocation />
      </MapControls>
    </MapGLContextProvider>
  );
};
