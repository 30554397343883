import React from 'react';
import { Checkbox, Typography } from '@npm-registry/eapteka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectWebFeatures } from '../../../../services/redux/store/handbook/selectors';
import { CheckoutTooltip } from '../../../elements/CheckoutTooltip';
import {
  selectCardRememberdToggleState,
  selectPayment
} from '../../../../services/redux/store/payment/selectors';
import { setCardRemembered } from '../../../../services/redux/store/payment/slice';
import './CheckoutPaymentSaveCard.scss';

export const CheckoutPaymentSaveCard = () => {
  const dispatch = useDispatch();
  const { isCardTokensEnabled } = useSelector(selectWebFeatures);
  const isCardRemembered = useSelector(selectCardRememberdToggleState);
  const { paymentTypes } = useSelector(selectPayment);
  const avaibableMethods = paymentTypes.filter((method) => method.isAvailable);
  const isOnlineMethodAvailable = avaibableMethods.some((method) => method.code === 'card');
  const text = 'Запоминать карты для\u00A0будущих\u00A0покупок';
  const notice =
    'Это безопасно. Мы получаем данные карты в зашифрованном виде, а все операции проходят на стороне банка';

  const handleChange = () => {
    dispatch(setCardRemembered());
  };

  if (!isCardTokensEnabled || !isOnlineMethodAvailable) return null;

  return (
    <div className="CheckoutPaymentSaveCard_Root">
      <label className="CheckoutPaymentSaveCard_Label">
        <Checkbox checked={isCardRemembered} onChange={handleChange} />
        <Typography className="CheckoutPaymentSaveCard_Text">{text}</Typography>
      </label>
      <CheckoutTooltip message={notice} iconName="questionCircleFilled" />
    </div>
  );
};
