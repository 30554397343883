function checkDigit(value, coefficients) {
  let n = 0;

  for (let i in coefficients) {
    n += coefficients[i] * value[i];
  }

  return parseInt((n % 11) % 10);
}

export function validateINN(value = '') {
  value.toString();
  value = value.replace(/\s/g, '');

  const valueLength = value.length;

  if (!valueLength) {
    return { isValid: false, errorMessage: 'Укажите ИНН' };
  }

  if (/[^0-9]/.test(value)) {
    return {
      isValid: false,
      errorMessage: 'ИНН должен состоять из 10 или 12 цифр'
    };
  }

  if (value[0] === '0') {
    return {
      isValid: false,
      errorMessage:
        'ИНН не найден, проверьте номер на ошибки'
    };
  }

  if (valueLength !== 10 && valueLength !== 12) {
    return {
      isValid: false,
      errorMessage: 'ИНН должен состоять из 10 или 12 цифр'
    };
  }

  if (valueLength === 10) {
    const n10 = checkDigit(
      value,
      [2, 4, 10, 3, 5, 9, 4, 6, 8]
    );
    const isValid10 = n10 === parseInt(value[9]);

    if (isValid10) {
      return { isValid: true, errorMessage: '' };
    } else {
      return {
        isValid: false,
        errorMessage:
          'ИНН не найден, проверьте номер на ошибки'
      };
    }
  }

  if (valueLength === 12) {
    const n11 = checkDigit(
      value,
      [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
    );
    const n12 = checkDigit(
      value,
      [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
    );
    const isValid12 =
      n11 === parseInt(value[10]) &&
      n12 === parseInt(value[11]);

    if (isValid12) {
      return { isValid: true, errorMessage: '' };
    } else {
      return {
        isValid: false,
        errorMessage:
          'ИНН не найден, проверьте номер на ошибки'
      };
    }
  }
}
