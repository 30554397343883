import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@npm-registry/eapteka-ui';
import { selectUserIsCorporate } from '../../../../services/redux/store/user/selectors';
import { useIntervalDescription } from '../Delivery/hooks/useIntervalDescription';
import { CheckoutSectionHeader } from '../../../elements/CheckoutSectionHeader';
import { CheckoutSection } from '../../../elements/CheckoutSection';
import { CheckoutDeliveryDatetime } from '../../CheckoutDelivery/Datetime';
import { selectReceivingMethod } from '../../../../services/redux/store/receiving/selectors';
import { selectUIDelivery } from '../../../../services/redux/store/ui/selectors';
import { selectCurrentAddress } from '../../../../services/redux/store/delivery/selectors';
import { setIsDeliveryIntervalModalOpen } from '../../../../services/redux/store/ui/slice';

export const CheckoutReceivingMethodsDeliveryDatetime =
  () => {
    const dispatch = useDispatch();
    const isCorporate = useSelector(selectUserIsCorporate)
    const { isDeliveryIntervalModalOpen } = useSelector(selectUIDelivery);
    const { isPickupSelected } = useSelector(selectReceivingMethod);
    const currentDeliveryAddress = useSelector(selectCurrentAddress);
    const { isIntervalSelected, intervalDescription } = useIntervalDescription();
    const { isAddressUnavailable } = { isAddressUnavailable: false }

    const title = isIntervalSelected
      ? 'Время доставки'
      : '';
    const subtitle = isIntervalSelected
      ? intervalDescription
      : 'Выбрать время доставки';
    const buttonTitle = isIntervalSelected
      ? 'Изменить'
      : 'Добавить';

    if (
      (isPickupSelected && !isCorporate) ||
      !currentDeliveryAddress ||
      isAddressUnavailable
    )
      return null;

    return (
      <CheckoutSection className="Datetime">
        <CheckoutSectionHeader
          image="time"
          subtitle={subtitle}
          isLightTitle
        >
          {title}
        </CheckoutSectionHeader>
        <Button
          theme="transparent"
          size="s"
          onClick={() => dispatch(setIsDeliveryIntervalModalOpen(true))}
        >
          {buttonTitle}
        </Button>
        {isDeliveryIntervalModalOpen && (
          <CheckoutDeliveryDatetime />
        )}
      </CheckoutSection>
    );
  };
