import config from '../../../../config';
import {
  PickupPointCurrentResponse,
  PickupPointResponse
} from '../../../redux/store/pharmacies/types';
import { API_SERVICE } from '../../api';

export const getCurrentPickupPointsRequest =
  API_SERVICE.createGetMethod<
    undefined,
    PickupPointCurrentResponse
  >(config.apiUrl.getCurrentPickupPoints, {
    mapRequest: () => ({
      headers: {
        Accept: 'application/json, text/plain, */*',
        features: 'multicart'
      }
    })
  });

export const getPickupPointsRequest =
  API_SERVICE.createGetMethod<
    undefined,
    PickupPointResponse
  >(config.apiUrl.getAllPickupPoints);
