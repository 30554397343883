import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import { usePickupPointsSort } from './usePickupPointsSort';
import { useSelector } from 'react-redux';
import { selectEnabledFilters, selectFilteredPharmacies } from '../../../../../services/redux/store/pharmacies/selectors';

const PICKUP_LIST_PAGE_BASE_ITEMS_COUNT = 7;
const PICKUP_LIST_PAGE_ITEMS_COUNT = 20;

export function usePickupPointsList() {
  const [currentPage, setCurrentPage] = useState(0);
  const currentPageRef = useRef(0);

  const filtersEnabled = useSelector(selectEnabledFilters);
  const filteredPickupPoints = useSelector(selectFilteredPharmacies);
  const pickupPointsSorted = usePickupPointsSort(filteredPickupPoints);


  const onShowMore = useCallback(() => {
    currentPageRef.current += 1;

    setCurrentPage(currentPageRef.current);
  }, []);

  const isUpdated = useRef(false);
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const isIntersectionsUpdate = entries.some(
          (entry) => entry.isIntersecting
        );

        if (isIntersectionsUpdate && !isUpdated.current) {
          isUpdated.current = true;
          onShowMore();
        }
      },
      {
        rootMargin: '0px',
        threshold: 0
      }
    )
  );

  const [paginationElement, setPaginationElement] =
    useState();

  const visibleItemsCount = useMemo(() => {
    return (
      PICKUP_LIST_PAGE_BASE_ITEMS_COUNT +
      PICKUP_LIST_PAGE_ITEMS_COUNT * currentPage
    );
  }, [currentPage]);

  useEffect(() => {
    isUpdated.current = false;
  }, [currentPage]);

  useEffect(() => {
    currentPageRef.current = 0;
    setCurrentPage(0);
  }, [filtersEnabled]);

  useEffect(() => {
    const curPaginationElement = paginationElement;
    const currentElem = observer.current;
    if (curPaginationElement) {
      currentElem.observe(curPaginationElement);
    }

    return () => {
      if (curPaginationElement) {
        currentElem.unobserve(curPaginationElement);
      }
    };
  }, [paginationElement]);

  return {
    setPaginationElement,
    pickupPointsVisible: pickupPointsSorted.slice(
      0,
      visibleItemsCount
    ),
    onShowMore
  };
}
