import { createAsyncThunk } from "@reduxjs/toolkit";
import { FETCH_STATUS, STORE_NAMES } from "../consts";
import { deliveryActions } from "./slice";
import { getAddressesRequest } from "../../../api/endpoints/delivery/getDeliveryRequest";
import { serializeDeliveryAddresses } from "./serializers";
import { checkoutDispatchers } from "../../../dispatchers";

export const getAddresses = createAsyncThunk(STORE_NAMES.DELIVERY + "_addresses", async (regionId: number, thunkAPI) => {
  thunkAPI.dispatch(deliveryActions.setStatus(FETCH_STATUS.PENDING));

  await getAddressesRequest().then((response) => {
    const addresses = serializeDeliveryAddresses(response, regionId);

    thunkAPI.dispatch(deliveryActions.setAddresses(addresses));
    thunkAPI.dispatch(deliveryActions.setStatus(FETCH_STATUS.SUCCESS));
  }).catch(err => {
    console.error('Ошибка получения addresses: ', err);
    thunkAPI.dispatch(deliveryActions.setStatus(FETCH_STATUS.ERROR));
    checkoutDispatchers.errorTechnical.dispatch();
  });
});
