import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { Cart, CartStore } from './types';
import { FetchStatus } from '../types';

const initialState = {
  status: FETCH_STATUS.IDLE,
  cart: {
    bonusProgram: {
      canWithdraw: false,
      earn: 0,
      reasonNotWithdraw: '',
      withdraw: 0
    },
    checkout: {
      legalInfo: '',
      legalLinks: [],
      erecipeMessage: ''
    },
    data: {
      hasERecipes: false,
      hasItemBenefit: false,
      hasItemBenefitOnly: false,
      hasItemPreorder: false,
      hasItemRecipeDeliveryAllowed: false,
      hasItemWithAlcohol: false,
      hasItemWithPku: false,
      hasItemWithRecipe: false,
      hasItemWithRecipeOnly: false,
      hasOnlinePayment: false,
    },
    items: [],
    availableItems: [],
    unavailableItems: [],
    offersData: {
      availableOffersPrice: 0,
      availableOffersCount: 0,
      availablePositionsCount: 0,
      totalOffersCount: 0,
      totalPositionsCount: 0,
      unavailableOffersPrice:0,
      unavailableOffersCount: 0,
      unavailablePositionsCount: 0,
    },
    summary: {
      discount: 0,
      promocodeAmount: 0,
      totalPrice: 0,
      totalPriceComponents: {
        availableOffersPrice: 0,
        unavailableOffersPrice: 0,
        bonusWithdraw: 0,
        deliveryPrice: 0,
        fee: 0,
        spasiboWithdraw: 0,
      },
    },
    token: ''
  },
} as CartStore;

export const cartSlice = createSlice({
  name: STORE_NAMES.CART,
  initialState,
  reducers: {
    setCart: (state, { payload }: PayloadAction<Cart>) => {
      state.cart = payload;
    },
    setCartStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.status = payload;
    },
    setCartHasERecipe: (state, { payload }: { payload: boolean }) => {
      state.cart.data.hasERecipes = payload;
    },
    setCartERecipe: (state, { payload }: { payload: { result: boolean; message: string } }) => {
      state.cart.data.hasERecipes = payload.result;
      state.cart.checkout.erecipeMessage = payload.message;
    },
    setTotalOffersCount: (state, { payload }: PayloadAction<number>) => {
      state.cart.offersData.totalOffersCount = payload;
    },
    setTotalPositionsCount: (state, { payload }: PayloadAction<number>) => {
      state.cart.offersData.totalPositionsCount = payload;
    },
    setAvailableOffersCount: (state, { payload }: PayloadAction<number>) => {
      state.cart.offersData.availableOffersCount = payload;
    },
    setAvailablePositionsCount: (state, { payload }: PayloadAction<number>) => {
      state.cart.offersData.availablePositionsCount = payload;
    },
    setUnavailableOffersCount: (state, { payload }: PayloadAction<number>) => {
      state.cart.offersData.unavailableOffersCount = payload;
    },
    setUnavailablePositionsCount: (state, { payload }: PayloadAction<number>) => {
      state.cart.offersData.unavailablePositionsCount = payload;
    },
    setSummaryFee: (state, { payload }: PayloadAction<number>) => {
      state.cart.summary.totalPriceComponents.fee = payload;
    },
    setSummaryBonusWithdraw: (state, { payload }: PayloadAction<number>) => {
      state.cart.summary.totalPriceComponents.bonusWithdraw = payload;
    },
    setSummarySpasiboWithdraw: (state, { payload }: PayloadAction<number>) => {
      state.cart.summary.totalPriceComponents.spasiboWithdraw = payload;
    },
    setSummaryDeliveryPrice: (state, { payload }: PayloadAction<number>) => {
      state.cart.summary.totalPriceComponents.deliveryPrice = payload;
    },
    setSummaryAvailableOffersPrice: (state, { payload }: PayloadAction<number>) => {
      state.cart.summary.totalPriceComponents.availableOffersPrice = payload;
    },
    setSummaryDiscount: (state, { payload }: PayloadAction<number>) => {
      state.cart.summary.discount = Math.round(payload);
    },
    setSummaryUnavailablePrice: (state, { payload }: PayloadAction<number>) => {
      state.cart.summary.totalPriceComponents.unavailableOffersPrice = payload;
    },
    setCartTotalPrice: (state, { payload }: PayloadAction<number>) => {
      state.cart.summary.totalPrice = Math.round(payload);
    },
  }
});

const cartReducer = cartSlice.reducer;
const cartActions = cartSlice.actions;

export const {
  setAvailableOffersCount,
  setTotalOffersCount,
  setTotalPositionsCount,
  setAvailablePositionsCount,
  setUnavailablePositionsCount,
  setUnavailableOffersCount,
  setSummaryDiscount,
  setSummaryFee,
  setSummaryUnavailablePrice,
  setSummaryAvailableOffersPrice,
  setCartTotalPrice,
  setSummaryBonusWithdraw,
  setSummaryDeliveryPrice,
  setSummarySpasiboWithdraw
} = cartActions;
export { cartReducer, cartActions };
