import React from 'react';
import {
  Button,
  Icon,
  Link
} from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import './CheckoutHeader.scss';

export const CheckoutHeader = ({
  onClickUndo,
  children,
  title = '',
  className
}) => {
  const CheckoutHeaderClass = clsx(
    'CheckoutHeader_Root',
    className
  );
  return (
    <header className={CheckoutHeaderClass}>
      <Button
        onClick={onClickUndo}
        aria-label="Назад"
        theme="transparent"
        className="CheckoutHeader_Button"
      >
        <Icon
          name="arrowBack"
          size="m"
          className="CheckoutHeader_Icon"
        />
        Назад
      </Button>
      {title && (
        <h1 className="CheckoutHeader_Heading">{title}</h1>
      )}
      {children}
      <p className="CheckoutHeader_Text">
        Помощь покупателю
        <br />
        <b>
          <Link variant="black" href="tel:+74957907711">
            8 495 790 77 11
          </Link>
        </b>
      </p>
    </header>
  );
};
