import config from '../../../../config';
import {
  LoyaltyResponse,
  SberspasiboEarnResponse,
  SberspasiboPayload,
  SberspasiboResponse
} from '../../../redux/store/payment/types';
import { API_SERVICE } from '../../api';

export const getLoyaltyRequest = API_SERVICE.createGetMethod<string | undefined, LoyaltyResponse>(
  config.apiUrl.userLoyalty,
  {
    mapRequest: (bindingId, { currentUrl }) => ({
      url: bindingId ? `${currentUrl}?binding_id=${bindingId}` : currentUrl
    })
  }
);

export const postSberspasiboRequest = API_SERVICE.createPostMethod<
  SberspasiboPayload,
  SberspasiboResponse
>(config.apiUrl.sberspasibo, {
  mapRequest: (resquestData, { currentUrl }) => ({
    url: currentUrl,
    body: JSON.stringify(resquestData)
  })
});

export const getSberspasiboEarnRequest = API_SERVICE.createGetMethod<
  number,
  SberspasiboEarnResponse
>(config.apiUrl.sberspasiboEarn, {
  mapRequest: (totalSum: number, { currentUrl }) => ({
    url: currentUrl + totalSum
  })
});
