// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgMUM5IDAuNDQ3NzE1IDguNTUyMjggMCA4IDBDNy40NDc3MSAwIDcgMC40NDc3MTUgNyAxVjdIMUMwLjQ0NzcxNSA3IDAgNy40NDc3MSAwIDhDMCA4LjU1MjI4IDAuNDQ3NzE1IDkgMSA5SDdWMTVDNyAxNS41NTIzIDcuNDQ3NzEgMTYgOCAxNkM4LjU1MjI4IDE2IDkgMTUuNTUyMyA5IDE1VjlIMTVDMTUuNTUyMyA5IDE2IDguNTUyMjkgMTYgOEMxNiA3LjQ0NzcyIDE1LjU1MjMgNyAxNSA3SDlWMVoiIGZpbGw9IiMzMzNGNDgiLz4KPC9zdmc+Cg==", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDE2IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjE2IiB3aWR0aD0iMiIgaGVpZ2h0PSIxNiIgcng9IjEiIHRyYW5zZm9ybT0icm90YXRlKDkwIDE2IDApIiBmaWxsPSIjMzMzRjQ4Ii8+Cjwvc3ZnPgo=", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapControlsZoom_In::after{width:16px;height:16px;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.MapControlsZoom_Out::after{width:16px;height:2px;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___})}`, "",{"version":3,"sources":["webpack://./src/components/core/2GIS/Controls/Zoom/MapControlsZoom.scss"],"names":[],"mappings":"AACE,2BACE,UAAA,CACA,WAAA,CAEA,wDAAA,CAGF,4BACE,UAAA,CACA,UAAA,CAEA,wDAAA","sourcesContent":[".MapControlsZoom {\n  &_In::after {\n    width: 16px;\n    height: 16px;\n\n    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgMUM5IDAuNDQ3NzE1IDguNTUyMjggMCA4IDBDNy40NDc3MSAwIDcgMC40NDc3MTUgNyAxVjdIMUMwLjQ0NzcxNSA3IDAgNy40NDc3MSAwIDhDMCA4LjU1MjI4IDAuNDQ3NzE1IDkgMSA5SDdWMTVDNyAxNS41NTIzIDcuNDQ3NzEgMTYgOCAxNkM4LjU1MjI4IDE2IDkgMTUuNTUyMyA5IDE1VjlIMTVDMTUuNTUyMyA5IDE2IDguNTUyMjkgMTYgOEMxNiA3LjQ0NzcyIDE1LjU1MjMgNyAxNSA3SDlWMVoiIGZpbGw9IiMzMzNGNDgiLz4KPC9zdmc+Cg==');\n  }\n\n  &_Out::after {\n    width: 16px;\n    height: 2px;\n\n    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDE2IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjE2IiB3aWR0aD0iMiIgaGVpZ2h0PSIxNiIgcng9IjEiIHRyYW5zZm9ybT0icm90YXRlKDkwIDE2IDApIiBmaWxsPSIjMzMzRjQ4Ii8+Cjwvc3ZnPgo=');\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
