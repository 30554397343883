import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkoutDispatchers } from '../../../../../../services/dispatchers';
import { pickupButtonTypes } from './consts';
import { setIsMapView, setIsPickupScreenOpen } from '../../../../../../services/redux/store/ui/slice';
import { selectCurrentPharmacy } from '../../../../../../services/redux/store/pharmacies/selectors';
import { selectReceivingMethod } from '../../../../../../services/redux/store/receiving/selectors';
import { setReceivingMethodPickup } from '../../../../../../services/redux/store/receiving/slice';

export function useReceivingMethodPickup() {
  const dispatch = useDispatch();
  const pharmacy = useSelector(selectCurrentPharmacy);
  const { isPickupAvailable } = pharmacy || {};
  const { isPickupSelected } = useSelector(selectReceivingMethod);

  const onPickupEdit = useCallback(
    (
      buttonType = pickupButtonTypes.selectPharmacy,
      sendTelemetryEvent = true
    ) => {
      if (sendTelemetryEvent) {
        checkoutDispatchers.pickupPharmacyChange.dispatch(
          buttonType
        );
      }
      checkoutDispatchers.notificationsClear.dispatch();
      dispatch(setIsPickupScreenOpen(true));
    },
    [dispatch]
  );

  const onPickupScreenOpen = useCallback(() => {
    checkoutDispatchers.notificationsClear.dispatch();
    if (
      !isPickupSelected &&
      pharmacy &&
      isPickupAvailable
    ) {
      dispatch(setReceivingMethodPickup());
    } else {
      dispatch(setIsPickupScreenOpen(!isPickupSelected));
    }
    checkoutDispatchers.receivingMethodPickupSelect.dispatch();
  }, [isPickupSelected, pharmacy, isPickupAvailable, dispatch]);

  const onPickupScreenClose = useCallback(() => {
    dispatch(setIsMapView(true));
    dispatch(setIsPickupScreenOpen(false));
  }, [dispatch]);

  return {
    onPickupEdit,
    onPickupScreenOpen,
    onPickupScreenClose
  };
}
