import React from 'react';
import clsx from 'clsx';
import {
  Delivery,
  RadioButton
} from '@npm-registry/eapteka-ui';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../services/redux/store/user/selectors';
import { formatCurrency } from '../../../../../../tools/formatters/formatCurrency/formatCurrency';
import './CheckoutDeliveryInterval.scss';

export const CheckoutDeliveryInterval = ({
  description,
  isFastDelivery,
  isSelected,
  onClick,
  price
}) => {
  const user = useSelector(selectUser);
  const isSberPrimeFreeDeliveryDisabled = user.sberprime?.delivery.freeDisabled;
  const isDeliveryFree = price === 0;
  const rootClassName = clsx(
    'CheckoutDeliveryInterval_Root',
    {
      'CheckoutDeliveryInterval_Root--selected': isSelected
    }
  );
  const priceClassName = clsx(
    'CheckoutDeliveryInterval_Price',
    { 'Price--free': isDeliveryFree }
  );
  const priceTag = isDeliveryFree || !isSberPrimeFreeDeliveryDisabled
    ? 'Бесплатно'
    : formatCurrency(price, true);

  return (
    <label className={rootClassName}>
      <RadioButton
        className="CheckoutDeliveryInterval_Radio"
        value={description}
        checked={isSelected}
        onChange={onClick}
      />
      {isFastDelivery ? (
        <Delivery
          variant="quick"
          className="CheckoutDeliveryInterval_Time"
        />
      ) : (
        <span className="CheckoutDeliveryInterval_Time">
          {description}
        </span>
      )}
      <span className={priceClassName}>{priceTag}</span>
    </label>
  );
};
