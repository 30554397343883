import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldText, Title, Button, Icon, Typography } from '@npm-registry/eapteka-ui';
import { CheckoutAddressSearch } from '../../CheckoutAddressSearch';
import { MapGLDispatcher } from '../../../core/2GIS/helpers/dispatcher';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAddress, selectDeliveryAddresses } from '../../../../services/redux/store/delivery/selectors';
import {
  addAddress,
  deleteAddress,
  setCurrentAddress,
  setIntervalStatus
} from '../../../../services/redux/store/delivery/slice';
import type {
  Address,
  AddressResponse,
  PostAddressPayload,
  PutAddressPayload
} from '../../../../services/redux/store/delivery/types';
import {
  getAddressZone,
  postAddressRequest,
  putAddressRequest
} from '../../../../services/api/endpoints/delivery/getDeliveryRequest';
import {
  deserializeDeliveryAddress,
  formatAddress,
  serializeDeliveryAddress
} from '../../../../services/redux/store/delivery/serializers';
import { setIsDeliveryIntervalModalOpen, setIsDeliveryScreenOpen } from '../../../../services/redux/store/ui/slice';
import { CURRENT_REGION_ID, EMOJI_CODES, MAX_FIELD_LENGTH } from '../../../../tools/const';
import { Suggestion } from '../../CheckoutAddressSearch/hooks/types';
import { GeocodeAddress } from '../../../core/2GIS/MapGLUserMarker';
import { createNotification } from '../../../../services/notifications';

const defaultAddressState = {
  address: '',
  addressCity: '',
  addressCountry: '',
  addressHouse: '',
  addressStreet: '',
  apartment: '',
  comment: '',
  entrance: '',
  entranceCode: '',
  fastDelivery: false,
  floor: '',
  fullAddress: '',
  id: 0,
  lastDeliveryDate: '',
  latitude: 0,
  longitude: 0,
  name: '',
  regionId: CURRENT_REGION_ID,
  zoneId: ''
} as Address;

export const CheckoutDeliveryAddressEditForm = () => {
  const dispatch = useDispatch();
  const currentDeliveryAddress = useSelector(selectCurrentAddress);
  const defaultAddress = currentDeliveryAddress?.id ? currentDeliveryAddress : defaultAddressState;
  const [address, setAddress] = useState(defaultAddress);
  const [isSaving, setSaving] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const isAllowedToDelete = useSelector(selectDeliveryAddresses).length > 1;
  const payload = useMemo(() => deserializeDeliveryAddress(address), [address]);

  const updateEditedAddress = useCallback(async () => {
    const { result } = await postAddressRequest(payload as PostAddressPayload);

    if (result) {
      setSaving(false);

      dispatch(setCurrentAddress({ ...address, comment: '', fullAddress: formatAddress(payload as AddressResponse) }));
      dispatch(setIsDeliveryScreenOpen(false));
      dispatch(setIntervalStatus('PENDING'));
      dispatch(setIsDeliveryIntervalModalOpen(true));
    }
  }, [address, dispatch, payload]);

  const addNewAddress = useCallback(async () => {
    const { zone } = await getAddressZone([address.latitude, address.longitude]);
    const { address_id } = await putAddressRequest(payload as PutAddressPayload);
    const updatedPayload = payload as PostAddressPayload;
    const newAddress = serializeDeliveryAddress(payload as AddressResponse);

    if (zone && address_id) {
      updatedPayload.id = address_id;
      updatedPayload.zone_id = zone;

      const { result } = await postAddressRequest(updatedPayload);

      if (result) {
        setSaving(false);

        dispatch(setIsDeliveryScreenOpen(false));
        dispatch(addAddress(newAddress));
        dispatch(setCurrentAddress(newAddress));
        dispatch(setIntervalStatus('PENDING'));
        dispatch(setIsDeliveryIntervalModalOpen(true));
      }
    } else {
      setSaving(false);
      createNotification({ title: 'Адрес вне зоны доставки' });
    }
  }, [address, dispatch, payload]);

  const onAddressRemove = useCallback(() => {
    dispatch(deleteAddress());
    dispatch(setIsDeliveryScreenOpen(false));
    createNotification({ title: 'Адрес удалён' });
  }, [dispatch]);

  const onAddressSave = useCallback(
    async (event) => {
      event.preventDefault();
      if (!address.addressHouse) {
        createNotification({ title: 'Укажите номер дома' });
        return;
      }
      setSaving(true);
      if (address.id > 0) {
        await updateEditedAddress();
      } else {
        await addNewAddress();
      }
    },
    [addNewAddress, address.addressHouse, address.id, updateEditedAddress]
  );

  const onChangeField = useCallback((event) => {
    const { name, value: inputValue } = event.target;
    const value = inputValue.replace(EMOJI_CODES, '');

    if (value.length > MAX_FIELD_LENGTH) return;

    setAddress((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onAddressSuggestionSelect = useCallback((suggestion: Suggestion) => {
    setDisabled(true);
    if (!suggestion) return;
    const { building, city, coordinates, country, label, street } = suggestion;

    setAddress((prev) => ({
      ...prev,
      address: label,
      addressHouse: building,
      fullAddress: label,
      addressCity: city,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
      addressCountry: country,
      addressStreet: street
    }));
    setDisabled(false);
  }, []);

  useEffect(() => {
    if (currentDeliveryAddress?.latitude) {
      const { latitude, longitude } = currentDeliveryAddress;
      // Таймаут нужен для поздней инициализации MapGLUserMarker
      setTimeout(() => {
        MapGLDispatcher.userCoordinates.dispatch([longitude, latitude]);
      }, 100);
    }
  }, [currentDeliveryAddress]);

  useEffect(() => {
    const getAddressFromUserMarker = MapGLDispatcher.userAddress.subscribe((label: string, data: GeocodeAddress) => {
      if (data.label) {
        setAddress((prev) => ({
          ...prev,
          address: label,
          addressCity: data.city,
          addressCountry: data.country,
          addressStreet: data.street,
          addressHouse: data.building,
          latitude: data.coords.lat,
          longitude: data.coords.lon,
          fullAddress: label
        }));
      }
    });

    return () => {
      getAddressFromUserMarker();
    };
  });

  return (
    <form className="CheckoutDeliveryAddressEdit_Form" onSubmit={onAddressSave}>
      <header className="CheckoutDeliveryAddressEdit_Form-Header">
        <Title tag="h3" weight="semiBold" className="CheckoutDeliveryAddressEdit_Form-Title">
          Куда доставить товары?
        </Title>
        <p className="CheckoutDeliveryAddressEdit_Form-Paragraph">Выберите на карте или используйте поиск</p>
      </header>
      {false && (
        <div className="CheckoutDeliveryAddressEdit_Informer">
          <Icon className="CheckoutDeliveryAddressEdit_Informer-Icon" name="roundSpeechBubbleExclamation" size="m" />
          <Typography variant="p2" className="CheckoutDeliveryAddressEdit_Informer-Text">
            Доставка доступна для региона, где вы&nbsp;получали электронный рецепт
          </Typography>
        </div>
      )}
      <fieldset className="CheckoutDeliveryAddressEdit_Form-Wrapper">
        <CheckoutAddressSearch onAddressSelect={onAddressSuggestionSelect} hasLabel />
        <FieldText
          className="CheckoutDeliveryAddressEdit_Input-Flat"
          name="apartment"
          value={address.apartment}
          onChange={onChangeField}
          label="Кв/офис"
        />
        <FieldText
          className="CheckoutDeliveryAddressEdit_Input-Floor"
          name="floor"
          value={address.floor}
          onChange={onChangeField}
          label="Этаж"
        />
        <FieldText
          className="CheckoutDeliveryAddressEdit_Input-Entrance"
          name="entrance"
          value={address.entrance}
          onChange={onChangeField}
          label="Подъезд"
        />
        <FieldText
          className="CheckoutDeliveryAddressEdit_Input-EntranceCode"
          name="entranceCode"
          value={address.entranceCode}
          onChange={onChangeField}
          label="Домофон"
        />
      </fieldset>
      <div className="CheckoutDeliveryAddressEdit_Buttons">
        {currentDeliveryAddress && isAllowedToDelete && !isSaving && (
          <Button fullWidth variant="outlinedSolid" onClick={onAddressRemove}>
            Удалить
          </Button>
        )}
        <Button
          style={isSaving ? { flex: '1 0 auto' } : {}}
          variant="filled"
          fullWidth
          type="submit"
          disabled={isDisabled || isSaving}
        >
          {isSaving ? <Icon name="loader" size="m" /> : 'Сохранить'}
        </Button>
      </div>
    </form>
  );
};
