import React from 'react';
import clsx from 'clsx';
import { Icon, Button } from '@npm-registry/eapteka-ui';
import { useSelector } from 'react-redux';
import { selectRegionFeatures } from '../../../../../services/redux/store/region/selectors';
import { selectCurrentPharmacy } from '../../../../../services/redux/store/pharmacies/selectors';
import { CheckoutPharmacySection } from '../../../CheckoutPharmacySection';
import { CheckoutPharmacyAddress } from '../../../CheckoutPharmacy/Address';
import { CheckoutReceivingMethodPlaceholder } from '../../../../elements/CheckoutReceivingMethodPlaceholder';
import { checkoutDispatchers } from '../../../../../services/dispatchers';
import { pickupButtonTypes } from '../hooks/useReceivingMethodPickup/consts';
import './CheckoutSelectedPharmacy.scss';

export const CheckoutSelectedPharmacy = ({
  onPickupEdit
}) => {
  const { pickup } = useSelector(selectRegionFeatures);
  const pickupPoint = useSelector(selectCurrentPharmacy);

  if (!pickup) {
    return (
      <CheckoutReceivingMethodPlaceholder title="Самовывоз пока недоступен в вашем регионе" />
    );
  }

  if (!pickupPoint) {
    return (
      <CheckoutReceivingMethodPlaceholder
        title="Выберите, откуда заберёте товары"
        buttonTitle="Выбрать аптеку"
        onClick={() =>
          onPickupEdit(pickupButtonTypes.selectPharmacy)
        }
      />
    );
  }

  const {
    isPickupAvailablePartly,
    isPickupAvailable,
    availabilityText,
    paymentText,
    isPartner,
    paymentIcon,
    paymentIconSize,
    isOnlinePayAvailable,
    commonUnavailableReason
  } = pickupPoint;

  const anchorClassNames = clsx(
    'CheckoutPharmacyProductsAvailability_Button',
    {
      CheckoutPharmacyProductsAvailability_Button_isAvailablePartly:
        isPickupAvailablePartly
    }
  );

  return (
    <div className="CheckoutSelectedPharmacy_Root">
      <CheckoutPharmacyAddress
        pharmacy={pickupPoint}
        isFavorite
      />
      {isPickupAvailable && (
        <>
          <CheckoutPharmacySection
            icon={
              <Icon
                name="timeRound"
                size="m"
                className="CheckoutSelectedPharmacy_SectionIcon"
              />
            }
          >
            Будет готов <b>{pickupPoint.storeTo}</b>
          </CheckoutPharmacySection>
          <CheckoutPharmacySection
            icon={
              <Icon
                name="time"
                size="m"
                className="CheckoutSelectedPharmacy_SectionIcon"
              />
            }
          >
            {pickupPoint.storeFrom}
          </CheckoutPharmacySection>
        </>
      )}
      {isOnlinePayAvailable && (
        <CheckoutPharmacySection
          icon={
            <Icon
              name="sberSpasibo"
              size="m"
              className="CheckoutSelectedPharmacy_SectionIcon SberSpasibo"
            />
          }
        >
          Бонусы СберСпасибо
          {isPartner ? ' при оплате онлайн' : ''}
        </CheckoutPharmacySection>
      )}
      <CheckoutPharmacySection
        icon={
          <Icon
            name={paymentIcon}
            size={paymentIconSize}
            className="CheckoutSelectedPharmacy_SectionIcon"
          />
        }
      >
        {paymentText}
      </CheckoutPharmacySection>
      <button
        className={anchorClassNames}
        onClick={
          checkoutDispatchers.toggleProductList.dispatch
        }
      >
        {availabilityText}
      </button>
      {commonUnavailableReason && (
        <p className="CheckoutPharmacyProductsAvailability_minPrice">
          {commonUnavailableReason}
        </p>
      )}
      <Button
        size="s"
        variant="outlinedSolid"
        theme="primary"
        onClick={() =>
          onPickupEdit(pickupButtonTypes.changePharmacy)
        }
        className="CheckoutSelectedPharmacy_Edit"
      >
        Изменить аптеку
      </Button>
    </div>
  );
};
