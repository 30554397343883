import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Icon } from '@npm-registry/eapteka-ui';
import { useSelector } from 'react-redux';
import { selectCurrentPharmacy } from '../../../services/redux/store/pharmacies/selectors';
import { useCorporateSumInsufficient } from '../../hooks/useCorporateSumInsufficient';
import { useMobileWithoutTablet } from '../../hooks/useMobileWithoutTablet';
import { Installment } from '../../core/BNPL';
import { CheckoutCollapse } from '../../elements/CheckoutCollapse';
import { CheckoutSectionHeader } from '../../elements/CheckoutSectionHeader';
import { CheckoutSection } from '../../elements/CheckoutSection';
import { CheckoutPaymentPrograms } from './LoyaltyPrograms';
import { CheckoutPaymentMethodSelect } from './MethodSelect';
import { selectReceivingMethod } from '../../../services/redux/store/receiving/selectors';
import {
  selectCurrentPayment,
  selectPaymentStatus
} from '../../../services/redux/store/payment/selectors';
import { selectUserIsCorporate } from '../../../services/redux/store/user/selectors';
import { selectCurrentInterval } from '../../../services/redux/store/delivery/selectors';

export const CheckoutPayment = () => {
  const [isMobilePaymentsListOpen, setMobilePaymentsListOpen] = useState(false);
  const [isPaymentsListOpen, setPaymentsListOpen] = useState(false);
  const isMobileWithoutTablet = useMobileWithoutTablet();
  const currentInterval = useSelector(selectCurrentInterval);
  const isIntervalSelected = !!currentInterval;
  const { isPickupSelected } = useSelector(selectReceivingMethod);
  const currentPickupPoint = useSelector(selectCurrentPharmacy);
  const currentPaymentMethod = useSelector(selectCurrentPayment);
  const paymentsStatus = useSelector(selectPaymentStatus);
  const isCorporate = useSelector(selectUserIsCorporate);
  const isInstallmentBannerVisible = currentPaymentMethod?.code === 'bnpl';
  const isCorporateSumInsufficient = useCorporateSumInsufficient();

  const [currentOpenListState, currentOpenListHandler] = useMemo(() => {
    if (isMobileWithoutTablet) {
      return [isMobilePaymentsListOpen, setMobilePaymentsListOpen];
    }

    return [isPaymentsListOpen, setPaymentsListOpen];
  }, [isPaymentsListOpen, isMobileWithoutTablet, isMobilePaymentsListOpen]);

  const handleOpening = (e) => {
    if (['Space', 'Enter'].includes(e.code)) {
      currentOpenListHandler(!currentOpenListState);
    }
  };

  const onCloseVisible = () => {
    setMobilePaymentsListOpen(false);
  };

  useEffect(() => {
    if (paymentsStatus === 'SUCCESS') {
      setPaymentsListOpen(true);
    }
  }, [paymentsStatus]);

  const paymentIcon = useMemo(() => {
    if (currentPaymentMethod?.code === 'cash' && currentPaymentMethod?.isSBP) {
      return (
        <>
          <Icon name="cash" size="m" />
          <span className="Icon_SBP">
            <Icon name="sbp" size="s" />
          </span>
        </>
      );
    }

    return <Icon name={currentPaymentMethod?.iconName || 'wallet'} size="m" />;
  }, [currentPaymentMethod]);

  const subtitle = currentPaymentMethod?.name || 'Способ оплаты';
  const title = currentPaymentMethod?.name ? 'Способ оплаты' : '';

  if (
    isCorporateSumInsufficient ||
    (isPickupSelected && !currentPickupPoint && !isCorporate) ||
    (!isPickupSelected && !isIntervalSelected)
  )
    return null;

  return (
    <>
      <CheckoutSection className="Payment">
        <CheckoutSectionHeader
          onClick={() => currentOpenListHandler(!currentOpenListState)}
          onKeyDown={handleOpening}
          image={currentPaymentMethod?.iconName || 'wallet'}
          imageNode={paymentIcon}
          isCollapsed={currentOpenListState}
          subtitle={subtitle}
          isLightTitle
        >
          {title}
        </CheckoutSectionHeader>
        {isMobileWithoutTablet && (
          <Drawer
            isExpanded={isMobilePaymentsListOpen}
            onClose={() => setMobilePaymentsListOpen(false)}
          >
            <article className="CheckoutPayment_Mobile">
              <h3 className="CheckoutPayment_Mobile_Title">Оплата</h3>
              <CheckoutPaymentMethodSelect onClose={onCloseVisible} />
            </article>
          </Drawer>
        )}
        {!isMobileWithoutTablet && (
          <CheckoutCollapse isOpen={isPaymentsListOpen}>
            <CheckoutPaymentMethodSelect />
          </CheckoutCollapse>
        )}
      </CheckoutSection>
      <CheckoutPaymentPrograms />
      {isInstallmentBannerVisible && (
        <CheckoutSection>
          <CheckoutSectionHeader>График платежей</CheckoutSectionHeader>
          <Installment />
        </CheckoutSection>
      )}
    </>
  );
};
