import config from '../../../config';
import { getTokensFromStorage } from '../../../tools/getTokensFromStorage';
import { apiService } from '../apiService';

export const requestOrders = apiService.createPostMethod(
  config.apiUrl.orders,
  {
    mapRequest: ({ data, headers }) => ({
      headers: {
        ...getTokensFromStorage('cartToken'),
        ...headers
      },
      body: JSON.stringify(data)
    })
  }
);

export const requestOrdersHistory =
  apiService.createGetMethod(config.apiUrl.historyOrders, {
    mapRequest: () => ({
      headers: {
        ...getTokensFromStorage('userToken')
      }
    })
  });

export const requestOrdersPaymentStatus =
  apiService.createGetMethod(config.apiUrl.orders, {
    mapRequest: ({ orderId, headers }, { currentUrl }) => ({
      url: `${currentUrl}/${orderId}/payment_status`,
      headers: {
        ...headers,
        ...getTokensFromStorage('cartToken')
      }
    })
  });

export const requestOrdersPaymentUrl =
  apiService.createGetMethod(config.apiUrl.orders, {
    mapRequest: ({ orderId, headers }, { currentUrl }) => ({
      url: `${currentUrl}/${orderId}/pay`,
      headers: {
        ...headers,
        ...getTokensFromStorage('cartToken')
      }
    })
  });
