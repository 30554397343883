import type {
  HandbookWeb,
  HandbookWebResponse,
  HandbookSberResponse,
  HandbookSber,
} from '../types';

export const serializeHandbookWeb = (
  response: HandbookWebResponse
): HandbookWeb => {
  return {
    authPrioritizeSberId: response.auth_prioritize_sber_id,
    eaptekaOnly: response.eapteka_only,
    fallbackInsteadSberId: response.fallback_instead_sber_id,
    isAutoRegisterSberspasiboToOAuth: response.is_auto_register_sberspasibo_to_oauth,
    isBankIconVisible: response.is_bank_icon_visible,
    isBindingIdLoyaltyQueryEnabled: response.is_binding_id_loyalty_query_enabled,
    isBNPLAvailable: response.availability_bnpl,
    isCardTokensEnabled: response.is_card_tokens_enabled,
    isCorporateCheckoutDisabled: response.checkout_disabled_corporate,
    isHeaderSberspasiboEnabled: response.is_enable_header_sberspasibo,
    isSberBonusesEnabled: response.is_sber_bonuses_enabled,
    isSberSpasiboSwitcherAvailable: response.is_sber_spasibo_switcher_available,
    isSberpayEnabled: response.sberpay_enabled,
    isSberpaySpasiboEnabled: response.sberspasibo_sberpay_enabled,
    maxBnplSum: response.max_bnpl_sum,
    minBnplSum: response.min_bnpl_sum,
    onlySberId: response.only_sber_id,
  };
};

export const serializeHandbookSber = (
  response: HandbookSberResponse
): HandbookSber => {
  return {
    sberBonusesThresholds: response.sber_bonuses_thresholds.map(
      ({ cart_sum_from, cart_sum_to, percent }) => ({
        cartSumFrom: cart_sum_from,
        cartSumTo: cart_sum_to,
        percent,
      })
    ),
  };
};
