import { useSelector } from 'react-redux';
import {
  selectCurrentAddress,
  selectIntervals,
  selectIntervalStatus
} from '../../services/redux/store/delivery/selectors';

export const useAddressAvailability = () => {
  const isDataFetched =
    useSelector(selectIntervalStatus) === 'SUCCESS';
  const currentDeliveryAddress = useSelector(
    selectCurrentAddress
  );
  const deliveryIntervals = useSelector(selectIntervals);

  if (!isDataFetched) return true;

  const isAddressAvailable = currentDeliveryAddress
      ? currentDeliveryAddress.zoneId &&
        deliveryIntervals.length > 0
      : false;

  return isAddressAvailable;
};
