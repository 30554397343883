import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentPayment,
  selectPayment
} from '../../../../services/redux/store/payment/selectors';
import { CheckoutPaymentMethodSelectMethods } from './Methods';
import { CheckoutPaymentSaveCard } from '../SaveCard';
import { setCurrentPayment } from '../../../../services/redux/store/payment/slice';
import { checkoutDispatchers } from '../../../../services/dispatchers';
import './CheckoutPaymentMethodSelect.scss';

const offlineTypes = ['cash', 'pickup_payment'];

export const CheckoutPaymentMethodSelect = () => {
  const dispatch = useDispatch();
  const { paymentTypes } = useSelector(selectPayment);
  const { uuid } = useSelector(selectCurrentPayment) || {};
  const paymentMethodsOffline = paymentTypes.filter((p) => offlineTypes.includes(p.code));
  const paymentMethodsOnline = paymentTypes.filter((p) => !offlineTypes.includes(p.code));

  const onSelect = (payment) => {
    dispatch(setCurrentPayment(payment));
    checkoutDispatchers.paymentMethodSubmit.dispatch({
      is_interaction: true
    });
  };

  return (
    <div className="CheckoutPaymentMethodSelect_Root">
      <CheckoutPaymentMethodSelectMethods
        title="Онлайн"
        payments={paymentMethodsOnline}
        onSelect={onSelect}
        currentPaymentId={uuid}
      />
      <CheckoutPaymentSaveCard />
      <CheckoutPaymentMethodSelectMethods
        title="При получении"
        payments={paymentMethodsOffline}
        onSelect={onSelect}
        currentPaymentId={uuid}
      />
    </div>
  );
};
