import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegion } from '../../../../services/redux/store/region/selectors';
import { Title } from '@npm-registry/eapteka-ui';
import { CheckoutReceivingMethodPlaceholder } from '../../../elements/CheckoutReceivingMethodPlaceholder';
import { setIsDeliveryAddressModalOpen } from '../../../../services/redux/store/ui/slice';
import { selectUserIsCorporate } from '../../../../services/redux/store/user/selectors';
import './DeliveryPlaceholder.scss';

export const DeliveryPlaceholder = () => {
  const dispatch = useDispatch();
  const isCorporate = useSelector(selectUserIsCorporate);
  const isDeliveryCompany = useSelector(selectRegion).deliveryCompany;
  const isCorporateDeliveryUnavailable =
    isCorporate && !isDeliveryCompany;
  const placeholderTitle = isCorporateDeliveryUnavailable
    ? 'Доставка пока недоступна в вашем регионе'
    : 'Укажите, куда привезти товары';
  const placeholderButtonTitle =
    isCorporateDeliveryUnavailable ? '' : 'Указать адрес';

  const onClick = useCallback(() => {
    dispatch(setIsDeliveryAddressModalOpen(true));
  }, [dispatch]);

  return (
    <div>
      {!isCorporateDeliveryUnavailable && isCorporate && (
        <Title
          className="DeliveryPlaceholderTitle"
          tag="h3"
        >
          Доставка курьером - бесплатно
        </Title>
      )}
      <CheckoutReceivingMethodPlaceholder
        isButtonUnavailable={isCorporateDeliveryUnavailable}
        title={placeholderTitle}
        buttonTitle={placeholderButtonTitle}
        onClick={onClick}
        receiverType="Delivery"
      />
    </div>
  );
};
