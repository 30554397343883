/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Button } from '@npm-registry/eapteka-ui';
import {
  clearDialog,
  createDialog,
  createNotification
} from '.';
import { validateEmail } from '../../tools/validation/validateEmail/validateEmail';
import { validateFullName } from '../../tools/validation/validateFullName/validateFullName';
import { validatePhoneNumber } from '../../tools/validation/validatePhoneNumber/validatePhoneNumber';
import { apiRequestOrdersPaymentStatus } from '../api';
import { apiRequestOrdersPaymentUrl } from '../api/methods/paymentUrl';
import { checkoutDispatchers } from '../dispatchers';
import { resetReceivingMethods } from '../redux/store/receiving/slice';
import { Store } from '../redux/store/store';

checkoutDispatchers.errorTechnical.subscribe(() => {
  createDialog({
    title: 'Технический сбой',
    message:
      'Обновите страницу или попробуйте вернуться позднее',
    addon: (
      <Button onClick={document.location.reload}>
        Обновить
      </Button>
    )
  });
});

checkoutDispatchers.errorGetPharmacies.subscribe(() => {
  createDialog({
    title: 'Нет доступных аптек',
    message:
      'Возможно, это технический сбой. \nОбновите страницу или попробуйте вернуться позднее',
    addon: (
      <Button onClick={document.location.reload}>
        Обновить
      </Button>
    )
  });
});

checkoutDispatchers.confirmOrderFail.subscribe(() => {
  createDialog({
    title: 'Не удалось оформить',
    message: 'Попробуйте обновить или вернитесь позднее',
    addon: (
      <Button onClick={document.location.reload}>
        Обновить
      </Button>
    )
  });
});

checkoutDispatchers.deliveryIntervalsAreMissing.subscribe(
  () => {
    const { isCorporate } = Store.getState().user.user;
    const message = isCorporate
      ? 'Попробуйте оформить позднее или измените адрес доставки'
      : 'Попробуйте забрать самовывозом или выбрать другой адрес';

    createDialog({
      title: 'Нет доступных интервалов доставки',
      message,
      addon: (
        <Button
          onClick={() => {
            Store.dispatch(resetReceivingMethods());
            clearDialog();
          }}
        >
          Хорошо
        </Button>
      )
    });
  }
);

checkoutDispatchers.errorGetPickupPoints.subscribe(() => {
  createDialog({
    title: 'Точки самовывоза не загрузились',
    message:
      'Обновите страницу или попробуйте вернуться позднее',
    addon: (
      <Button onClick={document.location.reload}>
        Обновить
      </Button>
    )
  });
});

checkoutDispatchers.errorRecipientData.subscribe(
  ({ email, name, phone, isVisibleForm }) => {
    const invalidFields = [];
    const fieldValidators = {
      email: validateEmail,
      name: validateFullName,
      phone: validatePhoneNumber
    };
    const fields = isVisibleForm
      ? new Map(Object.entries({ phone, name }))
      : new Map(Object.entries({ phone, email, name }));

    fields.forEach((value, key) => {
      if (
        !Boolean(value) ||
        (key in fieldValidators &&
          fieldValidators[key](value).hasError)
      ) {
        invalidFields.push(key);
      }
    });
    const errorType = invalidFields.join();

    const errors = {
      isVisibleForm: {
        'phone,name': 'Укажите ФИО и телефон получателя',
        phone: 'Укажите телефон получателя',
        name: 'Укажите ФИО получателя'
      },
      'phone,email,name':
        'Укажите ваши телефон, ФИО и почту',
      'phone,email': 'Укажите ваши телефон и почту',
      'phone,name': 'Укажите ваши телефон и ФИО',
      'email,name': 'Укажите ваши почту и ФИО',
      phone: 'Укажите ваш телефон',
      email: 'Укажите вашу почту',
      name: 'Укажите ваше ФИО'
    };

    const title = isVisibleForm
      ? errors.isVisibleForm[errorType]
      : errors[errorType];

    createNotification({ title });
    checkoutDispatchers.orderFailEvent.dispatch({
      errorMessage: title
    });
  }
);

checkoutDispatchers.sberLoyaltyFail.subscribe(
  ({ orderUrl, orderId }) => {
    const title = 'Не удалось списать бонусы';
    const message =
      'Списание бонусов СберСпасибо временно недоступно. Сумма заказа будет оплачена рублями';
    const interactAdditional = () => {
      window.location.assign(orderUrl);
    };
    const interactPrimary = async () => {
      const { payment_url } =
        await apiRequestOrdersPaymentUrl(orderId);
      await apiRequestOrdersPaymentStatus(orderId).finally(
        () => {
          window.location.assign(payment_url || orderUrl);
        }
      );
    };
    createDialog({
      title,
      message,
      onClose: interactAdditional,
      addon: (
        <>
          <Button
            variant="outlinedSolid"
            onClick={interactAdditional}
          >
            Отменить
          </Button>
          <Button onClick={interactPrimary}>
            Хорошо, продолжить
          </Button>
        </>
      )
    });
  }
);
