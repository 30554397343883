import React from 'react';
import { useSelector } from 'react-redux';
import { selectRegion } from '../../../services/redux/store/region/selectors';
import { useReceivingMethodView } from './hooks/useReceivingMethodView';
import { useCorporateSumInsufficient } from '../../hooks/useCorporateSumInsufficient';
import { CheckoutReceivingMethodPlaceholder } from '../../elements/CheckoutReceivingMethodPlaceholder';
import { CheckoutSection } from '../../elements/CheckoutSection';
import { CheckoutReceivingMethodDelivery } from './Delivery';
import { CheckoutReceivingMethodPickup } from './Pickup';
import { CheckoutReceivingMethodSkeleton } from './Skeleton';
import { DeliveryTypeSelector } from './DeliveryTypeSelector/DeliveryTypeSelector';
import { CorporateDeliveryUnavailableBlock } from './CorporateDeliveryUnavailableBlock/CorporateDeliveryUnavailableBlock';
import { selectUserIsCorporate } from '../../../services/redux/store/user/selectors';
import { selectReceivingMethodStatus } from '../../../services/redux/store/receiving/selectors';

export const CheckoutReceivingMethods = () => {
  const isOrderReceivingStorePending = useSelector(selectReceivingMethodStatus) === 'PENDING';
  const isCorporate = useSelector(selectUserIsCorporate);
  const isDeliveryCompany = useSelector(selectRegion).deliveryCompany;
  const { isPickupSelected, onChangeReceivingMethod } = useReceivingMethodView();
  const isCorporateSumInsufficient =
    useCorporateSumInsufficient();

  if (
    isCorporate &&
    (!isDeliveryCompany || isCorporateSumInsufficient)
  ) {
    return (
      <CorporateDeliveryUnavailableBlock
        isSumLow={isCorporateSumInsufficient}
        isDeliveryUnavailable={
          isCorporate && !isDeliveryCompany
        }
      />
    );
  }

  const isPending =
    isOrderReceivingStorePending ||
    (!isOrderReceivingStorePending &&
      isPickupSelected &&
      isCorporate);

  return (
    <CheckoutSection className="CheckoutSection--ReceivingMethods">
      {isPending && <CheckoutReceivingMethodSkeleton />}
      {isCorporate && !isDeliveryCompany && (
        <CheckoutSection className="CheckoutSection--ReceivingMethods">
          <CheckoutReceivingMethodPlaceholder
            title="Доставка пока недоступна в вашем регионе"
            receiverType="Delivery"
          />
        </CheckoutSection>
      )}

      {!isOrderReceivingStorePending && !isCorporate && (
        <>
          <DeliveryTypeSelector
            onChange={onChangeReceivingMethod}
          />
          {isPickupSelected && (
            <CheckoutReceivingMethodPickup />
          )}
        </>
      )}
      {!isOrderReceivingStorePending && !isPickupSelected && (
        <CheckoutReceivingMethodDelivery />
      )}
    </CheckoutSection>
  );
};
