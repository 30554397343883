import React from 'react';
import { Typography, Icon } from '@npm-registry/eapteka-ui';
import './CheckoutERecipe.scss';

export const CheckoutERecipe = () => {
  return (
    <Typography
      variant="p2"
      className="CheckoutERecipe_Root"
    >
      <Icon size="s" name="eRecipe" />
      <span className="CheckoutERecipe_Text">
        Доступна доставка
      </span>
    </Typography>
  );
};
