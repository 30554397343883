import { checkoutDispatchers } from '../../../dispatchers';
import { requestOrdersPaymentStatus } from '../orders';

export function apiRequestOrdersPaymentStatus(
  orderId,
  headers
) {
  try {
    return requestOrdersPaymentStatus({ orderId, headers });
  } catch (error) {
    checkoutDispatchers.errorTechnical.dispatch({
      title: 'Не удалось получить статус оплаты'
    });

    throw error;
  }
}
