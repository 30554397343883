import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import {
  getWebFeaturesRequest,
  getSberFeaturesRequest
} from '../../../api/endpoints/handbooks/getFeaturesRequest';
import { handbookActions } from './slice';
import {
  serializeHandbookSber,
  serializeHandbookWeb
} from './serializers/serialize';

export const getHandbook = createAsyncThunk(
  STORE_NAMES.HANDBOOK + '_action',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(
      handbookActions.setHandbookStatus(
        FETCH_STATUS.PENDING
      )
    );

    await Promise.all([
      getWebFeaturesRequest(),
      getSberFeaturesRequest()
    ])
      .then(([web, sber]) => {
        const webHandbook = serializeHandbookWeb(web);
        const sberHandbook = serializeHandbookSber(sber);

        thunkAPI.dispatch(
          handbookActions.setHandbook({
            web: webHandbook,
            sber: sberHandbook
          })
        );
        thunkAPI.dispatch(
          handbookActions.setHandbookStatus(
            FETCH_STATUS.SUCCESS
          )
        );
      })
      .catch((err) => {
        console.error('Ошибка получения features: ', err);
        thunkAPI.dispatch(
          handbookActions.setHandbookStatus(
            FETCH_STATUS.ERROR
          )
        );
      });
  }
);
