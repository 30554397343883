import React, { FC } from 'react';
import clsx from 'clsx';
import { Title, Icon } from '@npm-registry/eapteka-ui';
import { CheckoutSectionHeaderProps } from './types';
import './CheckoutSectionHeader.scss';

export const CheckoutSectionHeader: FC<
  CheckoutSectionHeaderProps
> = ({
  image,
  imageNode,
  children,
  className = '',
  headerTag = 'h4',
  isDisabled = false,
  subtitle,
  isLightTitle,
  isCollapsed,
  onClick,
  onKeyDown
}) => {
  const rootClass = clsx(
    'CheckoutSectionHeader',
    className
  );
  const role = onClick ? 'button' : 'none';
  const tabIndex = onClick ? 0 : -1;

  return (
    <div
      className={rootClass}
      role={role}
      tabIndex={tabIndex}
      aria-label="Интерактивный элемент"
      aria-disabled={isDisabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {image && !imageNode && (
        <div className="CheckoutSectionHeaderImage">
          <Icon name={image} size="m" />
        </div>
      )}
      {imageNode && (
        <div className="CheckoutSectionHeaderImage">
          {imageNode}
        </div>
      )}
      <div>
        <Title
          className={clsx('CheckoutSectionHeaderTitle', {
            CheckoutSectionHeaderTitleLight: isLightTitle
          })}
          tag={headerTag}
        >
          {children}
        </Title>
        {subtitle && (
          <p
            className={clsx(
              'CheckoutSectionHeaderSubTitle',
              {
                CheckoutSectionHeaderSubTitleBold:
                  isLightTitle
              }
            )}
          >
            {subtitle}
          </p>
        )}
      </div>
      {onClick && (
        <Icon
          name="chevronDown"
          style={{
            color: '#9199A3',
            transform: isCollapsed
              ? 'rotateZ(180deg)'
              : 'initial',
            transition: 'transform 0.3s ease-out',
            marginLeft: 'auto',
            flex: '0 0 auto'
          }}
        />
      )}
    </div>
  );
};
