import { useSelector } from 'react-redux';
import { selectRegionFeatures } from '../../services/redux/store/region/selectors';
import { selectUserIsCorporate } from '../../services/redux/store/user/selectors';
import { useAddressAvailability } from './useAddressAvailability';
import { selectCartCheckout, selectCartData } from '../../services/redux/store/cart/selectors';
import { useDeliveryAvailable } from '../blocks/CheckoutReceivingMethods/Delivery/hooks/useDeliveryAvailable';

export const useDeliveryWarningMessage = () => {
  const {
    hasItemWithPku,
    hasItemWithRecipe,
    hasItemWithAlcohol,
    hasItemPreorder,
    hasERecipes,
  } = useSelector(selectCartData);
  const { erecipeMessage } = useSelector(selectCartCheckout);
  const isCorporate = useSelector(selectUserIsCorporate);
  const { delivery: isDeliveryAvailableInRegion } = useSelector(selectRegionFeatures);
  const isDeliveryAvailable = useDeliveryAvailable()
  const hasPkuOrRecipe = hasItemWithPku || hasItemWithRecipe;

  const isAddressAvailable = useAddressAvailability();


  if (isCorporate) return '';

  if (!isDeliveryAvailableInRegion) return 'Пока недоступно в вашем регионе';

  if (!hasERecipes && erecipeMessage) return erecipeMessage;

  if (hasItemPreorder) return '';

  if (!isAddressAvailable)
    return 'Адрес вне зоны доставки или нет доступных интервалов доставки';

  if (hasPkuOrRecipe && hasItemWithAlcohol)
    return 'В заказе рецептурные и спиртосодержащие товары';

  if (hasItemWithAlcohol)
    return 'В заказе спиртосодержащие товары';

  if (!isDeliveryAvailable)
    return 'Пока недоступно в вашем регионе';

  return '';
};
