import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS, STORE_NAMES } from '../consts';
import { Region, RegionStore } from './types';
import { FetchStatus } from '../types';

const initialState = {
  status: FETCH_STATUS.IDLE,
  region: {
    active: false,
    chatAvailable: false,
    code: '',
    deliveryCompany: false,
    features: {
      allHours: false,
      bonusProgram: false,
      delivery: false,
      isInstallmentAvailableInRegion: false,
      isNewGate: false,
      isUserCardsAvailable: false,
      pickup: false
    },
    freeDeliveryFrom: 0,
    id: 0,
    isDeliveryAvailable: false,
    isPickupAvailable: false,
    isVirtual: false,
    kladr: '',
    latitude: 0,
    license: '',
    longitude: 0,
    maxPriceCompany: 0,
    minPriceCompany: 0,
    name: '',
    phone: '',
    region: '',
    serviceCompany: false,
    serviceFee: 0
  },
} as RegionStore;

export const regionSlice = createSlice({
  name: STORE_NAMES.REGION,
  initialState,
  reducers: {
    setRegion: (state, { payload }: PayloadAction<Region>) => {
      state.region = payload;
    },
    setRegionStatus: (state, { payload }: PayloadAction<FetchStatus>) => {
      state.status  = payload;
    }
  },
});

const regionReducer  = regionSlice.reducer;
const regionActions = regionSlice.actions;

export { regionReducer, regionActions };
