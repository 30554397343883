import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectWebFeatures } from '../../../services/redux/store/handbook/selectors';
import { RECIPIENT_TYPE_LABELS } from './const';
import { checkoutDispatchers } from '../../../services/dispatchers';
import { CheckoutTooltip } from '../../elements/CheckoutTooltip';
import { setReceivingMethodDelivery } from '../../../services/redux/store/receiving/slice';
import { setBonusToggled, setSberspasiboToggled } from '../../../services/redux/store/payment/slice';
import './CheckoutRecipientType.scss';

export const CheckoutRecipientType = ({ isCorporate, hasERecipes, hasPreorder, onChange }) => {
  const dispatch = useDispatch();
  const { isCorporateCheckoutDisabled } = useSelector(selectWebFeatures);

  const rootClassNames = clsx('CheckoutRecipientType_Root', {
    CheckoutRecipientType_Root__corporate: isCorporate
  });

  const onSelectRecipientType = (recipientTypeLabel) => {
    onChange(recipientTypeLabel);
    if (recipientTypeLabel === RECIPIENT_TYPE_LABELS.corporate) {
      dispatch(setBonusToggled(false));
      dispatch(setSberspasiboToggled(false));
      dispatch(setReceivingMethodDelivery());
      checkoutDispatchers.paymentMethodSubmit.dispatch();
    }
  };

  return (
    <nav className={rootClassNames}>
      <button
        className="CheckoutRecipientType_Button CheckoutRecipientType_Button__individual"
        onClick={() => {
          onSelectRecipientType(RECIPIENT_TYPE_LABELS.individual);
        }}
        type="button"
      >
        Физлицо
      </button>
      {!hasERecipes && !hasPreorder && (
        <button
          className="CheckoutRecipientType_Button CheckoutRecipientType_Button__corporate"
          disabled={isCorporateCheckoutDisabled}
          onClick={() => {
            onSelectRecipientType(RECIPIENT_TYPE_LABELS.corporate);
          }}
          type="button"
        >
          Юрлицо или ИП
          {isCorporateCheckoutDisabled && (
            <CheckoutTooltip message="Онлайн-оплата временно недоступна" />
          )}
        </button>
      )}
    </nav>
  );
};
