import { createRestApiPreset } from '@npm-registry/helpers/request';
import { getTokensFromStorage } from './helpers/getTokensFromStorage';
import config from '../../config';

export const API_SERVICE = createRestApiPreset(
  config.baseUrl,
  {
    credentials: 'include',
    headers: {
      ...config.apiHeaders,
      ...getTokensFromStorage()
    }
  }
);
