export const getTokensFromStorage = () => {
  const userToken = localStorage.getItem('user-token');
  const cartToken = localStorage.getItem('cart-token');

  const resultTokensMap: {
    'user-token'?: string;
    'cart-token'?: string;
  } = {};

  if (userToken) {
    resultTokensMap['user-token'] = userToken;
  }

  if (cartToken) {
    resultTokensMap['cart-token'] = cartToken;
  }

  return resultTokensMap;
};
