import React from 'react';
import { InstallmentContextProvider } from './InstallmentContext';
import { BNPLSchedule } from './Schedule';

export const Installment = () => {
  return (
    <InstallmentContextProvider>
      <BNPLSchedule />
    </InstallmentContextProvider>
  );
};
