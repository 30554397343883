import React, { FC, ReactNode } from 'react';
import { Link } from '@npm-registry/eapteka-ui';
import { CheckoutSectionHeadDefaultLeft } from './CheckoutSectionHeadDefaultLeft';
import './CheckoutSectionHead.scss';

interface CheckoutSectionHeadProps {
  leftBlock?: ReactNode;
  children?: ReactNode;
}

// TODO: Заменить номер телефона
export const CheckoutSectionHead: FC<
  CheckoutSectionHeadProps
> = ({ leftBlock, children }) => (
  <header className="CheckoutSectionHead">
    <div className="CheckoutSectionHeadLeft">
      {leftBlock || <CheckoutSectionHeadDefaultLeft />}
    </div>
    {children && (
      <div className="CheckoutSectionHeadMiddle">
        {children}
      </div>
    )}
    <div className="CheckoutSectionHeadRight">
      Помощь покупателю
      <br />
      <b>
        <Link variant="black" href="tel:+74957907711">
          8 495 790 77 11
        </Link>
      </b>
    </div>
  </header>
);
