import { PickupPoint } from '../../../../../../services/redux/store/pharmacies/types';
import { Store } from '../../../../../../services/redux/store/store';

export const CLUSTER_ICON =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNTgiIHZpZXdCb3g9IjAgMCA2MCA1OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2ZfMTI2M18xNTY1NzEpIj4NCjxlbGxpcHNlIGN4PSIzMiIgY3k9IjQwIiByeD0iMjIiIHJ5PSIxMiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzEyNjNfMTU2NTcxKSIgZmlsbC1vcGFjaXR5PSIwLjQ2Ii8+DQo8L2c+DQo8Y2lyY2xlIGN4PSIyNiIgY3k9IjI2IiByPSIyNCIgZmlsbD0iIzgxNjhGMCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0Ii8+DQo8Y2lyY2xlIGN4PSIyNiIgY3k9IjI2IiByPSIxNiIgZmlsbD0id2hpdGUiLz4NCjxkZWZzPg0KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzEyNjNfMTU2NTcxIiB4PSI0LjY2NjY3IiB5PSIyMi42NjY3IiB3aWR0aD0iNTQuNjY2NyIgaGVpZ2h0PSIzNC42NjY3IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+DQo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPg0KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4NCjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIuNjY2NjciIHJlc3VsdD0iZWZmZWN0MV9mb3JlZ3JvdW5kQmx1cl8xMjYzXzE1NjU3MSIvPg0KPC9maWx0ZXI+DQo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMTI2M18xNTY1NzEiIHgxPSIzMiIgeTE9IjI4IiB4Mj0iMzIiIHkyPSI1MiIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPg0KPHN0b3Agc3RvcC1vcGFjaXR5PSIwIi8+DQo8c3RvcCBvZmZzZXQ9IjEiLz4NCjwvbGluZWFyR3JhZGllbnQ+DQo8L2RlZnM+DQo8L3N2Zz4NCg==';

function formatAvailabilityText(
  unavailableOffersCount: number
) {
  const { totalPositionsCount } =
    Store.getState().cart.cart.offersData;

  if (totalPositionsCount === unavailableOffersCount) {
    return '';
  }

  return unavailableOffersCount > 0
    ? `${totalPositionsCount - unavailableOffersCount} из ${totalPositionsCount}`
    : '';
}

export function getPlacemarkIcon(pickupPoint: PickupPoint) {
  const availabilityText = formatAvailabilityText(
    pickupPoint.unavailableOffers.length
  );
  const isPartiallyInStock = availabilityText.length > 6;
  const partiallyInStockClass = isPartiallyInStock
    ? 'partially-in-stock-wide'
    : 'partially-in-stock';
  const image = pickupPoint.isPickupInAnHour
    ? 'fast-delivery'
    : pickupPoint.isPickupAvailable
      ? pickupPoint.isPickupAvailablePartly
        ? partiallyInStockClass
        : pickupPoint.name === 'ЕАПТЕКА'
          ? 'eapteka'
          : 'all-in-stock'
      : 'unavailable';

  return {
    image,
    availabilityText
  };
}

export function resetIconSize(): void {
  const activePin = document.querySelector(
    '.MapGL_Marker.active'
  );
  if (activePin) {
    activePin.classList.remove('active');
  }
}
